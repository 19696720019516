import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaFopr.mjs'
import OprType from './OprType.jsx'
import Model from './ModelFopr.mjs'
import PropertiesPane from './PropertiesPaneFopr.jsx'

const OprTypes = new Map(
    OprType.flat().map(([type, name, icon]) => [type, {icon, name, type}])
)

export default (BizNode) => {
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        canLinkType(map, node, type) {
            return /^(SF)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(FRES)$/.test(type)
        },

        getDesc(map, node) {
            const {oprType} = node.data

            if (! oprType || 'GENERAL' === oprType) {
                return this.name
            }

            const {name = ''} = OprTypes.get(oprType) ?? {}
            return name
        },

        getInitData(map, parent) {
            return {
                ...ComponentNode.getInitData.call(this, map, parent),
                oprType: 'GENERAL',
            }
        },

        getDefaultChildData(map, node) {
            return BizNode.FRES.getInitData(map, node)
        },

        getIcons(map, node) {
            const type2icon = new Map(
                OprType.flat().map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(node.data.oprType)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'HorizontalHexagon',
            }
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SF[UI]_'],
            ]
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('CRES' === bizNodeType) {
                BizNode.FRES.castFrom(map, child)
            }

            ComponentNode.onAttached.call(this, map, node, child)
        },

        onPull(map, node) {
            ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            let dataKey, oprCat, oprScene, sceneNo

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if ('CAT' === bizNodeType) {
                    if (! oprCat) {
                        oprCat = BizNode.CAT.getTextRaw(map, n)
                    }
                }
                else if (BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('SF_MAIN' === bizNodeType) {
                    dataKey = 'mainOprList'
                    break
                }
                else if ('SCENE' === bizNodeType) {
                    dataKey = 'otherOprList'
                    oprScene = BizNode.SCENE.getTextRaw(map, n)
                    sceneNo = String(n.id)
                    break
                }
            }

            if (dataKey) {
                data[dataKey].push({
                    ...this._getPushData(map, node, {
                        child: [],
                        sfOprSfs: [],
                    }),

                    oprCat,
                    oprScene,
                    sceneNo,
                })
            }
        },
    }
}
