import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import meta from './metaDes.mjs'
import Model from './ModelDes.mjs'
import PropertiesPane from './PropertiesPaneDes.jsx'
import useApi from './useApiDes.mjs'
import useContextMenu from './useContextMenuDes.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)
    const contextMenu = useContextMenu(BizNode)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^DES_(DES|OTH|TASK|TEST)$/.test(type)
        },

        contextMenu,

        getStyle(map, node) {
            return {
                ...ProductNode.getStyle.call(this, map, node),
                backgroundColor: Color.LIGHT_GREY,
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
