import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBjDesc.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        onCreate(map, node) {
            const bfTypeCode = (() => {
                for (const ancestor of map.walkUp(node.parent)) {
                    const {
                        bfTypeCode = 'TARGET',
                        bizNodeType,
                    } = ancestor.data

                    if ('BF' === bizNodeType) {
                        return bfTypeCode
                    }
                }

                return 'TARGET'
            })()

            const childNodeTexts = {
                TARGET: ['场景描述', '注意事项'],
                SITUATION: ['场景描述', '数据处理'],
            }[bfTypeCode]

            for (const text of childNodeTexts) {
                const data = {
                    ...BizNode.AR.getInitData(map, node),
                    arTypeCode: 'CAT',
                    [BizNode.AR.textProp]: text,
                }

                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            ArCatNode.onCreate.call(this, map, node)
        },
    }
}
