import {cloneElement} from 'react'
import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import useBizNode from './useBizNode.mjs'

const cssIcons = css({
    display: 'flex',

    'svg': {
        width: '1em',
        height: '1em',
    }
})

const NodeIcons = ({node, ...props}) => {
    const map = useMapContext()
    const BizNode = useBizNode()
    const {bizNodeType} = node.data
    const icons = BizNode[bizNodeType].getIcons(map, node)

    if (! icons?.length) {
        return null
    }

    const {fontSize} = map.behaviours.getNodeTextStyle(node)

    return (
        <div
            css={cssIcons}
            style={{fontSize}}
            {...props}
        >
            {icons.map((icon, key) => cloneElement(icon, {key}))}
        </div>
    )
}

export default NodeIcons
