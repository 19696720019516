import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        publish: (data) => http.post(
            '/archMiddlewares/modifySts',
            {...data, middlewareStsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/archMiddlewares/${pkid}/dps`),
        push: (data) => http.post('/archMiddlewares/batchmodify', data),
        read: ({pkid}) => http.get('/oneArchMiddleware', {pkid}),
        readList: (args) => http.get('/listArchMiddlewareByMap', args),

        revise: ({pkid}) => http.post(
            '/archMiddlewares/modifySts',
            {pkid, middlewareStsCode: 'REVISE'}
        ),

        updateMap: (data) => http.put(
            `/archMiddlewares/${data.pkid}/map`,
            data
        ),
    }
}
