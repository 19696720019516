import Color from '../Color.mjs'
import useModuleNode from '../useModuleNode.jsx'
import meta from './metaDm.mjs'
import Model from './ModelDm.mjs'
import PropertiesPane from './PropertiesPaneDm.jsx'

export default (BizNode) => {
    const ModuleNode = useModuleNode(BizNode, meta, Model)

    return {
        ...ModuleNode,

        canMountType(map, node, type) {
            return /^(DM)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return this.getInitData(map, node)
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.CYAN}),
                shape: 'SingleBreakangle',
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if (/^[BF]M$/.test(bizNodeType)) {
                this.castFrom(map, child)
            }

            ModuleNode.onAttached.call(this, map, node, child)
        },

        onPush(map, node, type, data) {
            const key = {
                DB: 'archDmList',
                DM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },
    }
}
