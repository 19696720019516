import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'sfNo',
    detailUrl: '/SfDetail',
    mapProp: 'sfMap',
    idProp: 'sfId',
    name: '系统功能',
    textProp: 'sfName',
    type: 'SF',
}
