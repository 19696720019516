import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        discard: ({pkid}) => http.put(
            '/prjdess/rlsandrevise',
            {pkid, stsCode: 'DISCARD'}
        ),

        publish: (data) => http.put(
            '/prjdess/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        //pull: ({pkid}) => http.get(`/prjdess/${pkid}/dps`),
        push: (data) => http.post('/prjdess/batchmodify', data),
        read: ({pkid}) => http.get(`/prjdess/${pkid}`),

        revise: ({pkid}) => http.put(
            '/prjdess/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        updateMap: (data) => http.put(`/prjdess/${data.pkid}/map`, data),
    }
}
