import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import meta from './metaBf.mjs'
import Model from './ModelBf.mjs'
import PropertiesPane from './PropertiesPaneBf.jsx'
import QueryForm from './QueryFormSelectBf.jsx'
import Table from './TableSelectBf.jsx'
import useApi from './useApiBf.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(BF_(BD|BF|CHART|DATAFLOW|RULE|SU))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {bms = [], ...query}) => ({
                ...query,
                bmIds: bms.map(({bmId}) => bmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getInitData(map, parent) {
            const d = {
                ...ProductNode.getInitData.call(this, map, parent),
                bfTypeCode: 'TARGET',
            }

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    bmCode,
                    bmId,
                    bmName,
                } = ancestor.data

                if ('CAT_ROOT_BM' === bizNodeType) {
                    Object.assign(d, {
                        bmCode,
                        bmId,
                        bmName,
                    })

                    break
                }
            }

            return d
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                bdList: [],
                bmBfs: [],
                bmBjList: [],
                fileList: [],
                sfList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'Arrow',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'algList',
                'bdList',
                'bmBjList',
                'fileList',
            ])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BF: 'bmBfs',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)
            data[key].push(pushData)
        },
    }
}
