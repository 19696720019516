import Color from '../Color.mjs'
import useAttributeNode from '../useAttributeNode.jsx'

const prop = 'sfKey'
const type = 'ATTR_SF_KEY'

export default (BizNode) => {
    const AttributeNode = useAttributeNode(BizNode, {prop, type})

    return {
        ...AttributeNode,

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                borderColor: Color.GOLD,
            }
        },
    }
}
