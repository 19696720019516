export const insertBizNode = BizNode => () => {
    return function (nodes, type) {
        const nodesToSelect = new Set()

        for (const node of nodes) {
            const data = BizNode[type].getInitData(this, node)

            if (data) {
                const child = this.importTree({data})
                this.appendChild(node, child)
                BizNode[type].onCreate(this, child)

                if (! child.isDeleted) {
                    this.logger.info('已插入节点', [node])
                    nodesToSelect.add(child)
                }
            }
        }

        this.selectNodes(nodesToSelect)
    }
}
