import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/umuis', data),

        publish: (data) => http.put(
            '/umuis/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/umuis/${pkid}/dps`),
        push: (data) => http.post('/umuis/batchmodify', data),
        read: ({pkid}) => http.get(`/umuis/${pkid}`),
        readHierarchyMap: ({pkid}) => http.get(`/umuis/${pkid}/children`),
        readList: (args) => http.get('/umuis', args),
        readMigrateMap: ({pkid}) => http.get(`/umuis/${pkid}/migrate`),

        revise: ({pkid}) => http.put(
            '/umuis/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/umuis/${data.pkid}`, data),
        updateHierarchy: (tree) => http.post('/umuis/savechild', tree),
        updateMap: (data) => http.put(`/umuis/${data.pkid}/map`, data),
    }
}
