import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'

const PropertiesControllerProductSn = (props) => {
    return (
        <Text
            prop="dpSn"
            readOnly
            title="模件编号"
            {...props}
        />
    )
}

export default PropertiesControllerProductSn
