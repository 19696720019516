import {useState} from 'react'
import {css} from '@emotion/react'
import {Button} from 'antd'
import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'

const cssTestResult = css({
    display: 'inline-block',
    width: 8,
    height: 8,
    verticalAlign: 'middle',
    borderRadius: '50%',
    marginRight: 8,
})

const cssTestResultOk = css({
    backgroundColor: 'green',
})

const cssTestResultFailed = css({
    backgroundColor: 'red',
})

const useTestConnection = () => {
    const http = useHTTP()

    return (args) => runAsync(
        () => http.post('/ins/testConnection', args),
        {action: '测试数据库连接'}
    )
}

const TestResult = ({isOk, ...props}) => {
    if (undefined === isOk) {
        return null
    }

    const csss = [
        cssTestResult,
        isOk ? cssTestResultOk : cssTestResultFailed
    ]

    return <div css={csss} {...props}></div>
}

const ButtonTestConnection = (props) => {
    const testConnection = useTestConnection()
    const [{dbInsUserName, dbInsUserPassword, url}] = useProperties()
    const [isOk, setIsOk] = useState()

    const handleClick = async () => {
        try {
            await testConnection({dbInsUserName, dbInsUserPassword, url})
            setIsOk(true)
        }
        catch (err) {
            setIsOk(false)
            throw err
        }
    }

    return (
        <Button
            disabled={! (dbInsUserName && dbInsUserPassword && url)}
            size="small"
            onClick={handleClick}
            {...props}
        >
            <TestResult isOk={isOk} />
            测试连接
        </Button>
    )
}

const PropertiesControllerUrl = () => {
    return (
        <LongText
            labelSuffix={<ButtonTestConnection />}
            prop="url"
        />
    )
}

export default PropertiesControllerUrl
