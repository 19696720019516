import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'svrNo',
    detailUrl: '/SvrDetail',
    idProp: 'svrId',
    mapProp: 'svrMap',
    name: '业务服务',
    textProp: 'svrName',
    type: 'SVR',
}
