import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'eventUserCode',
    name: '业务事件',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    textProp: 'eventTitle',
    type: 'BDEVENT',
}
