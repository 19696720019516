import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelBdterm.mjs'

const PropertiesPaneBdterm = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="termTitle" />
                <Text prop="termUserCode" />
                <TagSelect prop="termKey" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBdterm
