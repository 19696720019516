export const deleteChildren = (map, node) => {
    for (const child of [...map.children(node)]) {
        map.deleteTree(child)
    }
}

export const exportTree = (map, node) => map.exportTree(
    node, ({id, isHidden, ...n}) => n
)

export const exportNode = node => node.export(
    ({id, isHidden, ...n}) => n
)

export const replaceWithTree = (map, node, tree) => {
    const {children, ...props} = tree
    deleteChildren(map, node)

    for (const tree of children) {
        const child = map.importTree(tree)
        map.appendChild(node, child)
    }

    // 设置某些属性的行为可能取决于子节点，故要先处理子节点再设置属性
    Object.assign(node, props)
}
