import NodeMeta from './nodes/meta.mjs'

export const fromNavigatorMap = (BizNode, mapData) => {
    const {version = 0} = mapData

    return [
        // v0 -> latest
        ({root}) => ({root: fromTree(BizNode, [], root, version)}),

        // latest
        (a) => a,
    ][version](mapData)
}

export const fromProductMap = (BizNode, mapData) => {
    const {version = 0} = mapData

    return [
        // v0 -> latest
        ({root, ...data}) => ({
            data,
            root: fromTree(BizNode, [], root, version),
        }),

        // latest
        (a) => a,
    ][version](mapData)
}

export const fromTree = (BizNode, chain, tree, version = 0) => {
    return [
        (tree) => {
            const bizNodeType = (() => {
                if (tree.data) {
                    return tree.data.bizNodeType
                }
                else {
                    return tree.bizNodeType
                }
            })()

            const {
                children = [],
                data,
                isFolded = false,
                mapJson,
            } = (() => {
                const {mapProp} = NodeMeta[bizNodeType] ?? {}

                if (tree.data && tree.style) {
                    const {
                        children,
                        data: {[mapProp]: mapJson, ...data},
                        isFolded,
                        style,
                    } = tree

                    return {
                        children,
                        data: {...data, style},
                        isFolded,
                        mapJson,
                    }
                }
                else if (tree.data) {
                    const {
                        children,
                        data: {[mapProp]: mapJson, ...data},
                        isFolded,
                    } = tree

                    return {children, data, isFolded, mapJson}
                }
                else if (tree.style) {
                    const {
                        [mapProp]: mapJson,
                        children,
                        isFolded,
                        style,
                        ...data
                    } = tree

                    return {
                        children,
                        data: {...data, style},
                        isFolded,
                        mapJson,
                    }
                }
                else {
                    const {
                        [mapProp]: mapJson,
                        children,
                        isFolded,
                        ...data
                    } = tree

                    return {
                        children,
                        data,
                        isFolded,
                        mapJson,
                    }
                }
            })()

            const node = {data, isFolded}

            const mapChildren = (children) => children.map(
                (subTree) => fromTree(
                    BizNode, [node, ...chain], subTree, version
                )
            )

            if (0 < children.length || ! mapJson) {
                return {
                    children: mapChildren(children),
                    ...node,
                }
            }
            else {
                const map = JSON.parse(mapJson)

                if (Array.isArray(map)) {
                    return {
                        children: mapChildren(map),
                        ...node,
                    }
                }
                else {
                    return {
                        children: mapChildren(map.children),
                        ...node,
                    }
                }
            }
        },
    ][version](tree)
}

export const toNavigatorMap = (mapData, version = 0) => {
    return [
        // latest -> v0
        ({root}) => ({
            root: toTree(root, version),
        }),

        // latest
        (a) => a,
    ][version](mapData)
}

export const toProductMap = (mapData, version = 0) => {
    return [
        // latest -> v0
        ({root, data}) => ({
            ...data,
            root: toTree(root, version),
        }),

        // latest
        (a) => a,
    ][version](mapData)
}

export const toTree = (tree, version = 0) => {
    return [
        // latest -> v0
        ({children, data, isFolded}) => ({
            ...data,
            children: children.map((tree) => toTree(tree, version)),
            isFolded,
        }),

        // latest
        (a) => a,
    ][version](tree)
}
