import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaSfRule.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        onPush(map, node, type, data) {
            if ('SF' !== type) {
                return
            }

            data.algList.push(this._getPushData(map, node))
        },
    }
}
