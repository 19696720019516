import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'

const PropertiesControllerProjectNumber = () => {
    return (
        <Text
            prop="prjNo"
            readOnly
            title="项目编号"
        />
    )
}

export default PropertiesControllerProjectNumber
