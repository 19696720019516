import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaCopr.mjs'
import OprType from '../FOPR/OprType.jsx'
import Model from '../FOPR/ModelFopr.mjs'
import PropertiesPane from '../FOPR/PropertiesPaneFopr.jsx'

export default (BizNode) => {
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        canMountType(map, node, type) {
            return /^(CRES|PIC)$/.test(type)
        },

        getInitData(map, parent) {
            return {
                ...ComponentNode.getInitData.call(this, map, parent),
                oprType: 'GENERAL',
            }
        },

        getDefaultChildData(map, node) {
            return BizNode.CRES.getInitData(map, node)
        },

        getIcons(map, node) {
            const type2icon = new Map(
                OprType.flat().map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(node.data.oprType)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'HorizontalHexagon',
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('FRES' === bizNodeType) {
                BizNode.CRES.castFrom(map, child)
            }

            ComponentNode.onAttached.call(this, map, node, child)
        },

        onPull(map, node) {
            ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            let dataKey, oprCat, oprScene, sceneNo

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if ('CAT' === bizNodeType) {
                    if (! oprCat) {
                        oprCat = BizNode.CAT.getTextRaw(map, n)
                    }
                }
                else if (BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('ROLE' === bizNodeType) {
                    dataKey = 'ucOprList'
                    oprScene = BizNode.ROLE.getTextRaw(map, n)
                    sceneNo = String(n.id)
                    break
                }
            }

            if (! dataKey) {
                return
            }

            const d = {
                ...this._getPushData(map, node, {child: []}),
                oprCat,
                oprScene,
                picId: '',
                picName: '',
                sceneNo,
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data

                if ('PIC' === bizNodeType) {
                    const {fileId: picId, text: picName} = child.data

                    if (picId) {
                        Object.assign(d, {picId, picName})
                        break
                    }
                }
            }

            data[dataKey].push(d)
        },
    }
}
