import useCategoryNode from '../useCategoryNode.jsx'
import {replaceWithTree} from '../../scripts/map.mjs'
import meta from './metaBfRule.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canGrow(map, node) {
            return true
        },

        async grow(map, node, growedNodes) {
            if (growedNodes) {
                return
            }

            const bfBd = [...map.children(node.parent)].find(
                n => 'BF_BD' === n.data.bizNodeType
            )

            if (! bfBd) {
                return
            }

            const bdIds = []

            const next = chain => {
                const yieldChildren = 'BD' !== chain[0].data.bizNodeType
                return {yieldChildren}
            }

            for (const n of map.walkDown(bfBd, {excludeTarget: true, next})) {
                if ('BD' === n.data.bizNodeType) {
                    bdIds.push(n.data.pkid)
                }
            }

            const bds = await Promise.all(bdIds.map(
                pkid => BizNode.BD.readTree(map, {pkid})
            ))

            const makeCat = (text, children, isFolded = false) => ({
                children,

                data: {
                    ...BizNode.CAT.getInitData(map, node),
                    text,
                },

                isFolded,
            })

            const cats = [
                '业务术语',
                '业务场景',
                '业务规则',
                '数据样本',
                '数据结构',
                '数据关系',
                '业务事务能力',
                '业务基础能力',
            ].map(text => makeCat(text, []))

            const extractBdItem = (bd, bdCat, index) => {
                const bdName = bd.data[BizNode.BD.textProp]
                const cat = makeCat(bdName, bdCat.children, true)
                cats[index].children.push(cat)
            }

            for (const bd of bds) {
                for (const bdCat of bd.children) {
                    if (0 < bdCat.children.length) {
                        const i = [
                            'BD_TERM',
                            'BD_SUMMARY',
                            'BD_RULE',
                            'BD_SAMPLE',
                            'BD_STRUCT',
                            'BD_RELATION',
                            'BD_DEVELOP',
                            'BD_BASE',
                        ].indexOf(bdCat.data.bizNodeType)

                        if (-1 < i) {
                            extractBdItem(bd, bdCat, i)
                        }
                    }
                }
            }

            const children = cats.filter(a => 0 < a.children.length)
            replaceWithTree(map, node, {children})
        },
    }
}
