export const canMakeProduct = BizNode => () => {
    return function (node) {
        const {bizNodeType, pkid} = node.data

        if (pkid) {
            this.logger.error('只有概念节点才能生成制品', [node])
            return false
        }

        const bn = BizNode[bizNodeType]

        if (! bn.create) {
            this.logger.error(`${bn.name}不支持生成制品`, [node])
            return false
        }

        return BizNode[bizNodeType].canWriteNode(this, node)
    }
}

export const makeProduct = BizNode => () => {
    return function (nodes) {
        return Promise.allSettled(nodes.map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data

                    const updates = await BizNode[bizNodeType].create(
                        this, node
                    )

                    node.data = {...node.data, ...updates}
                    this.logger.info('生成模型制品成功', [node])
                }
                catch (err) {
                    this.logger.error(
                        `生成模型制品失败: ${err.message}`,
                        [node]
                    )

                    err.nodes = [node]
                    throw err
                }
            }
        ))
    }
}
