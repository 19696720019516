import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDto.mjs'
import PropertiesPane from './PropertiesPaneDto.jsx'
import QueryForm from './QueryFormSelectDto.jsx'
import Table from './TableSelectDto.jsx'
import useApi from './useApiDto.mjs'
import Model from './ModelDto.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(CAT|DTF)$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getDefaultChildData(map, node) {
            return BizNode.DTF.getInitData(map, node)
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.DARK_CYAN}
                        letters="DT"
                        textColor="#666"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {dtoDfList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.GREEN_YELLOW,
                }),

                shape: 'SingleBreakangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['dtoDfList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('DF' === bizNodeType) {
                BizNode.DTF.castFrom(map, child)
            }

            ProductNode.onAttached.call(this, map, node, child)
        },

        onPull(map, node) {
            ProductNode.onPull.call(this, map, node)
            node.isFolded = false
        },
    }
}
