import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDataTypeGroup from '../components/SelectDataTypeGroup.jsx'

const PropertiesControllerDataTypeGrpCode = ({disabled, readOnly, ...props}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const handleChange = (dataTypeGrpCode) => {
        if (/^(Basic|Other)$/.test(dataTypeGrpCode)) {
            update({
                dfDtoCode: '',
                dfDtoId: '',
                dfDtoName: '',
                dfDtoUserCode: '',
                dataTypeGrpCode,
            })
        }
        else if ('DTO' === dataTypeGrpCode) {
            update({
                dataTypeCode: '',
                dataTypeGrpCode,
            })
        }
        else {
            update({dataTypeGrpCode})
        }
    }

    return (
        <div {...props}>
            <Label required={model.required('dataTypeGrpCode')}>
                {model.name('dataTypeGrpCode')}
            </Label>

            <SelectDataTypeGroup
                disabled={disabled || model.disabled('dataTypeGrpCode')}
                readOnly={readOnly || gReadOnly}
                value={values.dataTypeGrpCode}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerDataTypeGrpCode
