export const canViewMap = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data
        const {mapIdProp} = BizNode[bizNodeType]
        const {[mapIdProp]: mapId} = node.data

        if (! mapId) {
            this.logger.error('没有创建过地图，不能查看', [node])
            return false
        }

        return true
    }
}

export const viewMap = BizNode => () => {
    return function (nodes) {
        for (const node of nodes) {
            const {bizNodeType} = node.data
            BizNode[bizNodeType].viewMap(this, node)
        }
    }
}
