import {useBdt} from '@/biz/bizTable.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useModel} from '../../bizNodeModel.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDataType from '../components/SelectDataType.jsx'

const PropertiesControllerBdt = ({disabled, readOnly, ...props}) => {
    const map = useMapContext()
    const {prjId} = map.data
    const {dict: Bdt} = useBdt({prjId})
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)
    const bdt = Bdt[values.bdtCode]

    const handleChange = ({
        code,
        id,
        name,
    }) => {
        update({
            bdtCode: code,
            bdtId: id,
            bdtName: name,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required('bdtCode')}>
                {model.name('bdtCode')}
            </Label>

            <SelectDataType
                disabled={disabled || model.disabled('bdtCode')}
                readOnly={readOnly || gReadOnly}

                value={{
                    code: values?.bdtCode,
                    name: bdt?.bdtName,
                    type: 'BDT',
                }}

                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerBdt
