import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        publish: (data) => http.post(
            '/depVss/modifySts',
            {...data, vsStsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/depVss/${pkid}/dps`),
        push: (data) => http.post('/depVss/batchmodify', data),
        read: ({pkid}) => http.get('/oneDepVs', {pkid}),
        readList: (args) => http.get('/listDepVsByMap', args),

        revise: ({pkid}) => http.post(
            '/depVss/modifySts',
            {pkid, vsStsCode: 'REVISE'}
        ),

        updateMap: (data) => http.put(`/depVss/${data.pkid}/map`, data),
    }
}
