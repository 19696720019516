import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import PropertiesPane from '../SVRALG/PropertiesPaneSvralg.jsx'
import PropertiesPaneBatch from '../SVRALG/PropertiesPaneBatchSvralg.jsx'
import meta from './metaSvrbase.mjs'

export default (BizNode) => {
    const AlgNode = useAlgNode(BizNode, meta)

    return {
        ...AlgNode,
        algType: 'BASE',

        canMountType(map, node, type) {
            return BizNode.SVRALG.canMountType.call(this, map, node, type)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        grow(map, node, growedNodes) {
            return BizNode.SVRALG.grow.call(this, map, node, growedNodes)
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane, PropertiesPaneBatch],
            },
        },

        onCreate(map, node) {
            return BizNode.SVRALG.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            return BizNode.SVRALG.onPush.call(this, map, node, type, data)
        },
    }
}
