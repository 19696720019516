import {SearchOutlined} from '@ant-design/icons'
import InputText from '@/components/Form/InputText.jsx'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function InputModalSelect({
    className,
    style,
    disabled,
    getText,
    Modal,
    multiple,
    readOnly,
    onChange,
    ...props
}) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const text = getText(multiple)

    const handleChangeInput = text => {
        if (! text) {
            onChange(null)
        }
    }

    const handleClickInput = async () => {
        if (readOnly) {
            return
        }

        const {prjId, sVer} = map.data
        const params = {prjId, sVer, ...props}

        const result = await openModal(
            <Modal
                multiple={multiple}
                params={params}
            />
        )

        if (result) {
            onChange(result)
        }
    }

    const icon = readOnly ?
        null
        :
        <SearchOutlined
            disabled={disabled}
            onClick={handleClickInput}
        />

    return (
        <InputText
            className={className}
            style={style}
            addonAfter={icon}
            allowClear
            disabled={disabled}
            readOnly
            value={text}
            title={text}
            onChange={handleChangeInput}
            onClick={handleClickInput}
            onKeyDown={e => e.stopPropagation()}
        />
    )
}
