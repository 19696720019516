import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDb from '../DB/SelectDb.jsx'

const PropertiesControllerDb = ({
    codeProp = 'dbCode',
    disabled,
    idProp = 'dbId',
    nameProp = 'dbName',
    readOnly,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        [codeProp]: dbCode,
        [idProp]: dbId,
        [nameProp]: dbName,
    } = values

    const handleChange = db => {
        if (db) {
            const {dbCode, dbId, dbName} = db

            update({
                [codeProp]: dbCode,
                [idProp]: dbId,
                [nameProp]: dbName,
            })
        }
        else {
            update({
                [codeProp]: '',
                [idProp]: '',
                [nameProp]: '',
            })
        }
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectDb
                disabled={disabled || model.disabled('codeProp')}
                readOnly={readOnly || gReadOnly}
                value={{dbCode, dbId, dbName}}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerDb
