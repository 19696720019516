import {Button} from 'antd'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {ModalDfkAdd} from '@/pages/MainPage/frames/DfList/DfkAdd.jsx'
import useBizNode from '../../useBizNode.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'

export default function PropertiesPaneButtonMakeFk(props) {
    const openModal = useOpenModal()
    const BizNode = useBizNode()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const [{dbId, dtId, prjId, sVer}] = useProperties()

    if (! (dbId && dtId && prjId && sVer)) {
        return null
    }

    for (const node of selectedNodes) {
        if (! BizNode.DF.isMounted(map, node)) {
            return null
        }
    }

    const fieldRows = [...selectedNodes].map(n => n.data)

    const handleClick = async () => {
        const result = await openModal(
            <ModalDfkAdd
                dbId={dbId}
                dtId={dtId}
                prjId={prjId}
                sVer={sVer}
                fieldRows={fieldRows}
            />
        )

        if (! result) {
            return
        }

        const {data} = await BizNode.DT.readTree(
            map, {pkid: result.fk.refDtId}
        )

        await map.cmd(() => {
            for (const node of selectedNodes) {
                const fk = map.importTree({
                    data: {
                        ...result.fk,
                        bizNodeType: 'FK',
                    },
                })

                map.appendChild(node, fk)

                const df = map.importTree({
                    data: {
                        ...result.refDfs.get(node.data.pkid),
                        bizNodeType: 'DF',
                    },
                })

                map.appendChild(fk, df)
                const dt = map.importTree({data})
                map.appendChild(df, dt)
            }
        })
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >生成外键</Button>
    )
}
