import {forwardRef} from 'react'
import {css} from '@emotion/react'
import usePermission from '@/components/TreeDoc/plugins/usePermission.mjs'
import {Canvas, MindMap} from '@/components/MindMap/index.mjs'
import defaultHotkeys from '../hotkeys.mjs'
import NodeText from '../NodeText.jsx'
import useBizNode from '../useBizNode.mjs'
import useMindMapPlugins from '../plugins/useMindMapPlugins.mjs'
import useNodeProxy from '../plugins/useNodeProxy.mjs'
import useRunAction from '../plugins/useRunAction.mjs'
import useShowNodeDesc from '../plugins/useShowNodeDesc.mjs'
import useTreeUtils from '../plugins/useTreeUtils.mjs'
import DefaultNavPanel from '../SidePanel/NavPanel/NavPanel.jsx'
import DefaultNodePanel from '../SidePanel/NodePanel/NodePanel.jsx'
import DefaultStatusBar from '../StatusBar/StatusBar.jsx'
import DefaultToolBar from '../ToolBar/ToolBar.jsx'

const cssMap = css({
    display: 'grid !important',
    width: '100%',
    height: '100%',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateColumns: 'auto 1fr auto',

    gridTemplateAreas: `
        "Top    Top    Top"
        "Left   Main   Right"
        "Bottom Bottom Bottom"
    `,

    backgroundColor: '#fff',
    overflow: 'hidden',
    userSelect: 'none',

    '*': {
        '&::-webkit-scrollbar': {
            width: 4,
            height: 4,
        },

        '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: '#c1c1c1',

            '&:hover': {
                backgroundColor: '#a8a8a8',
            },
        },
    },
})

const cssMain = css({
    gridArea: 'Main',
})

const cssNavPanel = css({
    gridArea: 'Left',
})

const cssNodePanel = css({
    gridArea: 'Right',
})

const cssToolBar = css({
    gridArea: 'Top',
})

const cssStatusBar = css({
    gridArea: 'Bottom',
})

const getNodeByPath = (map, BizNode, path) => {
    const nodeTexts = path
        .slice(1, -1)
        .split('/')
        .map(s => decodeURIComponent(s))

    const match = (node, texts) => {
        const {bizNodeType} = node.data
        const text = BizNode[bizNodeType].getTextRaw(map, node)

        if (text === texts[0]) {
            if (1 < texts.length) {
                for (const child of map.children(node)) {
                    const matchedNode = match(child, texts.slice(1))

                    if (matchedNode) {
                        return matchedNode
                    }
                }

                return null
            }
            else {
                return node
            }
        }
        else {
            return null
        }
    }

    return match(map.root, nodeTexts)
}

const getNodeByQuery = (map, BizNode, query) => {
    const {
        isMounted,
        ...dataQuery
    } = query

    const dataQueryEntries = Object.entries(dataQuery)

    const match = (node) => dataQueryEntries.every(
        ([key, value]) => node.data[key] === value
    )

    const next = (chain) => {
        const node = chain[0]
        const {bizNodeType} = node.data

        if (undefined !== isMounted) {
            const isNodeMounted = BizNode[bizNodeType].isMounted(map, node)

            return {
                yieldChildren: isMounted === isNodeMounted,
                yieldNode: isMounted === isNodeMounted,
            }
        }
    }

    for (const node of map.walkDown(map.root, {excludeTarget: true, next})) {
        if (match(node)) {
            return node
        }
    }
}

const getInitialSelectedNode = ({map, BizNode, query, path}) => {
    const node = (() => {
        if (path) {
            return getNodeByPath(map, BizNode, path)
        }

        if (query) {
            return getNodeByQuery(map, BizNode, query)
        }
    })()

    return node ?? map.root
}

export default forwardRef(function BaseMap(
    {
        components: {
            NavPanel = DefaultNavPanel,
            NodePanel = DefaultNodePanel,
            StatusBar = DefaultStatusBar,
            ToolBar = DefaultToolBar,
            ...components
        } = {},

        hotkeys,
        mapPath,
        permission = [],
        plugins = [],
        q,
        showNodeDesc = false,
        onLoad,
        ...props
    },

    ref
) {
    const BizNode = useBizNode()

    const handleLoad = (map) => {
        const node = getInitialSelectedNode({
            BizNode,
            map,
            path: mapPath,
            query: q && JSON.parse(q),
        })

        map.execute(() => map.selectNodes([node]))
        onLoad?.(map)
    }

    return (
        <MindMap
            ref={ref}
            css={cssMap}

            plugins={[
                useNodeProxy(),
                usePermission(permission),
                useRunAction(),
                useShowNodeDesc(showNodeDesc),
                useTreeUtils(),
                useMindMapPlugins(),
                ...plugins
            ]}

            components={{...components, NodeText}}
            hotkeys={{...defaultHotkeys, ...hotkeys}}
            onLoad={handleLoad}
            {...props}
        >
            <Canvas css={cssMain} />
            <ToolBar css={cssToolBar} />
            <StatusBar css={cssStatusBar} />
            <NavPanel css={cssNavPanel} />
            <NodePanel css={cssNodePanel} />
        </MindMap>
    )
})
