import useAttributeNode from '../useAttributeNode.jsx'
import IconQuote from '../icons/icon-quote.svg?react'

const prop = 'remark'
const type = 'ATTR_PARAM_REMARK'

export default (BizNode) => {
    const AttributeNode = useAttributeNode(BizNode, {prop, type})

    return {
        ...AttributeNode,

        getIcons() {
            return [<IconQuote key="type" />]
        },

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                shape: 'Rectangle',
            }
        },
    }
}
