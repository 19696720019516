import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaSvrSummary.mjs'

export default (BizNode) => {
    const AlgCatNode = useAlgCatNode(BizNode, meta)

    return {
        ...AlgCatNode,
        algNodeType: 'SVRSUMMARY',

        canMountType(map, node, type) {
            return /^(SVRSUMMARY|CAT)$/.test(type)
        },
    }
}
