import {memo, useRef} from 'react'
import {css} from '@emotion/react'
import useElementSize from '@/hooks/useElementSize.mjs'
import {useTreeDocContext} from '@/components/TreeDoc/index.mjs'

const COLOR_SELECTED = 'rgba(233, 84, 32, 1)'
const COLOR_HOVERED = 'rgba(233, 84, 32, .3)'

const cssNodeContent = css({
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    outline: '2px solid transparent',
    outlineOffset: 2,
    pointerEvents: 'auto',

    '&:hover': {
        outlineColor: COLOR_HOVERED,
    },
})

const cssText = css({
    position: 'relative',
    padding: '.4em .5em',
})

const NodeContent = ({node, ...props}) => {
    const map = useTreeDocContext()
    const refEl = useRef()
    const refElText = useRef()
    map.useRegisterDom('nodes', node, refEl)
    map.useNodeChange(node, ['data'])
    const isEditingText = map.useIsEditingText(node)
    const isSelected = map.useIsNodeSelected(node)
    const title = map.behaviours.getNodeTitle(node)
    const textRect = useElementSize(refElText, {width: 0, height: 0})

    const handleClick = (e) => {
        map.behaviours.onClickNode(node, e)
    }

    const handleContextMenu = (e) => {
        e.preventDefault()
        map.behaviours.onContextMenu(node, e)
    }

    const handleDoubleClick = (e) => {
        map.behaviours.onDoubleClickNode(node, e)
    }

    const handlePointerDown = (e) => {
        e.stopPropagation()

        // 阻止输入框失焦
        if (isEditingText) {
            e.preventDefault()
        }
    }

    const [shape, paddings] = map.behaviours.getNodeShape(node, textRect)

    const style = {
        backgroundImage: `url('${shape}')`,
        padding: paddings.map((n) => `${n}px`).join(' '),
    }

    if (isSelected) {
        Object.assign(style, {outlineColor: COLOR_SELECTED})
    }

    return (
        <div
            ref={refEl}
            css={cssNodeContent}
            style={style}
            title={title}
            onClick={handleClick}
            onContextMenu={handleContextMenu}
            onDoubleClick={handleDoubleClick}
            onPointerDown={handlePointerDown}
            {...props}
        >
            <map.components.TreeJoint node={node} />

            <div ref={refElText}>
                <map.components.NodeText
                    css={cssText}
                    node={node}
                />
            </div>
        </div>
    )
}

const MemorizedNodeContent = memo(NodeContent, () => true)
MemorizedNodeContent.displayName = 'NodeContent'

export default MemorizedNodeContent
