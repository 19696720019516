import {css} from '@emotion/react'
import TopBar from '../../ToolBar/TopBar.jsx'
import DifferenceCount from './DifferenceCount.jsx'
import {useDiffMapContext} from './DiffMap.jsx'
import MapInfo from './MapInfo.jsx'
import Menu from './Menu.jsx'

const cssToolBar = css({
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    placeItems: 'start center',
})

export default function ToolBar(props) {
    const diffMap = useDiffMapContext()

    return (
        <TopBar
            css={cssToolBar}
            {...props}
        >
            <MapInfo map={diffMap.leftMap} />

            <div>
                <Menu />
                <DifferenceCount />
            </div>

            <MapInfo map={diffMap.rightMap} />
        </TopBar>
    )
}
