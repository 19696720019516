import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDm from '../DM/SelectDm.jsx'

const PropertiesControllerDm = ({
    codeProp = 'dmCode',
    dbCodeProp = 'dbCode',
    dbIdProp = 'dbId',
    dbNameProp = 'dbName',
    disabled,
    idProp = 'dmId',
    nameProp = 'dmName',
    readOnly,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        [codeProp]: dmCode,
        [dbCodeProp]: dbCode,
        [dbIdProp]: dbId,
        [idProp]: dmId,
        [nameProp]: dmName,
    } = values

    const handleChange = dm => {
        if (dm) {
            const {
                dbCode,
                dbId,
                dbName,
                dmCode,
                dmId,
                dmName,
            } = dm

            update({
                [codeProp]: dmCode,
                [dbCodeProp]: dbCode,
                [dbIdProp]: dbId,
                [dbNameProp]: dbName,
                [idProp]: dmId,
                [nameProp]: dmName,
            })
        }
        else {
            update({
                [codeProp]: '',
                [idProp]: '',
                [nameProp]: '',
            })
        }
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectDm
                dbCode={dbCode}
                dbId={dbId}
                disabled={disabled || model.disabled('codeProp')}
                readOnly={readOnly || gReadOnly}
                value={{dmCode, dmId, dmName}}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerDm
