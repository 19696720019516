import {NodePropertiesPane} from '../../SidePanel/NodePanel/NodePane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelUrl.mjs'

const PropertiesPaneUrl = (props) => (
    <NodePropertiesPane
        Model={Model}
        {...props}
    >
        <Text prop="text" />
        <LongText prop="address" />
    </NodePropertiesPane>
)

export default PropertiesPaneUrl
