import useHTTP from '@/hooks/useHTTP.mjs'
import {fromNavigatorMap} from '../../compatibility.mjs'
import {exportTree, replaceWithTree} from '../../scripts/map.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../useModuleNode.jsx'
import meta from './metaBm.mjs'
import Model from './ModelBm.mjs'
import PropertiesPane from './PropertiesPaneBm.jsx'

export default (BizNode) => {
    const http = useHTTP()
    const ModuleNode = useModuleNode(BizNode, meta, Model)

    const readIsMap = async ({pkid}) => {
        const {
            mapModel,
            ...data
        } = await http.get(`/basmaps/${pkid}/IS`)

        return {
            ...data,
            mapModel: JSON.parse(mapModel),
        }
    }

    return {
        ...ModuleNode,

        canMountType(map, node, type) {
            return /^(BM)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return this.getInitData(map, node)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if (/^[DF]M$/.test(bizNodeType)) {
                this.castFrom(map, child)
            }

            ModuleNode.onAttached.call(this, map, node, child)
        },

        onPush(map, node, type, data) {
            const key = {
                BA: 'archBmList',
                BM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },

        async grow(map, node, growedNodes) {
            if ('IS_MAP' === map.data.mapTypeCode) {
                if (! this.canGrow(map, node)) {
                    return
                }

                const {[this.mapIdProp]: mapId, pkid} = node.data

                if (growedNodes?.has(pkid)) {
                    map.logger.warn('为避免无限加载，跳过已加载过的节点', [node])
                    return
                }

                const mapData = await readIsMap({pkid: mapId})
                const oldTree = exportTree(map, node)

                const oldChildren = oldTree.children.filter(
                    tree => this.canMountTree(map, node, tree)
                )

                const {root: {children: newChildren}} = fromNavigatorMap(
                    BizNode, mapData.mapModel
                )

                const tree = {
                    ...oldTree,
                    children: [...oldChildren, ...newChildren],
                }

                replaceWithTree(map, node, tree)
                node.isFolded = 0 < growedNodes?.size

                for (const n of map.walkDown(node, {excludeTarget: true})) {
                    n.isFolded = false
                }

                await this._growChildren(map, node, growedNodes)
            }
            else {
                return ModuleNode.grow.call(this, map, node, growedNodes)
            }
        },
    }
}
