import useComponentNode from '../useComponentNode.jsx'
import * as defaultConfig from './metaAlgNode.mjs'
import PropertiesPane from './PropertiesPaneAlg.jsx'
import DefaultModel from './ModelAlg.mjs'

export default (BizNode, config, Model, api) => {
    const ComponentNode = useComponentNode(
        BizNode,
        {...defaultConfig, ...config},
        Model ?? DefaultModel,
        api
    )

    return {
        ...ComponentNode,
        algType: 'GENERAL',

        canMountType(map, node, type) {
            return 'AR' === type
        },

        castFrom(map, node) {
            ComponentNode.castFrom.call(this, map, node)
            const {algType} = this
            node.data = {...node.data, algType}
        },

        getInitData(map, parent) {
            const {algType} = this

            return {
                ...ComponentNode.getInitData.call(this, map, parent),
                algType,
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            BizNode.AR.onAttached.call(this, map, node, child)
        },

        _getStyle(map, node, styleDone) {
            return {
                ...ComponentNode._getStyle.call(this, map, node, styleDone),
                shape: 'EllipseRectangle',
            }
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(
                this, map, node, slots
            )

            const {bizNodeType} = node.data

            const tree = BizNode[bizNodeType].exportTree(
                map, node, {excludeGrowed: true}
            )

            const algMap = JSON.stringify(tree.children)
            return {...data, algMap}
        },
    }
}
