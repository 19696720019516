import runAsync from '@/script/runAsync.mjs'
import useBizNode from '../../useBizNode.mjs'
import useProject from '../useProject.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (pkid, type) => {
    const BizNode = useBizNode()

    const {data} = useSWR(
        `/maps/${pkid}`,

        () => runAsync(
            () => BizNode[type].readMap(null, {pkid}),
            {action: '读取地图'}
        ),
    )

    const {data: project} = useProject(data?.data.prjId)

    if (data && project) {
        return {
            ...data,
            data: {...data.data, pkid, project}
        }
    }
    else {
        return undefined
    }
}
