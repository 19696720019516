import useCategoryNode from '../useCategoryNode.jsx'
import defaultMeta from './metaArCatNode.mjs'

export default (BizNode, config) => {
    const CategoryNode = useCategoryNode(
        BizNode,
        {...defaultMeta, ...config}
    )

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return 'AR' === type
        },

        getDefaultChildData(map, node) {
            return BizNode.AR.getInitData(map, node)
        },

        isCatAr: true,

        onAttached(map, node, child) {
            if (
                ! this.canLinkTree(map, node, child) &&
                ! this.canMountTree(map, node, child)
            ) {
                BizNode.AR.castFrom(map, child)
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },

        onPull(map, node) {
            CategoryNode.onPull.call(this, map, node)
            node.isFolded = true
        },

        _getPushData(map, node) {
            const {bizNodeType} = node.data

            const tree = BizNode[bizNodeType].exportTree(
                map, node, {excludeGrowed: true}
            )

            const mapJson = JSON.stringify(tree.children)

            return {
                ...tree.data,
                id: node.id,
                [this.mapProp]: mapJson,
            }
        },
    }
}
