import {css} from '@emotion/react'
import Menu from './Menu.jsx'
import TopBar from './TopBar.jsx'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'center',
})

export default function ToolBar(props) {
    return (
        <TopBar
            css={cssToolBar}
            {...props}
        >
            <Menu />
        </TopBar>
    )
}
