import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'

const TableSelectMidins = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '中间件名称',
            dataIndex: 'middlewareName',
            component: <Table.ViewText />,
        },

        {
            title: '中间件类型',
            dataIndex: 'middlewareTypeName',
            component: <Table.ViewText />,
        },

        {
            title: '中间件实例名称',
            dataIndex: 'middlewareInsName',
            component: <Table.ViewText />,
        },

        {
            title: '环境',
            dataIndex: 'middlewareInsEnv',
            component: <Table.ViewText />,
        },

        {
            title: '内网IP',
            dataIndex: 'middlewareInsIntranetIp',
            component: <Table.ViewText />,
        },

        {
            title: '公网IP',
            dataIndex: 'middlewareInsInternetIp',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/midinss"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableSelectMidins
