import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaBdsummary.mjs'
import PropertiesPane from './PropertiesPaneBdsummary.jsx'

export default (BizNode) => {
    const AlgNode = useAlgNode(BizNode, meta)

    return {
        ...AlgNode,
        algType: 'SUMMARY',

        getDefaultChildData(map, node) {
            return BizNode.AR.getInitData(map, node)
        },

        getIcons(map, node) {
            const {storyType} = node.data

            if ('SYS' === storyType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREEN}
                        letters="S"
                        textColor="#000"
                    />
                ]
            }
            else if ('TMPL' === storyType) {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREY}
                        letters="S"
                        textColor="#fff"
                    />
                ]
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.LIGHT_YELLOW}
                        letters="S"
                        textColor="#000"
                    />
                ]
            }
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onCreate(map, node) {
            node.data = {...node.data, storyType: 'COMMON'}
        },

        onPush(map, node, type, data) {
            const key = {
                BD: 'algList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {fcCode: '', sfCodes: []}
            )

            data[key].push(pushData)
        },
    }
}
