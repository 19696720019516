import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    mapProp: 'map',
    name: '子系统实例',
    ownerIdProp: 'ssId',
    ownerTextProp: 'ssName',
    ownerType: 'SS',
    textProp: 'ssInsName',
    type: 'SSINS',
}

