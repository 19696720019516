import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import TextArea from '@/components/Form/TextArea.jsx'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectBasicDataType from '../components/SelectBasicDataType.jsx'
import SelectDto from '../DTO/SelectDto.jsx'

const PropertiesControllerDataType = ({disabled, readOnly, ...props}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        dataTypeCode,
        dataTypeGrpCode: grpCode,
        dfDtoCode: dtoCode,
        dfDtoName: dtoName,
        dfDtoUserCode: dtoUserCode,
        ssCode,
    } = values

    const input = (() => {
        if ('Basic' === grpCode) {
            const handleChange = (dataTypeCode) => {
                update({dataTypeCode})
            }

            return (
                <SelectBasicDataType
                    disabled={disabled || model.disabled('dataTypeCode')}
                    readOnly={readOnly || gReadOnly}
                    value={dataTypeCode}
                    onChange={handleChange}
                />
            )
        }
        else if ('Other' === grpCode) {
            const handleChange = (dataTypeCode) => {
                update({dataTypeCode})
            }

            return (
                <TextArea
                    autoSize={{minRows: 3}}
                    changeDelay={null}
                    disabled={disabled || model.disabled('dataTypeCode')}
                    readOnly={readOnly || gReadOnly}
                    value={dataTypeCode}
                    onChange={handleChange}
                    onKeyDown={e => e.stopPropagation()}
                />
            )
        }
        else {
            const handleChange = ({
                dataTypeCode,
                dtoCode,
                dtoId,
                dtoName,
                dtoUserCode,
            }) => {
                update({
                    dfDtoCode: dtoCode,
                    dfDtoId: dtoId,
                    dfDtoName: dtoName,
                    dfDtoUserCode: dtoUserCode,
                    dataTypeCode,
                })
            }

            return (
                <SelectDto
                    dataType={'DTO' === grpCode ? 'Ref' : undefined}
                    disabled={disabled || model.disabled('dataTypeCode')}
                    readOnly={readOnly || gReadOnly}
                    ssCode={ssCode}

                    value={{
                        dataTypeCode,
                        dtoCode,
                        dtoName,
                        dtoUserCode,
                        grpCode,
                    }}

                    onChange={handleChange}
                />
            )
        }
    })()

    return (
        <div {...props}>
            <Label required={model.required('dataTypeCode')}>
                {model.name('dataTypeCode')}
            </Label>

            {input}
        </div>
    )
}

export default PropertiesControllerDataType
