import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'
import MenuButton from '../MenuButton.jsx'

export default function MenuButtonPushProduct(props) {
    const map = useMapContext()
    map.usePermission()

    if (! isProductMap(map)) {
        return null
    }

    const {canPushProduct, pushAndSave} = map.commands
    const nodes = canPushProduct([map.root])

    if (0 === nodes.length) {
        return null
    }

    return (
        <MenuButton
            onClick={pushAndSave}
            {...props}
        >
            更新制品
        </MenuButton>
    )
}
