import useBaseNode from './useBaseNode.jsx'

export default (BizNode, config) => {
    const BaseNode = useBaseNode(BizNode, config)

    return {
        ...BaseNode,

        canWriteNode() {
            return false
        },

        canWriteTree() {
            return false
        },

        getDesc(map, node) {
            return ''
        },
    }
}
