import {useBdType} from '@/biz/bizTable.mjs'
import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Bm from '../components/PropertiesControllerBm.jsx'
import Ss from '../components/PropertiesControllerSs.jsx'
import Model from './ModelSvr.mjs'

const PropertiesPaneSvr = (props) => {
    const {pairs: bdTypePairs} = useBdType()

    return (
        <ProductPropertiesPane
            controllers={
                <>
                    <Ss />
                    <Bm />
                    <Text prop="svrNo" />
                    <Text prop="svrName" />

                    <Select
                        label={e => e.nameCn}
                        options={bdTypePairs}
                        prop="svrType"
                    />

                    <LongText prop="memo" />
                </>
            }

            Model={Model}
            {...props}
        />
    )
}

export default PropertiesPaneSvr
