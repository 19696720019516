import {NodePropertiesPane} from '../../SidePanel/NodePanel/NodePane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import ArTypeCode from './PropertiesControllerArTypeCode.jsx'
import Model from './ModelAr.mjs'

const PropertiesPaneAr = (props) => (
    <NodePropertiesPane
        Model={Model}
        {...props}
    >
        <LongText prop="arName" />
        <ArTypeCode prop="arTypeCode" />
    </NodePropertiesPane>
)

export default PropertiesPaneAr
