import useBizNode from '../../useBizNode.mjs'
import {fromProductMap} from '../../compatibility.mjs'
import useProject from '../useProject.mjs'

export default (data) => {
    const BizNode = useBizNode()
    const {data: project} = useProject(data.prjId)

    if (project) {
        return {
            ...fromProductMap(BizNode, data),
            data: {...data, project},
        }
    }
    else {
        return undefined
    }
}
