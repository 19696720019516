import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import {fromTree} from '../../compatibility.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaSvrrule.mjs'
import Model from './ModelSvrrule.mjs'
import RuleType from './RuleType.jsx'
import PropertiesPane from './PropertiesPaneSvrrule.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchSvrrule.jsx'

const ruleTypes = new Map(
    RuleType.map(([type, name, icon]) => [type, {icon, name, type}])
)

export default (BizNode) => {
    const http = useHTTP()
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        canMountType(map, node, type) {
            return /^(AR|DOC|PIC|URL)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.AR.getInitData(map, node)
        },

        getDesc(map, node) {
            const {ruleType} = node.data

            if (! ruleType || 'COMMON' === ruleType) {
                return this.name
            }

            const {name = ''} = ruleTypes.get(ruleType) ?? {}
            return name
        },

        getIcons(map, node) {
            const {ruleType} = node.data
            const {icon} = ruleTypes.get(ruleType) ?? {}

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getInitData(map, parent) {
            return {
                ...ComponentNode.getInitData.call(this, map, parent),
                ruleType: 'COMMON'
            }
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        async grow(map, node, growedNodes) {
            await ComponentNode.grow.call(this, map, node, growedNodes)
            const {prjId, rev, ruleCode, sVer} = node.data

            const result = await runAsync(
                () => http.get(
                    '/dmbds/rule/extend',
                    {prjId, rev, ruleCode, sVer}
                ),

                {action: '读取实现者'},
            )

            if (! result) {
                return
            }

            const tree = fromTree(BizNode, [], JSON.parse(result))
            const ruleImplementer = map.importTree(tree)
            map.prependChild(node, ruleImplementer)
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane, PropertiesPaneBatch],
            },
        },

        onPush(map, node, type, data) {
            const key = {
                SVR: 'ruleList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(
                this, map, node, slots
            )

            const {bizNodeType} = node.data

            const tree = BizNode[bizNodeType].exportTree(
                map, node, {excludeGrowed: true}
            )

            const ruleMap = JSON.stringify(tree.children)
            return {...data, ruleMap}
        },
    }
}
