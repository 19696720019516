export const getNodeTextStyle = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data

        const {
            fontSize,
            fontStyle,
            fontWeight,
            textAlign,
            textColor,
            textDecoration,
        } = {
            ...BizNode[bizNodeType].getStyle(this, node),
            ...node.data.style,
        }

        return {
            color: textColor,
            fontSize,
            fontStyle,
            fontWeight,
            textAlign,
            textDecoration,
        }
    }
}
