import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import {fromTree} from '../../compatibility.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaBdevent.mjs'
import Model from './ModelBdevent.mjs'
import PropertiesPane from './PropertiesPaneBdevent.jsx'

export default (BizNode) => {
    const http = useHTTP()
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        canMountType(map, node, type) {
            return /^EVENT_(PUBLISHER|SUBSCRIBER)$/.test(type)
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="E"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        async grow(map, node, growedNodes) {
            await ComponentNode.grow.call(this, map, node, growedNodes)
            const {prjId, rev, eventCode, sVer} = node.data

            const result = await runAsync(
                () => http.get(
                    '/dmbds/event/extend',
                    {prjId, rev, eventCode, sVer}
                ),

                {action: '加载业务事件'},
            )

            if (! result) {
                return
            }

            for (const n of map.children(node)) {
                const {bizNodeType} = n.data

                if (/^EVENT_(PUBLISHER|SUBSCRIBER)$/.test(bizNodeType)) {
                    map.deleteTree(n)
                }
            }

            for (const raw of JSON.parse(result).reverse()) {
                const tree = fromTree(BizNode, [], raw)
                const n = map.importTree(tree)
                map.prependChild(node, n)
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onCreate(map, node) {
            const childTypes = [
                'EVENT_PUBLISHER',
                'EVENT_SUBSCRIBER',
                'EVENT_DESC',
            ]

            for (const type of childTypes) {
                const data = map.BizNode[type].getInitData(map, node)
                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BD: 'eventList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(this, map, node, slots)
            const n = map.nodeProxy(node)
            const tree = n.exportTree(map, node, {excludeGrowed: true})
            const eventMap = JSON.stringify(tree.children)
            return {...data, eventMap}
        },
    }
}
