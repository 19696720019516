import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'userCode',
    ownerIdProp: 'fcId',
    ownerTextProp: 'fcName',
    ownerType: 'FC',
    textProp: 'name',
}
