import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaBfdataflow.mjs'
import PropertiesPane from './PropertiesPaneBfdataflow.jsx'
import QueryForm from './QueryFormSelectBfdataflow.jsx'
import Table from './TableSelectBfdataflow.jsx'
import useApi from './useApiBfdataflow.mjs'

export default (BizNode) => {
    const api = useApi()
    const AlgNode = useAlgNode(BizNode, meta, null, api)

    return {
        ...AlgNode,
        algType: 'DATAFLOW',

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getDefaultChildData(map, node) {
            return BizNode.AR.getInitData(map, node)
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="SS"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onPush(map, node, type, data) {
            const key = {
                BF: 'algList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(
                map,
                node,
                {fcCode: '', sfCodes: []}
            )

            data[key].push(pushData)
        },
    }
}
