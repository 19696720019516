import {Space} from 'antd'
import {NodePropertiesPane} from '../../SidePanel/NodePanel/NodePane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ButtonDownload from '../components/PropertiesPaneButtonDownload.jsx'
import ButtonUpload from './PropertiesPaneButtonUpload.jsx'
import Model from './ModelDoc.mjs'

const PropertiesPaneDoc = (props) => (
    <NodePropertiesPane
        Model={Model}
        {...props}
    >
        <Text prop="text" />

        <Space>
            <ButtonDownload />
            <ButtonUpload />
        </Space>
    </NodePropertiesPane>
)

export default PropertiesPaneDoc
