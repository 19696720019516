import {Col, Form, Input, Row, Select} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemDtoCode = (props) => {
    return (
        <Form.Item
            label="DTO代码"
            name="dtoUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemDtoName = (props) => {
    return (
        <Form.Item
            label="DTO名称"
            name="dtoName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemDr = (props) => {
    return (
        <Form.Item
            label="是否DR"
            name="isDr"
            {...props}
        >
            <Select options={[
                {label: '否', value: '0'},
                {label: '是', value: '1'},
            ]} />
        </Form.Item>
    )
}

const QueryFormSelectDto = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemDtoCode />
            </Col>

            <Col span={6}>
                <FormItemDtoName />
            </Col>

            <Col span={6}>
                <FormItemDr />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectDto
