import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/NodePanel/PropertiesControllerCheck.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelBj.mjs'

const PropertiesPaneBj = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="bjNo" />
                <Text prop="bjName" />
                <Check prop="onlineFlag" />
                <LongText prop="bjPos" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBj
