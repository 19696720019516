import {Modal} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import useCategoryNode from './useCategoryNode.jsx'
import meta from './metaArchCatNode.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,
        ...meta,

        getDefaultChildData(map, node) {
            const {childType} = this
            return map.BizNode[childType].getInitData(map, node)
        },

        onDoubleClick(map, node, event) {
            const {childType, detailUrl} = this

            for (const n of map.walkDown(node)) {
                const {bizNodeType} = n.data

                if (childType === bizNodeType) {
                    const {pkid: prjId, prjNo, sVer} = map.data.project
                    const url = buildUrl(detailUrl, {prjId, prjNo, sVer})
                    window.open(url)
                    return
                }
            }

            const {name} = map.BizNode[childType]

            Modal.warn({
                content: `请先插入${name}制品再双击跳转`,

                onOk: () => {
                    if (this.canWriteTree(map, node)) {
                        map.BizNode[childType].onClickMenuItemInsertProduct(
                            map, [node]
                        )
                    }
                },
            })
        },
    }
}
