import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    catProp: 'bjCat',
    codeProp: 'bjNo',
    mapProp: 'bjMap',
    name: '业务作业',
    ownerIdProp: 'bfId',
    ownerTextProp: 'bfName',
    ownerType: 'BF',
    textProp: 'bjName',
    type: 'BJ',
}
