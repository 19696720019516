import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    bmCode: {
        name: () => '业务域',
    },

    ssCode: {
        name: () => '子系统',
    },

    svrName: {
        name: () => '业务服务名称',
        required: () => true,
    },

    svrNo: {
        name: () => '业务服务代码',
        required: () => true,
    },

    svrType: {
        name: () => '业务服务类型',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },
})
