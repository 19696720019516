export const canPushProduct = BizNode => () => {
    return function (node) {
        const {bizNodeType, pkid} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能更新', [node])
            return false
        }

        const bn = BizNode[bizNodeType]

        if (! bn.push) {
            this.logger.error(`${bn.name}不支持更新`, [node])
            return false
        }

        return bn.canWriteNode(this, node)
    }
}

export const pushProduct = BizNode => () => {
    return function (nodes) {
        return Promise.allSettled(nodes.map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data

                    const id2updates = await BizNode[bizNodeType].push(
                        this, node
                    )

                    for (const [id, updates] of id2updates) {
                        const node = this.getNode(id)
                        node.data = {...node.data, ...updates}
                        const {bizNodeType: t} = node.data
                        await BizNode[t].onPushDone(this, node)
                    }

                    this.logger.info('更新模型制品成功', [node])
                }
                catch (err) {
                    this.logger.error(
                        `更新模型制品失败: ${err.message}`,
                        [node]
                    )

                    err.nodes = [node]
                    throw err
                }
            }
        ))
    }
}
