import {fromProductMap} from '../../compatibility.mjs'
import useBizNode from '../../useBizNode.mjs'
import BaseMap from '../BaseMap.jsx'

export default function EmbeddedMap({mapData, ...props}) {
    const BizNode = useBizNode()

    const components = {
        NavPanel: () => null,
        ToolBar: () => null,
    }

    return (
        <BaseMap
            components={components}
            initData={fromProductMap(BizNode, mapData)}
            {...props}
        />
    )
}
