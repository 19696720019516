import React, {useEffect, useState} from 'react';
import TabLayout from '@/pages/MainPage/components/FrameLayout';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Page from '@/script/Page';
import EditableTableExt from "@/pages/MainPage/components/EditableTableExt";
import Table from '@/components/Table/Table.jsx';
import usePermission from "@/hooks/usePermission.mjs";
import buildUrl from '@/script/buildUrl.mjs'
import {Button, Modal, Space} from "antd";
import openWindow from '@/script/openWindow.mjs';

const getRev = (row) => {
    const {dataVer, smallVer, verFlag} = row
    const v = dataVer ? dataVer : Number.parseInt(verFlag)
    return smallVer ? `${v}.${smallVer}` : String(v)
}

// 地图版本
const Frame = ({id, code, type, prjId, sVer}) => {
    const http = useHTTP();

    // 地图比对类型
    const [compareTypes, setCompareTypes] = useState([
        'BD', 'UI', 'BF', 'SVR', 'MID',// 固定节点+部件+通用对比
        'SF', // 固定节点+通用对比
        'UC', 'FC', 'SS', 'BA', 'FA', 'UA', 'DA', 'DB', 'LS', 'LSI', 'VR', // 通用对比
        'SA_MAP', 'BIZ_MAP', 'SF_MAP', 'UI_MAP', 'DATA_MAP', 'INF_MAP', 'FC_MAP', 'DEP_MAP', // 集成地图-通用对比
        'DT', // 数据表对比
        'CDG', // 标准字段组对比
        'CDT', //  定制数据类型对比
        'DTO', // DTO对比
    ]);

    // 数据模型
    const [mapVers, setMapVers] = useState([]);
    const [columns, setColumns] = useState([]);

    const [selectedRows, setSelectedRows] = useState([]); // 选中的对象集合

    // 加载显示
    const [loading, setLoading] = useState(false);

    // 更新权限
    const hasUpt = usePermission([type + '_UPT'], [prjId], []);

    // 组件初始化
    useEffect(() => {
        setColumns(optColumns)
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);

            const data = await runAsync(
                async () => {
                    return await http.get(`/basmapvers`, { dataId: id, dataCode: code, dataType: type, prjId });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            setMapVers(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 保存备注
    const doSave = async (mapVer) => {
        try {
            setLoading(true);

            const data = await runAsync(
                async () => {
                    return await http.put(`/basmapvers/${mapVer.pkid}`, mapVer);
                }, {
                error: { content: (err) => `保存失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            //setOptLogs(data);
        } catch (err) {
            console.log(err);
            // 有异常重新加载日志
            doSearch();
        } finally {
            setLoading(false);
        }
    }

    // 查看历史版本
    const ViewVersion = ({ value: { dataVer, pkid, dataType }, ...props }) => {
        const openVersion = (pkid) => {
            const url = dataType === 'UV' ?
                buildUrl('/FormilyLog', {id: pkid})
                :
                buildUrl('/SoftwareMap', {id: pkid, mode: 'snapshot'});
            window.open(url);
        }

        return (<a href="javascript:void(0);" rel="noreferrer" onClick={() => openVersion(pkid)} {...props}>{dataVer}</a>);
    }

    // 对比
    const ViewCompare = ({leftRev, verId}) => {
        const onClick = async () => {
            setLoading(true);
            const title = await runAsync(
                async () => {
                    return await http.get(`/mapcompare/title`, { verId, id, type });
                }, {
                error: { content: (err) => `标题获取失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const url = buildUrl(
                '/DiffMap',
                {
                    id,
                    leftRev,
                    type: type,
                    title: title.replace(/^(.+?【.+?】).*$/, '$1'),
                }
            );

            window.open(url);
            setLoading(false);
        }

        return (
            <a
                href="javascript:void(0);"
                rel="noreferrer"
                /*target="_blank"*/
                title={"比对当前版本"}
                onClick={onClick}
            >
                比对当前版本
            </a>
        )
    }

    // 模型交付单详情
    const linkDes = (desId) => {
        openWindow('/DesDetail', { desId });
    }

    const optColumns = [
        {
            dataIndex: 'crtTime',
            title: '发布时间',
            width: 180,
            component: <Table.ViewText />,
        },
        {
            dataIndex: 'crtName',
            title: '发布人',
            component: <Table.ViewText />,
            width: 120,
        },
        {
            title: '基线号',
            dataIndex: 'sVer',
            align: 'center',
            width: 80,
        },
        {
            dataIndex: 'dataVer',
            title: '版本号',
            width: 80,
            align: 'center',
            render: (text, record, index) => {
                return <ViewVersion value={{ dataVer: record?.dataVer ?? record?.verFlag, pkid: record?.pkid, dataType: record.dataType }} />;
            }
        },
        /*{
            title: '小版本号',
            dataIndex: 'smallVer',
            align: 'center',
            width: 80,
        },*/
        {
            dataIndex: 'deliverFlag',
            title: '是否交付',
            width: 90,
            align: 'center',
            render: (text, record, index) => {
                switch (text) {
                    case "1":
                        return "是";
                    case "0":
                        return "";
                }
            },
        },
        {
            dataIndex: 'deliverUserName',
            title: '交付人',
            component: <Table.ViewText />,
            width: 120,
        },
        {
            dataIndex: 'deliverTime',
            title: '交付时间',
            render: (text, record, index) => {
                if (record.deliverId) {
                    return <a href="javascript:void(0);" rel="noreferrer" onClick={() => linkDes(record.deliverId)}>{text}</a>
                }else{
                    return <Table.ViewText value={text}/>
                }
            },
            width: 180,
        },
        {
            dataIndex: 'taskNo',
            title: '业务需求号',
            component: <Table.ViewText />,
            width: 120,
        },
        {
            dataIndex: 'memo',
            title: '备注',
            component: (!hasUpt) && <Table.ViewLongText />,
            editable: hasUpt ? true : false,
        },
        compareTypes.includes(type) && {
            dataIndex: 'opt',
            title: '操作',
            width: 120,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <ViewCompare
                        leftRev={getRev(record)}
                        verId={record.pkid}
                    />
                )
            }
        }
    ].filter(r => r);


    // 处理table 回调
    const doCallData = (row) => {
        const newData = [...mapVers];
        const index = newData.findIndex((item) => row.pkid === item.pkid);
        const item = newData[index];
        // 异步保存
        doSave({ ...item, ...row });

        newData.splice(index, 1, { ...item, ...row });
        setMapVers(newData);
    }

    const rowSelection = {
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const openCompareHis = async () => {
        if (selectedRows.length != 2) {
            return Modal.info({ title: '提示信息', content: '请选择两条数据' });
        }
        const sortedRows = selectedRows.sort((verA, verB) => {
            if (verA.crtTime < verB.crtTime) {
                return -1; // verA在verB之前
            } else if (verA.crtTime > verB.crtTime) {
                return 1; // verA在verB之后
            } else {
                return 0; // 日期相同
            }
        })

        setLoading(true);
        const title = await runAsync(
            async () => {
                return await http.get(`/mapcompare/title`, { verId: sortedRows[0].pkid, id: sortedRows[1].pkid });
            }, {
            error: { content: (err) => `标题获取失败: ${err.message}` },
            loading: { show: false },
            success: { show: false },
        });

        const url = buildUrl(
            '/DiffMap',
            {
                id,
                leftRev: getRev(sortedRows[0]),
                rightRev: getRev(sortedRows[1]),
                title: title.replace(/^(.+?【.+?】).*$/, '$1'),
                type,
            }
        )
        window.open(url);
        setLoading(false);
    }


    let ExtraButtons = [
        <Button type="primary" onClick={openCompareHis}>版本比对</Button>
    ]

    return (
        <TabLayout>
            <TabLayout.Header title="历史版本">

            </TabLayout.Header>

            <TabLayout.Content>
                <div className="smstable">
                    <div className="smstable-body">
                        {/* 按钮区 */}
                        <div className="smstable-body-toolbar">
                            <Space>
                                {compareTypes.includes(type) && ExtraButtons}
                            </Space>
                        </div>

                        {/* 数据区 */}
                        <EditableTableExt
                            doCallData={doCallData} rows={mapVers} columnss={columns}
                            loading={loading}
                            pagination={Page.init(mapVers)}
                            rowKey={record => record.pkid}
                            rowSelection={compareTypes.includes(type) ? {type: "checkbox", ...rowSelection} : null}
                        />
                    </div>
                </div>
            </TabLayout.Content>
        </TabLayout>
);
}

export default Frame;
