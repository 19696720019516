import useBizNode from '../useBizNode.mjs'

export default () => {
    const BizNode = useBizNode()

    const extensions = () => ({
        *walkUpNoComment(node) {
            for (const n of this.walkUp(node)) {
                const {bizNodeType} = n.data
                if (! BizNode[bizNodeType].isComment) {
                    yield n
                }
            }
        },
    })

    return {extensions}
}
