import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ButtonMap from '../components/PropertiesPaneButtonMap.jsx'
import Model from './ModelUm.mjs'

const PropertiesPaneUm = (props) => (
    <ComponentPropertiesPane
        buttons={<ButtonMap />}

        controllers={
            <>
                <Text prop="umUserCode" />
                <Text prop="umName" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneUm
