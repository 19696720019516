import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelBdrule.mjs'
import Analyse from './PropertiesControllerAnalyse.jsx'
import RuleType from './RuleType.jsx'

const PropertiesPaneBdrule = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="ruleTitle" />
                <Text prop="ruleUserCode" />

                <Select
                    options={RuleType}
                    prop="ruleType"
                />

                <TagSelect prop="ruleKey" />
            </>
        }

        Model={Model}
        productSnSuffix={<Analyse />}
        {...props}
    />
)

export default PropertiesPaneBdrule
