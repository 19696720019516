import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const map = useMapContext()
    const permission = map.usePermission()

    if (
        ! isProductMap(map) ||
        ! permission.has('write')
    ) {
        return null
    }

    const {canPushProduct, canSaveMap, saveMap} = map.commands
    const nodes = canPushProduct([map.root])

    return {
        disabled: ! canSaveMap(),
        key: 'save-map',
        label: 0 < nodes.length ? '保存不更新' : '保存',
        suffix: 0 < nodes.length ? '' : 'Ctrl+S',
        onClick: saveMap,
    }
}
