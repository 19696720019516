import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Model from './ModelBdterm.mjs'

const PropertiesPaneBatchBdterm = (props) => (
    <BatchPropertiesPane
        controllers={
            <>
                <TagSelect prop="termKey" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBatchBdterm
