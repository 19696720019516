import Color from '../Color.mjs'
import useArchNode from '../useArchNode.jsx'
import meta from './metaUa.mjs'
import PropertiesPane from './PropertiesPaneUa.jsx'
import useApi from './useApiUa.mjs'

export default (BizNode) => {
    const api = useApi()
    const ArchNode = useArchNode(BizNode, meta, api)

    return {
        ...ArchNode,

        canMountType(map, node, type) {
            return /^(UM)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.UM.getInitData(map, node)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archUmList: []})
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.LIGHT_BLUE,
            })
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archUmList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
