import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaAlgApi.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canLinkType(map, node, type) {
            return /^(FC)$/.test(type)
        },

        isHidden(map, node) {
            return /^(ALG|SF)_LVL$/.test(map.data.project?.desgAcc)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['FC'],
            ]
        },

        onPush(map, node, type, data) {
            if (! /^(BD|UI)ALG$/.test(type)) {
                return
            }

            data.fcCode = ''

            for (
                const n of
                map.walkDown(node, {excludeTarget: true})
            ) {
                const {bizNodeType, fcCode} = n.data

                if ('FC' === bizNodeType) {
                    data.fcCode = fcCode
                    break
                }
            }
        },
    }
}
