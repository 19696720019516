import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaSfDes.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        onPush(map, node, type, data) {
            if ('SF' !== type) {
                return
            }

            const tree = this.exportTree(map, node, {excludeGrowed: true})
            const algMap = JSON.stringify(tree.children)
            data.algList.push({...tree.data, algMap})
        },
    }
}
