import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'termUserCode',
    mapProp: 'termMap',
    name: '业务术语',
    ownerIdProp: 'svrId',
    ownerTextProp: 'svrName',
    ownerType: 'SVR',
    textProp: 'termTitle',
    type: 'SVRTERM',
}
