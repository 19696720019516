export function canUnfold(node) {
    if (! node.isFolded) {
        return false
    }

    const notHidden = n => ! this.behaviours.isNodeHidden(n)
    return [...this.children(node)].some(notHidden)
}

export function unfoldNode(nodes) {
    for (const node of nodes) {
        node.isFolded = false
    }
}

export function unfoldTree(nodes) {
    const packedNodes = this.topNodes(nodes)

    for (const node of packedNodes) {
        for (const n of this.walkDown(node)) {
            n.isFolded = false
        }
    }
}
