import useExclusiveNode from '../useExclusiveNode.jsx'
import PsType from './PsType.jsx'
import meta from './metaPs.mjs'
import PropertiesPane from './PropertiesPanePs.jsx'

export default (BizNode) => {
    const ExclusiveNode = useExclusiveNode(BizNode, meta)

    return {
        ...ExclusiveNode,

        canMountType(map, node, type) {
            return /^(DOC|FJ|MARK|PIC|PS)$/.test(type)
        },

        getInitData(map, parent) {
            return {
                ...ExclusiveNode.getInitData.call(this, map, parent),
                psTypeCode: 'PF',
                [this.textProp]: '',
            }
        },

        getDefaultChildData(map, node) {
            return BizNode.PS.getInitData(map, node)
        },

        getIcons(map, node) {
            const {psTypeCode} = node.data

            const type2icon = new Map(
                PsType.map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(psTypeCode)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...ExclusiveNode.getStyle.call(this, map, node),
                backgroundColor: '#f8f7f7',
                shape: 'Rectangle',
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
