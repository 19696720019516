import useAbstractNode from '../useAbstractNode.jsx'
import meta from './metaMidins_.mjs'
import QueryForm from '../MIDINS/QueryFormSelectMidins.jsx'
import Table from '../MIDINS/TableSelectMidins.jsx'
import useApi from './useApiMidins_.mjs'

export default (BizNode) => {
    const api = useApi()
    const AbstractNode = useAbstractNode(BizNode, meta, api)

    return {
        ...AbstractNode,

        async chooseProduct(map, node) {
            const items = await this._chooseProduct(
                map, node, QueryForm, Table
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'MIDINS'},
            }))
        },
    }
}
