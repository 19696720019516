import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Fm from '../components/PropertiesControllerFm.jsx'
import Ss from '../components/PropertiesControllerSs.jsx'
//import ButtonDemo from './PropertiesPaneButtonDemo.jsx'
import ButtonPreview from './PropertiesPaneButtonPreview.jsx'
import Analyse from './PropertiesControllerAnalyse.jsx'
import Model from './ModelUi.mjs'

const PropertiesPaneUi = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                {/* <ButtonDemo /> */}
                <ButtonPreview />
            </>
        }

        controllers={
            <>
                <Ss isUi={1} />
                <Fm />
                <Text prop="uiUserCode" />
                <Text prop="uiName" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}
        productSnSuffix={<Analyse />}
        {...props}
    />
)

export default PropertiesPaneUi
