import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaSfMain.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(CAT|FOPR)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.FOPR.getInitData(map, node)
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('COPR' === bizNodeType) {
                BizNode.FOPR.castFrom(map, child)
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },
    }
}
