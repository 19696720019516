import {Modal} from 'antd'
import http from '@/biz/http.mjs'
import {usePreviewImages} from '@/components/PreviewImages/PreviewImages'
import Color from '../Color.mjs'
import {ValidationError} from '../Error.mjs'
import useCommonNode from '../useCommonNode.jsx'
import IconPic from './icon-pic.svg?react'
import meta from './metaPic.mjs'
import PropertiesPane from './PropertiesPanePic.jsx'

export default (BizNode) => {
    const previewImages = usePreviewImages()
    const CommonNode = useCommonNode(BizNode, meta)

    return {
        ...CommonNode,

        canWriteNode(map, node) {
            const {bizNodeType, demoType} = node.parent.data

            if (
                'UV' === bizNodeType &&
                'IMG' === demoType &&
                BizNode[bizNodeType].isMounted(map, node.parent)
            ) {
                return map.permission.has('write')
            }
            else {
                return CommonNode.canWriteNode.call(this, map, node)
            }

        },

        getIcons() {
            return [<IconPic key="type" />]
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
                shape: 'BottomWavyRectangle',
            }
        },

        menuInsertCommon(map, nodes) {
            return this._menuInsertCommon(map, nodes, {rank: 40})
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onDoubleClick(map, node, event) {
            const {fileId} = node.data

            if (fileId) {
                event.preventDefault()
                const url = `${import.meta.env.VITE_HTTP_BASE_URL}/files/${fileId}`

                try {
                    await http.head(url)
                    previewImages([url])
                }
                // eslint-disable-next-line no-empty
                catch (err) {
                }
            }
        },

        onPush(map, node, type, data) {
            const dataKey = {
                BF: 'fileList',
                UI: 'demoList',
            }[type]

            if (! dataKey) {
                return
            }

            //if (node.data.fileId) {
                data[dataKey].push({
                    ...node.data,
                    id: node.id,
                })
            //}
            //else {
                //throw new ValidationError('没有上传图片', node)
            //}
        },
    }
}
