import useProductNode from '../useProductNode.jsx'
import meta from './metaCdg.mjs'
import Model from './ModelCdg.mjs'
import PropertiesPane from './PropertiesPaneCdg.jsx'
import QueryForm from './QueryFormSelectCdg.jsx'
import Table from './TableSelectCdg.jsx'
import useApi from './useApiCdg.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(CAT|DGF)$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {dms = [], ...query}) => ({
                ...query,
                dmIds: dms.map(({dmId}) => dmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getInitData(map, parent) {
            const d = ProductNode.getInitData.call(this, map, parent)

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    dmCode,
                    dmId,
                    dmName,
                } = ancestor.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    Object.assign(d, {
                        dmCode,
                        dmId,
                        dmName,
                    })

                    break
                }
            }

            return d
        },

        getDefaultChildData(map, node) {
            return BizNode.DGF.getInitData(map, node)
        },

        getIcons(map, node) {
            return BizNode.DT.getIcons.call(this, map, node)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {pcdgDfList: []})
        },

        getStyle(map, node) {
            return BizNode.DT.getStyle.call(this, map, node)
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['pcdgDfList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('DF' === bizNodeType) {
                BizNode.DGF.castFrom(map, child)
            }

            ProductNode.onAttached.call(this, map, node, child)
        },
    }
}
