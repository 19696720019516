import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBdSummary.mjs'

export default (BizNode) => {
    const AlgCatNode = useAlgCatNode(BizNode, meta)

    return {
        ...AlgCatNode,
        algNodeType: 'BDSUMMARY',

        canMountType(map, node, type) {
            return /^(BDSUMMARY|CAT)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.BDSUMMARY.getInitData(map, node)
        },

        getInitData(map, parent) {
            return {
                ...AlgCatNode.getInitData.call(this, map, parent),
                algType: 'SUMMARY',
            }
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '3' === bdType
        },
    }
}
