import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBfDataflow.mjs'

export default (BizNode) => {
    const AlgCatNode = useAlgCatNode(BizNode, meta)

    return {
        ...AlgCatNode,
        algNodeType: 'BFDATAFLOW',

        canLinkType(map, node, type) {
            if ('BM' === map.data.project.prjTypeCode) {
                return /^(BDSUMMARY)$/.test(type)
            }
            else {
                return false
            }
        },

        canMountType(map, node, type) {
            return /^(BFDATAFLOW|CAT)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            if ('BM' === map.data.project.prjTypeCode) {
                return [
                    ['BDSUMMARY_'],
                ]
            }
            else {
                return []
            }
        },
    }
}
