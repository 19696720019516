import IconLetters from '../icons/IconLetters.jsx'
import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import {replaceWithTree} from '../../scripts/map.mjs'
import meta from './metaSvr.mjs'
import Model from './ModelSvr.mjs'
import PropertiesPane from './PropertiesPaneSvr.jsx'
import QueryForm from './QueryFormSelectSvr.jsx'
import Table from './TableSelectSvr.jsx'
import useApi from './useApiSvr.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(SVR_(BASE|DEVELOP|EVENT|LOGIC|RULE|SU|SUMMARY|TERM))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {bms = [], ...query}) => ({
                ...query,
                bmIds: bms.map(({bmId}) => bmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getDefaultChildData(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return BizNode.UI.getInitData(map, node)
            }
        },

        getIcons(map, node) {
            const {svrType} = node.data

            if ('2' === svrType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="A"
                        textColor="#000"
                    />
                ]
            }
            else if ('3' === svrType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="D"
                        textColor="#000"
                    />
                ]
            }
            else {
                return []
            }
        },

        getPushData(map, node) {
            return this._getPushData(
                map,
                node,

                {
                    algList: [],
                    eventList: [],
                    ruleList: [],
                    sfList: [],
                    termList: [],
                }
            )
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'DoubleBreakangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(
                data,
                ['algList', 'eventList', 'ruleList', 'termList']
            )
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BF: 'svrList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)
            data[key].push(pushData)
        },

        onSetData(map, node, oldData) {
            ProductNode.onSetData.call(this, map, node, oldData)

            if (node.data.svrType === oldData.svrType) {
                return
            }

            const childTypes = {
                1: [
                    'SVR_TERM',
                    'SVR_SUMMARY',
                    'SVR_RULE',
                    'SVR_LOGIC',
                    'SVR_DEVELOP',
                    'SVR_BASE',
                    'SVR_SU',
                ],

                2: [
                    'SVR_TERM',
                    'SVR_SUMMARY',
                    'SVR_RULE',
                    'SVR_LOGIC',
                    'SVR_SU',
                ],

                3: [
                    'SVR_DEVELOP',
                    'SVR_BASE',
                    'SVR_SU',
                ],
            }

            const oldChildren = [...map.children(node)]

            const childMap = new Map(
                oldChildren.map(n => [n.data.bizNodeType, n])
            )

            const newChildren = childTypes[node.data.svrType].map(
                type => {
                    if (childMap.has(type)) {
                        return childMap.get(type)
                    }
                    else {
                        const data = BizNode[type].getInitData(map, node)
                        return map.importTree({data})
                    }
                }
            )

            for (const n of oldChildren) {
                map.unlinkTree(n)
            }

            for (const n of newChildren) {
                map.appendChild(node, n)
            }

            for (const n of oldChildren) {
                if (! n.parent) {
                    map.deleteTree(n)
                }
            }
        },

        async grow(map, node, growedNodes) {
            if ('IS_MAP' === map.data.mapTypeCode) {
                if (! this.canGrow(map, node)) {
                    return
                }

                const {deliverRev, deliverVer, lastRev, pkid} = node.data

                if (growedNodes?.has(pkid)) {
                    map.logger.warn('为避免无限加载，跳过已加载过的节点', [node])
                    return
                }

                const newTree = await (async () => {
                    const args = {briefType: 'IS', pkid}
                    const rev = this.getRev(map, node)

                    if (0 < rev) {
                        const tree = await this.readTree(map, {...args, rev})

                        Object.assign(
                            tree.data,
                            {deliverRev, deliverVer, lastRev, rev}
                        )

                        return tree
                    }
                    else {
                        return this.readTree(map, args)
                    }
                })()

                replaceWithTree(map, node, newTree)
                node.isFolded = 0 < growedNodes?.size

                for (const n of map.walkDown(node, {excludeTarget: true})) {
                    const {bizNodeType} = n
                    BizNode[bizNodeType].onPull(map, n)
                }

                await this._growChildren(map, node, growedNodes)
            }
            else {
                return ProductNode.grow.call(this, map, node, growedNodes)
            }
        },
    }
}
