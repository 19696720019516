import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableSelectUe_ = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()
    const {prjTypeCode} = map.data.project

    const columns = [
        'BCT' === prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '功能模块',
            dataIndex: 'fmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        {
            title: '界面名称',
            dataIndex: 'uiName',
            component: <Table.ViewText />,
        },

        {
            title: '界面代码',
            dataIndex: 'uiUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '能力标题',
            dataIndex: 'algTitle',
            getValue: ({algTitle, ueName}) => algTitle ?? ueName,
        },

        {
            title: '能力代码',
            component: <Table.ViewText />,
            getValue: ({algUserCode, ueUserCode}) => algUserCode ?? ueUserCode,
        },

        {
            title: '能力类型',
            dataIndex: 'algType',
            component: <Table.ViewDict dict={{GENERAL: '基础', UE: '事件'}} />,
        },
    ].filter(a => a)

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/ues"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableSelectUe_
