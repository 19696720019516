import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    archUrl: '/ProjectDataMap',
    codeProp: 'dbUserCode',
    detailUrl: '/DbDetail',
    idProp: 'dbId',
    mapProp: 'dbMap',
    name: '数据库',
    textProp: 'dbName',
    type: 'DB',
}
