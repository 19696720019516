import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableSelectBf = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()
    const {prjTypeCode} = map.data.project

    const columns = [
        {
            title: '业务域',
            dataIndex: 'bmNamePath',
            component: <Table.ViewText />,
        },

        'BCT' === prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '流程代码',
            dataIndex: 'bfNo',
            component: <Table.ViewText />,
        },

        {
            title: '流程名称',
            dataIndex: 'bfName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bfs"
            query={query}
            {...props}
        />
    )
}

export default TableSelectBf
