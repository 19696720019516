import useCategoryRootNode from '../useCategoryRootNode.jsx'
import meta from './metaCatRootDep.mjs'

export default (BizNode) => {
    const CategoryRootNode = useCategoryRootNode(BizNode, meta)

    return {
        ...CategoryRootNode,

        canMountType(map, node, type) {
            return /^DEP_(DB|MID|SS)$/.test(type)
        },
    }
}
