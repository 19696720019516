import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ruleUserCode',
    mapProp: 'ruleMap',
    name: '处理逻辑',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    textProp: 'ruleTitle',
    type: 'BDLOGIC',
}
