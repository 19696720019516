import TextArea from '@/components/Form/TextArea.jsx'
import Input from './PropertiesControllerInput.jsx'

const defaultAutoSize = {minRows: 3}

const PropertiesControllerLongText = ({
    className,
    style,
    disabled,
    hidden,
    labelSuffix,
    prop,
    required,
    title,
    value,
    ...props
}) => {
    return (
        <Input
            className={className}
            style={style}
            disabled={disabled}
            hidden={hidden}
            labelSuffix={labelSuffix}
            prop={prop}
            required={required}
            title={title}
            value={value}
        >
            <TextArea
                autoSize={defaultAutoSize}
                changeDelay={null}
                {...props}
            />
        </Input>
    )
}

export default PropertiesControllerLongText
