import buildUrl from '@/script/buildUrl.mjs'
import {fromProductMap, fromTree, toProductMap} from '../compatibility.mjs'
import useApiSnapshot from '../useApiSnapshot.mjs'
import useDesignNode from './useDesignNode.jsx'
import {exportTree, replaceWithTree} from '../scripts/map.mjs'

/**
 * 制品节点类型的基类
 */
export default (BizNode, {detailUrl, idProp, ...config}, Model, api) => {
    const apiSnapshot = useApiSnapshot()
    const DesignNode = useDesignNode(BizNode, config, Model, api)

    const ProductNode = {
        ...DesignNode,

        canGrow(map, node) {
            if (! this.readTree) {
                map.logger.error(`${this.name}不支持加载`, [node])
                return false
            }

            return DesignNode.canGrow.call(this, map, node)
        },

        canShrink(map, node) {
            if (! DesignNode.canShrink.call(this, map, node)) {
                return false
            }

            if (! this.readTree) {
                map.logger.error(`${this.name}不支持卸载`, [node])
                return false
            }

            return true
        },

        canWriteNode(map, node) {
            return (
                (
                    ! node.data.pkid ||
                    node === map.root
                ) &&

                DesignNode.canWriteNode.call(this, map, node)
            )
        },

        canWriteTree(map, node) {
            return (
                node === map.root &&
                DesignNode.canWriteTree.call(this, map, node)
            )
        },

        detailUrl,

        getCreateData(map, node) {
            this._validate(node)
            return {id: node.id, ...node.data}
        },

        getUrl(map, node, childPath = []) {
            const {
                [this.textProp]: text = '',
                pkid,
            } = node.data

            if (pkid) {
                const q = this._getUrlQuery(node)

                if (0 < childPath.length) {
                    const path = [encodeURIComponent(text), ...childPath]
                    q.mapPath = `/${path.join('/')}/`
                }

                return buildUrl(this.detailUrl, q)
            }
            else {
                return DesignNode.getUrl.call(this, map, node)
            }
        },

        async grow(map, node, growedNodes) {
            if (! this.canGrow(map, node)) {
                return
            }

            const {deliverRev, deliverVer, lastRev, pkid} = node.data

            if (growedNodes?.has(pkid)) {
                map.logger.warn('为避免无限加载，跳过已加载过的节点', [node])
                return
            }

            const newTree = await (async () => {
                const rev = this.getRev(map, node)

                if (0 < rev) {
                    const tree = await this.readTree(map, {pkid, rev})

                    Object.assign(
                        tree.data,
                        {deliverRev, deliverVer, lastRev, rev}
                    )

                    return tree
                }
                else {
                    return this.readTree(map, {pkid})
                }
            })()

            replaceWithTree(map, node, newTree)
            node.isFolded = 0 < growedNodes?.size

            for (const n of map.children(node)) {
                const {bizNodeType} = n.data
                BizNode[bizNodeType].onPull(map, n)
            }

            await this._growChildren(map, node, growedNodes)
        },

        idProp,
        isProduct: true,

        async jumpToDiff(map, node, leftRev, rightRev) {
            const {bizNodeType, pkid} = node.data
            const text = this.getTextRaw(map, node)

            const url = buildUrl(
                '/DiffMap',

                {
                    id: pkid,
                    leftRev,
                    rightRev,
                    title: `${this.name}【${text}】`,
                    type: bizNodeType,
                }
            )

            window.open(url)
        },

        mapPushResult(data) {
            return this._mapPushResult(data)
        },

        menuInsertConcept(map, nodes) {
            return this._menuInsertConcept(map, nodes)
        },

        async onDoubleClick(map, node, event) {
            const {
                bizNodeType: _1,
                deliverRev,
                deliverVer,
                lastRev: _2,
                pkid,
                snapshotId,
                sVer,
            } = node.data

            if (pkid) {
                if (node.parent) {
                    event.preventDefault()
                    const rev = this.getRev(map, node)

                    if (
                        deliverRev &&
                        deliverVer &&
                        '_DES_MAP' === map.data.mapTypeCode &&

                        (
                            deliverRev !== rev ||
                            deliverVer !== sVer
                        )
                    ) {
                        this.jumpToDiff(map, node, rev, deliverRev)
                    }
                    else if (
                        this.isOutdated(map, node) &&
                        0 < rev
                    ) {
                        this.jumpToDiff(map, node, rev)
                    }
                    else {
                        const url = buildUrl(
                            this.detailUrl, {[this.idProp]: pkid}
                        )

                        window.open(url)
                    }
                }
                else if (snapshotId) {
                    const url = buildUrl(
                        '/SoftwareMap', {id: snapshotId, mode: 'snapshot'}
                    )

                    window.open(url)
                }
                else if ('' === snapshotId) {
                    const url = buildUrl(
                        this.detailUrl, {[this.idProp]: pkid}
                    )

                    window.open(url)
                }
                else {
                    DesignNode.onDoubleClick.call(this, map, node, event)
                }
            }
            else {
                DesignNode.onDoubleClick.call(this, map, node, event)
            }
        },

        onPull(map, node) {
            DesignNode.onPull.call(this, map, node)

            if (this.attrNodes && ! node.parent) {
                this._initAttrNodes(map, node)
            }
        },

        async upgrade(map, node) {
            const {deliverRev, deliverVer, pkid} = node.data
            const tree = await this.readTree(map, {pkid})
            const {rev, stsCode} = tree.data

            if (! /^(RLS|REVISE)$/.test(stsCode)) {
                throw new Error('制品不是发布/修订状态，不能升级')
            }

            node.data = {
                ...tree.data,
                deliverRev,
                deliverVer,
                lastRev: rev,
                rev
            }
        },

        _getUrlQuery(node) {
            return {[this.idProp]: node.data.pkid}
        },

        _mapPushResult(result, listNames = []) {
            const id2updates = new Map()

            const collect = (items) => {
                for (const data of items) {
                    const {mapProp} = BizNode[data.bizNodeType]

                    const {
                        child = [],
                        id,
                        [mapProp]: _,
                        ...updates
                    } = data

                    if (id) {
                        id2updates.set(id, updates)
                        collect(child)
                    }
                }
            }

            for (const listName of listNames) {
                collect(result[listName])
            }

            return id2updates
        },
    }

    if (api?.create) {
        ProductNode.create = async function (map, node) {
            const data = this.getCreateData(map, node)
            const {prjId, sVer} = map.data

            const {
                [this.mapProp]: _,
                ...updates
            } = await api.create({...data, prjId, sVer})

            return {
                ...updates,
                stsCode: 'DRAFT',
                stsName: '草稿',
            }
        }
    }

    if (api?.discard) {
        ProductNode.discard = async function (map, node) {
            const {
                [this.mapProp]: _,
                ...updates
            } = await api.discard(node.data)

            return updates
        }
    }

    if (api?.publish) {
        ProductNode.publish = async function (map, node, extra) {
            const {pkid} = node.data

            const {
                [this.mapProp]: _,
                ...updates
            } = await api.publish({...extra, pkid})

            return updates
        }
    }

    if (api?.pull) {
        ProductNode.pull = async function (map, node) {
            const {
                [this.mapProp]: _,
                ...tree
            } = await api.pull(node.data)

            return fromTree(BizNode, [], tree)
        }
    }

    if (api?.push) {
        ProductNode.push = async function (map, node) {
            const args = this.getPushData(map, node)

            const {
                [this.mapProp]: _,
                ...data
            } = JSON.parse(await api.push(args))

            return this.mapPushResult(data)
        }
    }

    if (api?.read) {
        ProductNode.read = async function (map, args) {
            const {
                [this.mapProp]: _,
                ...node
            } = await api.read(args)

            return {...node, bizNodeType: this.type}
        }

        ProductNode.readTree = async function (map, args) {
            if (args.rev) {
                const snapshot = await apiSnapshot.readByRev(
                    {...args, bizNodeType: this.type}
                )

                const root = fromTree(BizNode, [], snapshot.root)
                const {prjNo} = snapshot.data
                Object.assign(root.data, {prjNo})
                return root
            }
            else {
                const {
                    [this.mapProp]: mapJson,
                    ...data
                } = await api.read(args)

                const tree = {
                    data: {
                        ...data,
                        bizNodeType: this.type,
                    },

                    children: [],
                }

                if (mapJson) {
                    const {root: {children}} = fromProductMap(
                        BizNode,
                        JSON.parse(mapJson)
                    )

                    Object.assign(tree, {children})
                }

                return tree
            }
        }

        if (config.mapProp) {
            ProductNode.readMap = async function (map, args) {
                const {
                    [this.mapProp]: mapJson,
                    ...rootData
                } = await api.read(args)

                const mapData = fromProductMap(
                    BizNode,
                    JSON.parse(mapJson)
                )

                mapData.data.mapRev = rootData.rev
                Object.assign(mapData.root.data, rootData)
                return mapData
            }
        }
    }

    if (api?.readHierarchyMap) {
        ProductNode.readHierarchyMap = async function (map, args) {
            const mapJson = await api.readHierarchyMap(args)

            return fromProductMap(
                BizNode,
                JSON.parse(mapJson)
            )
        }
    }

    if (api?.revise) {
        ProductNode.revise = async function (map, node) {
            const {
                [this.mapProp]: _,
                ...updates
            } = await api.revise(node.data)

            return updates
        }
    }

    if (api?.update) {
        ProductNode.update = async function (map, node) {
            const {
                [this.mapProp]: _,
                ...updates
            } = await api.update(node.data)

            return updates
        }
    }

    if (api.updateHierarchy) {
        ProductNode.updateHierarchy = async function (map, node) {
            const tree = exportTree(map, node)
            await api.updateHierarchy(tree)
        }
    }

    if ((api?.updateMap || api?.update) && config.mapProp) {
        const updateMap = api?.updateMap ?? api?.update

        ProductNode.updateMap = async function (map, node) {
            const mapName = this.getText(map, node)
            const root = this.exportTree(map, node, {excludeGrowed: true})

            const mapData = {
                data: {...map.data, mapName},
                root,
            }

            const json = JSON.stringify(toProductMap(mapData))

            const {
                [this.mapProp]: _,
                ...updates
            } = await updateMap({
                ...node.data,
                mapSize: json.length,
                nodeQty: node.descendantCount + 1,
                [this.mapProp]: json,
            })

            return updates
        }
    }

    if (api?.updateMigrate) {
        ProductNode.updateMigrate = async function (map, node) {
            const mapName = this.getText(map, node)
            const root = exportTree(map, node)

            const mapData = {
                data: {...map.data, mapName},
                root,
            }

            const migrateMap = JSON.stringify(toProductMap(mapData))

            const {
                [this.mapProp]: _1,
                migrateMap: _2,
                ...updates
            } = await api.updateMigrate({
                ...node.data,
                mapSize: migrateMap.length,
                migrateMap,
                nodeQty: node.descendantCount + 1,
            })

            return updates
        }
    }

    return ProductNode
}
