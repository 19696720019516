import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectSs from '../SS/SelectSs.jsx'

const PropertiesControllerSs = ({
    codeProp = 'ssCode',
    disabled,
    idProp = 'ssId',
    nameProp = 'ssName',
    readOnly,
    isUi,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        [codeProp]: ssCode,
        [idProp]: ssId,
        [nameProp]: ssName,
    } = values

    const handleChange = ss => {
        const {
            ssCode = '',
            ssId = '',
            ssName = '',
            terminalType,
        } = ss ?? {}

        update({
            [codeProp]: ssCode,
            [idProp]: ssId,
            [nameProp]: ssName,
            terminalType,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectSs
                isLeaf={false}
                disabled={disabled || model.disabled('codeProp')}
                isUi={isUi}
                readOnly={readOnly || gReadOnly}
                value={{ssCode, ssId, ssName}}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerSs
