export const canViewMap = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canViewMap(node)
        )
    }
}

export const viewMap = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.actions.viewMap(nodes)
        )
    }
}
