export default () => {
    const watchers = {
        task_finish() {
            setTimeout(() => {
                if (
                    ! document.activeElement ||
                    document.activeElement === document.body
                ) {
                    this.canvas.focus()
                }
            })
        },
    }

    return {watchers}
}
