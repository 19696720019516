import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonMakeApi from './PropertiesPaneButtonMakeApi.jsx'
import ButtonMakeSf from './PropertiesPaneButtonMakeSf.jsx'

const PropertiesPaneAlg = (props) => (
    <BatchPropertiesPane
        buttons={
            <>
                <ButtonMakeApi />
                <ButtonMakeSf />
            </>
        }

        {...props}
    />
)

export default PropertiesPaneAlg
