import {Button} from 'antd'
import useBizNode from '../../useBizNode.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'

const ButtonCreateMap = (props) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    map.useNodesChange(selectedNodes)
    const readOnly = useReadOnly()

    if (readOnly) {
        return null
    }

    const {canCreateMap, createMap} = map.commands
    const nodes = canCreateMap(selectedNodes)

    if (0 === nodes.length) {
        return null
    }

    const handleClick = () => createMap(nodes)

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}

const ButtonViewMap = (props) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    map.useNodesChange(selectedNodes)
    const {canViewMap, viewMap} = map.commands
    const nodes = canViewMap(selectedNodes)

    if (0 === nodes.length) {
        return null
    }

    const handleClick = () => viewMap(nodes)

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}

const PropertiesPaneButtonMap = (props) => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const BizNode = useBizNode()

    const types = new Set([...selectedNodes].map(
        ({data: {bizNodeType}}) => bizNodeType
    ))

    const mapTypeName = 1 === types.size ?
        BizNode[[...types][0]].mapName
        :
        ''

    return (
        <>
            <ButtonViewMap {...props}>
                查看{mapTypeName}地图
            </ButtonViewMap>

            <ButtonCreateMap {...props}>
                创建{mapTypeName}地图
            </ButtonCreateMap>
        </>
    )
}

export default PropertiesPaneButtonMap
