import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableSelectSvr = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()
    const {prjTypeCode} = map.data.project

    const columns = [
        {
            title: '业务域',
            dataIndex: 'bmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        'BCT' === prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务服务编号',
            dataIndex: 'svrNo',
            component: <Table.ViewText />,
        },

        {
            title: '业务服务名称',
            dataIndex: 'svrName',
            component: <Table.ViewText />,
        },

        {
            title: '业务服务类型',
            dataIndex: 'svrTypeName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/svrs"
            query={query}
            {...props}
        />
    )
}

export default TableSelectSvr
