import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelDes.mjs'
import ExportReq from './PropertiesPaneButtonExportReq.jsx'

const PropertiesPaneDes = (props) => (
    <ProductPropertiesPane
        buttons={<ExportReq />}

        controllers={
            <>
                <Text prop="desNo" />
                <Text prop="desTitle" />
                <Text prop="taskNo" />
            </>
        }

        Model={Model}
        noProductSn
        {...props}
    />
)

export default PropertiesPaneDes
