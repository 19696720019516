import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'pcdgUserCode',
    detailUrl: '/DmPcdgDetail',
    idProp: 'pcdgId',
    mapProp: 'pcdgMap',
    name: '标准字段组',
    textProp: 'pcdgName',
    type: 'CDG',
}
