import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelDto.mjs'

const PropertiesPaneDto = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="dtoUserCode" />
                <Text prop="dtoName" />
                <LongText prop="dtoDesc" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneDto
