import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'
import ButtonIs from './PropertiesPaneButtonIs.jsx'

const PropertiesPaneBa = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonIs />
                <ButtonExportModel type="bm" />
                <ButtonImportModules type="bm" />
            </>
        }

        {...props}
    />
)

export default PropertiesPaneBa
