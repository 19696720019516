import {useMapContext} from '@/components/MindMap/index.mjs'
import useBizNode from '../../useBizNode.mjs'
import MenuButton from '../MenuButton.jsx'

export const useMenuItemsInsertNode = (type) => {
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    const BizNode = useBizNode()

    const hookName = {
        common: 'menuInsertCommon',
        concept: 'menuInsertConcept',
    }[type]

    const nodesCanInsert = map.commands.canInsertChild(selectedNodes)

    if (0 === nodesCanInsert.length) {
        return []
    }

    return Object.values(BizNode)
        .map(bn => bn[hookName]?.(map, nodesCanInsert))
        .filter(a => a)
        .sort(([a], [b]) => a - b)
        .map(([, menuItem]) => menuItem)
}

export default function MenuButtonInsertNode({type, ...props}) {
    const items = useMenuItemsInsertNode(type)

    if (0 === items.length) {
        return null
    }

    const typeText = {
        common: '通用',
        concept: '模件定义',
    }[type]

    return (
        <MenuButton
            items={items}
            {...props}
        >
            {`插入${typeText}节点`}
        </MenuButton>
    )
}
