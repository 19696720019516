import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonImportModules from '../components/PropertiesPaneButtonImportModules.jsx'

const PropertiesPaneUa = (props) => (
    <ProductPropertiesPane
        buttons={<ButtonImportModules type="um" />}
        {...props}
    />
)

export default PropertiesPaneUa
