import {cloneElement} from 'react'
import useBizNode from '../../useBizNode.mjs'
import MenuButton from '../../ToolBar/MenuButton.jsx'
import {useDiffMapContext} from './DiffMap.jsx'

const ButtonCompare = ({leftMap, rightMap, ...props}) => {
    const BizNode = useBizNode()
    const leftSelectedNodes = leftMap.useSelectedNodes()
    const rightSelectedNodes = rightMap.useSelectedNodes()
    const button = <MenuButton {...props}>模件版本比对</MenuButton>

    if (
        1 !== leftSelectedNodes.size ||
        1 !== rightSelectedNodes.size
    ) {
        return cloneElement(button, {disabled: true})
    }

    const [l] = leftSelectedNodes
    const [r] = rightSelectedNodes
    const {bizNodeType: bntL, pkid: idL} = l.data
    const {bizNodeType: bntR, pkid: idR} = r.data

    if (idL !== idR) {
        return cloneElement(button, {disabled: true})
    }

    const revL = BizNode[bntL].getRev(leftMap, l)
    const revR = BizNode[bntR].getRev(rightMap, r)

    if (revL === revR) {
        return cloneElement(button, {disabled: true})
    }

    const onClick = () => {
        BizNode[bntL].jumpToDiff(leftMap, l, revL, revR)
    }

    return cloneElement(button, {onClick})
}

export default function MenuButtonCompareProduct(props) {
    const {leftMap, rightMap} = useDiffMapContext()

    if (! (leftMap && rightMap)) {
        return null 
    }

    return (
        <ButtonCompare
            leftMap={leftMap}
            rightMap={rightMap}
            {...props}
        />
    )
}
