import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaUeProg.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canLinkType(map, node, type) {
            return /^(FC)$/.test(type)
        },

        isHidden(map, node) {
            return /^(ALG|SF)_LVL$/.test(map.data.project?.desgAcc)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['FC'],
            ]
        },

        onPush(map, node, type, data) {
            if ('UE' !== type) {
                return
            }

            for (const n of map.children(node)) {
                const {bizNodeType, fcCode} = n.data

                if ('FC' === bizNodeType) {
                    data.child.push({ujFcCode: fcCode})

                    if (! data.fcCode) {
                        data.fcCode = fcCode
                    }
                }
            }
        },
    }
}
