import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaFcJob.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(CAT|MARK|FJ|PS)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.FJ.getInitData(map, node)
        },

        onPush(map, node, type, data) {
            if ('FC' !== type) {
                return
            }

            CategoryNode.onPush.call(this, map, node, type, data)
            const {bizNodeType} = node.data

            const tree = BizNode[bizNodeType].exportTree(
                map, node, {excludeGrowed: true}
            )

            data.jobMap = JSON.stringify(tree.children)
        },
    }
}
