import superMeta from '../metaArchNode.mjs'

export default {
    ...superMeta,
    archUrl: '/ProjectBizMap',
    detailUrl: '/BaDetail',
    idProp: 'baId',
    mapProp: 'baMap',
    name: '业务地图',
    type: 'BA',
}
