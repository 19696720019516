import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaSvrBase.mjs'

export default (BizNode) => {
    const AlgCatNode = useAlgCatNode(BizNode, meta)

    return {
        ...AlgCatNode,
        algNodeType: 'SVRBASE',

        canMountType(map, node, type) {
            return /^(SVRBASE|CAT)$/.test(type)
        },
    }
}
