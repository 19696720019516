import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from '../_ALG/ModelAlg.mjs'
import Analyse from './PropertiesControllerAnalyse.jsx'
import ButtonMakeApi from './PropertiesPaneButtonMakeApi.jsx'
import ButtonMakeSf from './PropertiesPaneButtonMakeSf.jsx'

const PropertiesPaneAlg = (props) => {
    const [{algType}] = useProperties()

    return (
        <ComponentPropertiesPane
            buttons={
                <>
                    <ButtonMakeApi />
                    <ButtonMakeSf />
                </>
            }

            controllers={
                <>
                    <Text prop="algTitle" />
                    <Text prop="algUserCode" />

                    {
                        'CONCEPT' !== algType &&
                            <TagSelect prop="algKey" />
                    }
                </>
            }

            Model={Model}
            productSnSuffix={<Analyse />}
            {...props}
        />
    )
}

export default PropertiesPaneAlg
