import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemTermTitle = (props) => {
    return (
        <Form.Item
            label="术语标题"
            name="termTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdName = (props) => {
    return (
        <Form.Item
            label="业务对象名称"
            name="bdName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdNo = (props) => {
    return (
        <Form.Item
            label="业务对象代码"
            name="bdNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const QueryFormSelectBdterm = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemBdName />
            </Col>

            <Col span={6}>
                <FormItemBdNo />
            </Col>

            <Col span={6}>
                <FormItemTermTitle />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectBdterm
