import {useEffect, useState} from 'react'
import {Button, Popover, Table} from 'antd'
import {css} from '@emotion/react'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import useHTTP from '@/hooks/useHTTP.mjs'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDataType from '../components/SelectDataType.jsx'

const cssCode = css({
    fontSize: 9,
    userSelect: 'all',
})

const CDTValuesTable = ({values, ...props}) => {
    const columns = [
        {
            title: '序列码',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
    ]

    return (
        <div tabIndex="-1">
            <Table
                {...props}
                bordered
                columns={columns}
                dataSource={values}
                pagination={false}
                size="small"
            />
        </div>
    )
}

const CDTValuesPopover = ({typeId, ...props}) => {
    const http = useHTTP()
    const [values, setValues] = useState([])

    useEffect(
        () => {
            if (! typeId) {
                return
            }

            (async () => {
                const res = await http.get(`/dmcdtseqvals/cdt/${typeId}`)

                const values = res.map(
                    ({cdtSeqVal, cdtSeqValName}) => ({
                        code: cdtSeqValName,
                        name: cdtSeqVal,
                    })
                )

                setValues(values)
            })()
        },

        [http, typeId]
    )

    return (
        <Popover
            {...props}
            content={<CDTValuesTable values={values} />}
            trigger="click"
        />
    )
}

const DisplayCDTValuesButton = ({typeId, ...props}) => {
    return (
        <CDTValuesPopover typeId={typeId}>
            <Button
                type="link"
                {...props}
            >值域</Button>
        </CDTValuesPopover>
    )
}

const PropertiesControllerDataType = ({disabled, readOnly, ...props}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        cdgDfUserCode,
        cdtIsSeq,
        dataTypeCode,
        dataTypeId,
        dataTypeName,
        dataTypeGrpCode,
    } = values

    const handleChange = ({
        code,
        id,
        isLen,
        isPrecision,
        isSeq,
        len,
        name,
        precision,
        defVal,
        dataType,
        type,
    }) => {
        update({
            cdtDataTypeCode: dataType,
            cdtIsSeq: isSeq,
            dataTypeGrpCode: type.toUpperCase(),
            dataTypeCode: code,
            dataTypeId: id,
            dataTypeName: name,
            defVal,
            dfLen: len || undefined,
            dfPrecision: precision || undefined, 
            isLen,
            isPrecision,
        })
    }

    return (
        <div {...props}>
            <div>
                <Label required={model.required('dataTypeCode')}>
                    {model.name('dataTypeCode')}
                </Label>

                {
                    '1' === cdtIsSeq &&
                        <DisplayCDTValuesButton typeId={dataTypeId} />
                }

                <span css={cssCode}>{cdgDfUserCode}</span>
            </div>

            <SelectDataType
                disabled={disabled || model.disabled('dataTypeCode')}
                readOnly={readOnly || gReadOnly}

                value={{
                    type: dataTypeGrpCode,
                    code: dataTypeCode,
                    name: dataTypeName,
                }}

                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerDataType
