import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableSelectBdterm = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()
    const {prjTypeCode} = map.data.project

    const columns = [
        'BCT' === prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '术语标题',
            dataIndex: 'termTitle',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bdterms"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableSelectBdterm
