import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Ls from '../components/PropertiesControllerLs.jsx'
import Model from './ModelLsi.mjs'
import Analyse from './PropertiesControllerAnalyse.jsx'

const PropertiesPaneLsi = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Ls />
                <Text prop="lsiNo" />
                <Text prop="lsiName" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}
        productSnSuffix={<Analyse />}
        {...props}
    />
)

export default PropertiesPaneLsi
