import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import NodeExtraText from './NodeExtraText.jsx'
import useBizNode from './useBizNode.mjs'

const cssNodeDesc = css({
})

export default function NodeDesc({node, ...props}) {
    const map = useMapContext()
    const BizNode = useBizNode()
    const showNodeDesc = map.useShowNodeDesc()

    if (! showNodeDesc) {
        return null
    }

    const {bizNodeType} = node.data
    const desc = BizNode[bizNodeType].getDesc(map, node)

    if (! desc) {
        return null
    }

    return (
        <NodeExtraText
            css={cssNodeDesc}
            node={node}
            text={`<${desc}>`}
            {...props}
        />
    )
}
