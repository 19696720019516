import Color from '../Color.mjs'
import useArchCatNode from '../useArchCatNode.jsx'
import meta from './metaCatSs.mjs'

export default (BizNode) => {
    const ArchCatNode = useArchCatNode(BizNode, meta)

    return {
        ...ArchCatNode,
        ...meta,

        canLinkType(map, node, type) {
            return /^(MID)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT|MARK|SS)$/.test(type)
        },

        getStyle(map, node) {
            return {
                ...ArchCatNode.getStyle.call(this, map, node),
                backgroundColor: Color.GREEN_YELLOW,
            }
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SS'],
                ['MID'],
            ]
        },
    }
}
