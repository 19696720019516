import useCategoryNode from '../useCategoryNode.jsx'

export default (BizNode, config) => {
    const CategoryNode = useCategoryNode(BizNode, config)

    return {
        ...CategoryNode,
        algNodeType: 'ALG',

        getDefaultChildData(map, node) {
            return BizNode[this.algNodeType].getInitData(map, node)
        },

        onAttached(map, node, child) {
            const {algType} = child.data

            if (algType) {
                BizNode[this.algNodeType].castFrom(map, child)
            }

            CategoryNode.onAttached.call(this, map, node, child)
        },
    }
}
