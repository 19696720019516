import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'taskNo',
    detailUrl: '/DesDetail',
    idProp: 'desId',
    mapProp: 'desMap',
    name: '模型交付单',
    textProp: 'desTitle',
    type: 'DES',
}
