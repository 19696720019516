import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaSsinsDeployedIn.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canLinkType(map, node, type) {
            return /^(PHYS|VR)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['PHYS_'],
                ['VR_'],
            ]
        },
    }
}
