import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    name: '用户操作',
    ownerIdProp: 'sfId',
    ownerTextProp: 'sfName',
    ownerType: 'UC',
    textProp: 'oprTitle',
    type: 'COPR',
}
