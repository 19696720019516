import {createContext, useContext} from 'react'

const bizNodeModel = (definition = {}) => {
    return class Model {
        constructor(data) {
            this.#data = data
        }

        get definition() {
            return definition
        }

        disabled(prop) {
            return definition[prop]?.disabled?.(this.#data)
        }

        hidden(prop) {
            return definition[prop]?.hidden?.(this.#data)
        }

        name(prop) {
            return definition[prop]?.name?.(this.#data)
        }

        readOnly(prop) {
            return definition[prop]?.readOnly?.(this.#data)
        }

        required(prop) {
            return definition[prop]?.required?.(this.#data)
        }

        validate(prop) {
            const validateProp = (prop) => {
                if (
                    this.required(prop) &&
                    ! this.hidden(prop) &&
                    ! this.disabled(prop) &&
                    ! this.readOnly(prop) &&
                    ! definition[prop]?.ignoreRequiredInValidation &&
                    ! this.#data[prop] &&
                    0 !== this.#data[prop]
                ) {
                    return `${this.name(prop)}不能为空`
                }
                else {
                    return definition[prop]?.validate?.(this.#data)
                }
            }

            if (prop) {
                return validateProp(prop)
            }
            else {
                for (const prop of Object.keys(definition)) {
                    const errMsg = validateProp(prop)

                    if (errMsg) {
                        return errMsg
                    }
                }
            }
        }

        #data
    }
}

export const ModelContext = createContext(bizNodeModel())
export const useModel = () => useContext(ModelContext)
export default bizNodeModel
