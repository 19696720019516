import Color from '../Color.mjs'
import useCommonNode from '../useCommonNode.jsx'
import IconLink from './IconLink.jsx'
import PropertiesPane from './PropertiesPaneUrl.jsx'
import meta from './metaUrl.mjs'

export default (BizNode) => {
    const CommonNode = useCommonNode(BizNode, meta)

    return {
        ...CommonNode,

        getIcons(map, node) {
            return [<IconLink key="type"/>]
        },

        getStyle(map, node) {
            return {
                ...CommonNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
                Shape: 'SingleBreakangle',
            }
        },

        menuInsertCommon(map, nodes) {
            return this._menuInsertCommon(map, nodes, {rank: 60})
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onDoubleClick(map, node, event) {
            const {address} = node.data

            if (address) {
                event.preventDefault()
                window.open(address)
            }
        },
    }
}
