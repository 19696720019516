import useBaseNode from './useBaseNode.jsx'
import useDesignNode from './useDesignNode.jsx'

/**
 * 专属节点类型的基类
 *
 * 专属节点可通过「插入概念节点」的方式插入地图
 */
export default (BizNode, config) => {
    const BaseNode = useBaseNode(BizNode, config)
    const DesignNode = useDesignNode(BizNode, config)

    return {
        ...BaseNode,

        menuInsertConcept(map, nodes) {
            return DesignNode._menuInsertConcept.call(this, map, nodes)
        },
    }
}
