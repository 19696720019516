import Color from '../Color.mjs'
import {deleteChildren} from '../../scripts/map.mjs'
import useComponentNode from '../useComponentNode.jsx'
import * as defaultMeta from './metaParamNode.mjs'
import Model from './ModelParam.mjs'
import PropertiesPane from './PropertiesPaneParam.jsx'

export default (BizNode, meta) => {
    const ComponentNode = useComponentNode(
        BizNode,
        {...defaultMeta, ...meta},
        Model
    )

    return {
        ...ComponentNode,

        attrNodes: {
            bottom: ['ATTR_PARAM_REMARK'],
        },

        canLinkType(map, node, type) {
            return /^(DTO)$/.test(type)
        },

        canWriteTree(map, node) {
            return false
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.GREEN_YELLOW,
            })
        },

        getTextPrefix(map, node) {
            const {
                dataTypeCode,
                dataTypeGrpCode,
                dfDtoUserCode,
            } = node.data

            if (dataTypeGrpCode) {
                if (
                    /^(Basic|Other)$/.test(dataTypeGrpCode) &&
                    dataTypeCode
                ) {
                    return `${dataTypeCode} `
                }
                else if (
                    'DTO' === dataTypeGrpCode &&
                    dfDtoUserCode
                ) {
                    return `${dfDtoUserCode} `
                }
                else if (dataTypeCode || dfDtoUserCode) {
                    const type = dataTypeCode || dfDtoUserCode
                    return `${dataTypeGrpCode}<${type}> `
                }
            }

            return ComponentNode.getTextPrefix.call(this, map, node)
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onPull(map, node) {
            ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        async onSetData(map, node, oldData) {
            await ComponentNode.onSetData.call(this, map, node, oldData)
            const {dfDtoId} = node.data

            if (dfDtoId === oldData.dfDtoId) {
                return
            }

            deleteChildren(map, node)

            if (dfDtoId) {
                const data = await BizNode.DTO.read(map, {pkid: dfDtoId})
                const dto = map.importTree({data})
                map.appendChild(node, dto)
            }
        },
    }
}
