import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const map = useMapContext()
    const permission = map.usePermission()

    if (
        ! isProductMap(map) ||
        ! permission.has('write')
    ) {
        return null
    }

    const {canPushProduct, pushAndSave} = map.commands
    const nodes = canPushProduct([map.root])

    if (0 === nodes.length) {
        return null
    }

    return {
        disabled: 0 === nodes.length,
        key: 'push-product',
        label: '保存且更新',
        suffix: 'Ctrl+S',
        onClick: pushAndSave,
    }
}
