import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (args) => http.post('/sharemap', args),

        read: async ({id}) => {
            const {dataMap} = await http.get(`/sharemap/${id}`)
            return JSON.parse(dataMap)
        },
    }
}
