const insertNode = (map, BizNode, nodes, insertNode) => {
    const nodesToSelect = new Set()

    for (const node of nodes) {
        const {bizNodeType} = node.data
        const data = BizNode[bizNodeType].getDefaultChildData(map, node)

        if (data) {
            const child = map.importTree({data})
            insertNode(node, child)
            const {bizNodeType} = child.data
            BizNode[bizNodeType].onCreate(map, child)

            if (! child.isDeleted) {
                map.logger.info('已插入节点', [node])
                nodesToSelect.add(child)
            }
        }
    }

    map.selectNodes(nodesToSelect)
}

export const canInsertChild = BizNode => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canInsertChild(node) &&
            BizNode[bizNodeType].canWriteTree(this, node)
        )
    }
}

export const appendChild = BizNode => () => {
    return function (nodes) {
        return insertNode(this, BizNode, nodes, this.appendChild.bind(this))
    }
}

export const prependChild = BizNode => () => {
    return function (nodes) {
        return insertNode(this, BizNode, nodes, this.prependChild.bind(this))
    }
}
