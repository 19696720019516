export const canCopyNodeId = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data
        return BizNode[bizNodeType].isProduct
    }
}

export const copyNodeId = BizNode => () => {
    return async function (nodes) {
        if (0 === nodes.length) {
            return
        }

        const text = [...nodes]
            .map(node => {
                const {bizNodeType, dpSn, rev, sVer} = node.data
                const text = BizNode[bizNodeType].getTextRaw(this, node)
                return [dpSn, `${sVer}.${rev}`, text].join('/')
            })
            .join('\n')

        await navigator.clipboard.writeText(text)
        this.logger.info('节点标识已拷贝到剪贴板', nodes)
    }
}
