import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableSelectBdrule = ({fetch, fetchArgs, query, ...props}) => {
    const map = useMapContext()
    const {prjTypeCode} = map.data.project

    const columns = [
        'BCT' === prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务对象/服务名称',
            component: <Table.ViewText />,
            getValue: ({bdName, svrName}) => bdName ?? svrName
        },

        {
            title: '业务对象/服务代码',
            component: <Table.ViewText />,
            getValue: ({bdNo, svrNo}) => bdNo ?? svrNo
        },

        {
            title: '处理逻辑标题',
            dataIndex: 'ruleTitle',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bdlogic_1s"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableSelectBdrule
