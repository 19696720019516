import {cloneElement} from 'react'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from './Properties.mjs'
import Label from './PropertiesControllerLabel.jsx'

const PropertiesControllerInput = ({
    children,
    disabled,
    hidden,
    labelSuffix,
    prop,
    readOnly,
    required,
    title,
    value,
    ...props
}) => {
    const [values, update] = useProperties()
    const Model = useModel()
    const model = new Model(values)

    if (hidden ?? model?.hidden(prop)) {
        return null
    }

    const onChange = (value) => {
        const oldValue = values[prop]

        if (undefined === oldValue || null === oldValue) {
            if (value || 0 === value) {
                update({[prop]: value})
            }
        }
        else {
            if (oldValue !== value) {
                update({[prop]: value})
            }
        }
    }

    const onKeyDown = e => e.stopPropagation()

    return (
        <div {...props}>
            <Label
                required={required ?? model?.required(prop)}
                suffix={labelSuffix}
            >
                {title ?? model?.name(prop)}
            </Label>

            {
                cloneElement(children, {
                    disabled: disabled || model.disabled(prop),
                    readOnly: readOnly || model.readOnly(prop),
                    value: value ?? values[prop],
                    onChange,
                    onKeyDown,
                })
            }
        </div>
    )
}

export default PropertiesControllerInput
