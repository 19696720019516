import {useEffect, useRef} from 'react'
import QRious from 'qrious'
import {css} from '@emotion/react'
import {EyeOutlined, LinkOutlined} from '@ant-design/icons'
import InputText from '@/components/Form/InputText.jsx'
import {loadImage} from '@/script/image.mjs'
import runAsync from '@/script/runAsync.mjs'
import {Alert, Prompt, useOpenModal} from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import useApiShare from '../../useApiShare.mjs'
import {useMakeSnapshot} from './snapshot.mjs'
import POSTER from './poster.png'

const POSTER_WIDTH = 750
const POSTER_HEIGHT = 1334

const cssListItem = css({
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    color: '#8590a6',
    padding: '8px 0',

    '&:hover': {
        backgroundColor: '#f6f6f6',
    },

    'a': {
        flexGrow: 1,
        color: 'inherit',
        paddingLeft: 16,
    },
})

const cssPoster = css({
    width: 300,
    marginTop: 20,
})

const Copy = ({href, ...props}) => {
    const handleClick = async () => {
        await runAsync(
            () => navigator.clipboard.writeText(href),

            {
                action: '复制链接',
                loading: {show: false},
                success: {show: true},
            }
        )
    }

    return (
        <div
            css={cssListItem}
            {...props}
        >
            <LinkOutlined />
            <a onClick={handleClick}>复制链接</a>
        </div>
    )
}

const Poster = ({href, mapName, ...props}) => {
    const refEl = useRef()

    useEffect(
        () => {
            (async () => {
                const ctx = refEl.current.getContext('2d')
                const poster = await loadImage(POSTER)
                ctx.drawImage(poster, 0, 0)

                var qrSrc = new QRious({
                    level: 'H',
                    size: 400,
                    value: href,
                }).toDataURL()

                const qr = await loadImage(qrSrc)
                ctx.drawImage(qr, (POSTER_WIDTH - qr.width) / 2, 380)

                ctx.font = '28px 黑体'
                ctx.fillStyle = '#666'
                ctx.textAlign = 'center'
                ctx.fillText(mapName, POSTER_WIDTH / 2, 360)
            })()
        },

        [href, mapName]
    )

    return (
        <canvas
            ref={refEl}
            css={cssPoster}
            width={POSTER_WIDTH}
            height={POSTER_HEIGHT}
            {...props}
        >
        </canvas>
    )
}

const Preview = ({href, ...props}) => {
    return (
        <div
            css={cssListItem}
            {...props}
        >
            <EyeOutlined />

            <a
                href={href}
                rel="noreferrer"
                target="_blank"
            >
                预览
            </a>
        </div>
    )
}

const ModalShare = ({href, mapName, ...props}) => {
    return (
        <Alert
            title="分享"
            width="330px"
            {...props}
        >
            <Copy href={href} />
            <Preview href={href} />
            <Poster href={href} mapName={mapName} />
        </Alert>
    )
}

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const apiShare = useApiShare()
    const makeSnapshot = useMakeSnapshot()

    const onClick = async () => {
        const mapName = await openModal(
            <Prompt
                title="请输入地图名称"
                value={map.data.mapName} 
            >
                <InputText />
            </Prompt>
        )

        if (! mapName) {
            return
        }

        const args = makeSnapshot({mapName})

        const {pkid} = await runAsync(
            () => apiShare.create(args),

            {
                action: '创建分享',
                success: {show: true},
            }
        )

        const url = new URL(`/share/${pkid}`, location.origin)

        openModal(
            <ModalShare
                href={url.href}
                mapName={mapName}
            />
        )
    }

    return {
        key: 'share-map',
        label: '分享',
        onClick,
    }
}
