import MenuButton from '../../ToolBar/MenuButton.jsx'
import {useDiffMapContext} from './DiffMap.jsx'

export default function MenuButtonPrevDiff(props) {
    const {differenceCount, prevDiff} = useDiffMapContext()

    return (
        <MenuButton
            disabled={0 === differenceCount}
            onClick={prevDiff}
            {...props}
        >
            上一不同
        </MenuButton>
    )
}
