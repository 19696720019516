import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Analyse from '../BDALG/PropertiesControllerAnalyse.jsx'
import Model from './ModelBdsummary.mjs'
import StoryType from './StoryType.jsx'

const PropertiesPaneSummary = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />

                <Select
                    options={StoryType}
                    prop="storyType"
                />
            </>
        }

        Model={Model}
        productSnSuffix={<Analyse />}
        {...props}
    />
)

export default PropertiesPaneSummary
