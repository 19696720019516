import React, { useEffect, useState } from 'react';
import { Input, Tree, Spin, Empty } from 'antd';
import Modal from '@/components/Modal/Modal';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';

// 分组函数
const groupBy = (array, key) => {
    return array.reduce((result, currentItem) => {
        const groupKey = currentItem[key];
        if (!result[groupKey]) {
            result[groupKey] = [];
        }

        result[groupKey].push(currentItem);
        return result;
    }, {});
}

// 构建：数据域树
const buildTree = (data) => {
    const tree = [];
    const map = new Map();

    // 遍历：数据域树
    data.map(item => {
        const node = {
            key: item.pkid,
            title: item.dmName,
            obj: { ...item },
            children: [],
            dbCode: item.dbCode,
            dbName: item.dbName,
        }
        map.set(item.dmCode, node);
        return node;
    }).forEach(node => {
        const pDmCode = node.obj.pDmCode;
        if (pDmCode && pDmCode != '') {
            const pNode = map.get(pDmCode);
            if (pNode) {
                pNode.children.push(node);
            }
        } else {
            tree.push(node);
        }
    });

    // 分组
    const result = groupBy(tree, 'dbCode');
    return Object.entries(result).map(([key, value]) => {
        return {
            key: key,
            title: value[0].dbName,
            children: value
        };
    });
}

const Dialog = ({ params, value, onOk, multiple = false, ...props }) => {
    const http = useHTTP();
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, []);

    const doSearch = async () => {
        try {
            setLoading(true);
            const prjId = params.prjId;
            const sVer = params.sVer;
            const dbCode = params.dbCode;
            const data = await runAsync(
                async () => {
                    return await http.get(`/archdms`, { prjId, sVer, dbCode });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const tree = buildTree(data);
            setData(tree);
            setFilterData(tree);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const handleOk = () => {
        const dms = [];
        for (const node of selectedNodes) {
            const dm = node.obj;
            if (dm) {
                dms.push({
                    dbCode: dm.dbCode, dbName: dm.dbName, dbUserCode: dm.dbUserCode,
                    dmId: dm.pkid, dmCode: dm.dmCode, dmName: dm.dmName, dmNamePath: dm.dmNamePath,
                });
            }
        }

        if (multiple) {
            onOk(dms);
        } else {
            if (dms.length > 0) {
                onOk(dms[0]);
            }
        }
    }

    const handleSearch = (keyword) => {
        setFilterData(searchKeyword(keyword));
    }

    const searchKeyword = (keyword) => {
        const iterate = (item) => {
            const { title, children } = item;
            const isMatch = () => (title && title.toLowerCase().indexOf(keyword.toLowerCase()) > -1);

            if (children) {
                const _children = children.map(iterate).filter(v => v);
                if (_children.length > 0) {
                    return { ...item, children: _children };
                }
                return isMatch() ? { ...item } : null;
            } else {
                return isMatch() ? { ...item } : null;
            }
        }

        return data.map(iterate).filter(v => v);
    }

    const multipleProps = {
        selectable: false,
        checkable: true,
        checkedKeys: selectedKeys,
        onCheck: (selectedKeys, e) => {
            setSelectedNodes(e.checkedNodes);
            setSelectedKeys(selectedKeys);
        }
    }

    const singleProps = {
        selectable: true,
        checkable: false,
        selectedKeys: selectedKeys,
        onSelect: (selectedKeys, e) => {
            setSelectedNodes(e.selectedNodes);
            setSelectedKeys(selectedKeys);
        }
    }

    return (
        <Modal
            title={"选择数据域"}
            width={800}
            style={{ top: 20 }}
            bodyStyle={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '80vh', minHeight: 320, background: '#fff', padding: 12 }}
            onOk={handleOk}
            {...props}
        >
            <div style={{ marginBottom: 12, }}>
                <Input.Search
                    enterButton="查询"
                    allowClear
                    size="small"
                    onSearch={handleSearch}
                    placeholder='可输入数据域名称进行查询' />
            </div>

            {
                loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1' }}><Spin /></div>
                    :
                    filterData.length > 0 ?
                        <div style={{ overflow: 'hidden', border: '1px solid #f0f0f0', padding: 8 }}>
                            <Tree
                                height={430}
                                defaultExpandAll
                                showLine={{ showLeafIcon: false }}
                                showIcon={true}
                                treeData={filterData}
                                multiple={multiple}
                                {...(multiple ? multipleProps : singleProps)} />
                        </div>
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: '1', background: '#fff' }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </div>
            }
        </Modal>
    );
}

export default Dialog;