import {css} from '@emotion/react'
import Checkbox from '@/components/Form/Checkbox.jsx'
import Input from './PropertiesControllerInput.jsx'

const cssCheckbox = css({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    gap: 8,
})

const PropertiesControllerCheck = ({
    className,
    style,
    disabled,
    hidden,
    labelSuffix,
    prop,
    required,
    title,
    value,
    ...props
}) => {
    return (
        <Input
            css={cssCheckbox}
            className={className}
            style={style}
            disabled={disabled}
            hidden={hidden}
            labelSuffix={labelSuffix}
            prop={prop}
            required={required}
            title={title}
            value={value}
        >
            <Checkbox
                falseValue="0"
                trueValue="1"
                {...props}
            />
        </Input>
    )
}

export default PropertiesControllerCheck
