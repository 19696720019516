import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const map = useMapContext()
    map.useMetaData()

    if (isProductMap(map)) {
        return null
    }

    const {canReviseMap, reviseMap} = map.commands

    return {
        disabled: ! canReviseMap(),
        key: 'revise-map',
        label: '修订',
        onClick: reviseMap,
    }
}
