import useParamNode from '../PARAM/useParamNode.jsx'
import meta from './metaRep.mjs'

export default (BizNode) => {
    const ParamNode = useParamNode(BizNode, meta)

    return {
        ...ParamNode,

        onPush(map, node, type, data) {
            if ('FC' === type) {
                data.repList.push(
                    this.getPushData(map, node)
                )
            }
        },
    }
}
