import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../MenuButton.jsx'

export default function MenuButtonPullProduct(props) {
    const map = useMapContext()
    map.usePermission()

    if (! /^_(DT)_MAP$/.test(map.data.mapTypeCode)) {
        return null
    }

    const {canPullProduct, pullProduct} = map.commands
    const nodes = canPullProduct([map.root])

    if (0 === nodes.length) {
        return null
    }

    const handleClick = () => pullProduct([map.root])

    return (
        <MenuButton
            onClick={handleClick}
            {...props}
        >
            刷新制品
        </MenuButton>
    )
}
