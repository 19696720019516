import {TreeDoc, TreeDocNode} from '@/components/TreeDoc/index.mjs'

export class MindMapNode extends TreeDocNode {
    get children() {
        return this.doc.children(this)
    }
}

export class MindMapDoc extends TreeDoc {
    static TreeNode = MindMapNode
}
