import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    algType: 'DATAFLOW',
    name: '业务场景',
    ownerIdProp: 'bfId',
    ownerTextProp: 'bfName',
    ownerType: 'BF',
    type: 'BFDATAFLOW',
}
