import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        //publish: ({pkid}) => http.post('/releaseDepPs', {ids: [pkid]}),
        read: ({pkid}) => http.get('/oneDepPs', {pkid}),
        readList: (args) => http.get('/listDepPsByMap', args),
        //revise: ({pkid}) => http.post('/reviseDepPs', [pkid]),
    }
}
