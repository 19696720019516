const insertNode = (map, BizNode, nodes, insertNode) => {
    const nodesToSelect = new Set()

    for (const node of nodes) {
        const {bizNodeType} = node.data
        const data = BizNode[bizNodeType].getInitData(map, node)

        if (data) {
            const sibling = map.importTree({data})
            insertNode(node, sibling)
            BizNode[bizNodeType].onCreate(map, sibling)

            if (! sibling.isDeleted) {
                map.logger.info('已插入兄弟节点', [node])
                nodesToSelect.add(sibling)
            }
        }
    }

    map.selectNodes(nodesToSelect)
}

export const canInsertSibling = BizNode => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canInsertSibling(node) &&
            BizNode[bizNodeType].canDuplicate(this, node)
        )
    }
}

export const insertSiblingAfter = BizNode => () => {
    return function (nodes) {
        return insertNode(
            this,
            BizNode,
            nodes,
            this.insertSiblingAfter.bind(this),
        )
    }
}

export const insertSiblingBefore = BizNode => () => {
    return function (nodes) {
        return insertNode(
            this,
            BizNode,
            nodes,
            this.insertSiblingBefore.bind(this),
        )
    }
}
