import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemSfName = (props) => {
    return (
        <Form.Item
            label="功能名称"
            name="sfName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemSfNo = (props) => {
    return (
        <Form.Item
            label="功能代码"
            name="sfNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const QueryFormSelectSf = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemSfNo />
            </Col>

            <Col span={6}>
                <FormItemSfName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectSf
