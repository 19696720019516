import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ButtonSaveProduct from '../components/PropertiesPaneButtonSaveProduct.jsx'
import DbmsType from './DbmsType.mjs'
import Model from './ModelDb.mjs'

const PropertiesPaneDb = (props) => (
    <ProductPropertiesPane
        buttons={<ButtonSaveProduct />}

        controllers={
            <>
                <Text prop="dbUserCode" />
                <Text prop="dbName" />

                <Select
                    prop="dbmsCode"
                    options={DbmsType}
                />

                <LongText prop="dbDesc" />
                <LongText prop="softwareDesc" />
                <LongText prop="hardwareDesc" />
                <LongText prop="networkDesc" />
                <Text prop="deployIp" />
                <LongText prop="deployDesc" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneDb
