import superMeta from '../metaArchNode.mjs'

export default {
    ...superMeta,
    archUrl: '/ProjectUiMap',
    detailUrl: '/UaDetail',
    idProp: 'uaId',
    mapProp: 'uaMap',
    name: '界面地图',
    type: 'UA',
}
