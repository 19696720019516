import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelPhys.mjs'

const PropertiesPanePhys = (props) => (
    <ProductPropertiesPane
        controllers={
            <>
                <Text prop="psName" />
                <LongText prop="psDesc" />
                <LongText prop="psMemo" />

                <Select
                    options={[
                        ['NORMAL', '正常'],
                        ['DAMAGED', '损坏'],
                        ['REPAIR', '修理'],
                        ['MAINTAIN', '保养'],
                        ['UNKNOWN', '未知'],
                    ]}

                    prop="psStatus"
                />

                <Text prop="psBu" />
                <Text prop="psDm" />
                <Text prop="psOm" />
                <Text prop="psEnv" />
                <Text prop="psHostname" />
                <Text prop="psIntranetIp" />
                <Text prop="psInternetIp" />
                <LongText prop="psOs" />
                <Text prop="psKernelVersion" />
                <LongText prop="psOpenPort" />
                <Text prop="psSerialNumber" />
                <LongText prop="psModel" />
                <Text prop="psCpu" />
                <Text prop="psMemory" />
                <Text prop="psHardDisk" />
                <Text prop="psMac" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPanePhys
