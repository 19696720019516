import {fromTree} from '../../compatibility.mjs'
import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaFc.mjs'
import Model from './ModelFc.mjs'
import PropertiesPane from './PropertiesPaneFc.jsx'
import QueryForm from './QueryFormSelectFc.jsx'
import Table from './TableSelectFc.jsx'
import useApi from './useApiFc.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(FC_(DS|JOB|PARAM))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {
                fms = [],
                ss = {},
                vms = [],
                ...query
            }) => ({
                ...query,
                fmIds: fms.map(({fmId}) => fmId),
                ssId: ss.ssId,
                vmIds: vms.map(({vmId}) => vmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getInitData(map, parent) {
            const d = {
                ...ProductNode.getInitData.call(this, map, parent),
                isApi: '1',
                isSi: '0',
            }

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    ssCode,
                    ssId,
                    ssName,
                    vmCode,
                    vmId,
                    vmName,
                } = ancestor.data

                if ('CAT_ROOT_VM' === bizNodeType) {
                    Object.assign(d, {
                        ssCode,
                        ssId,
                        ssName,
                        vmCode,
                        vmId,
                        vmName,
                    })

                    break
                }
            }

            return d
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.GREEN_YELLOW}
                        letters="F"
                        textColor="#666"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                fmFjList: [],
                repList: [],
                reqList: [],
            })
        },

        getStyle(map, node) {
            const {isApi} = node.data

            const shape = '1' === isApi ?
                'HorizontalHexagon': 'EllipseRectangle'

            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.GREEN_YELLOW,
                }),

                shape,
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['repList', 'reqList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        onPull(map, node) {
            ProductNode.onPull.call(this, map, node)
            const {jobMap, ...data} = node.data

            if (! jobMap) {
                return
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data

                if ('FC_JOB' === bizNodeType) {
                    const mapData = JSON.parse(jobMap)

                    const grandchildTrees = (() => {
                        if (mapData.children) {
                            const {children} = fromTree(BizNode, [], mapData)
                            return children
                        }
                        else {
                            return mapData.map(
                                tree => fromTree(BizNode, [], tree)
                            )
                        }
                    })()

                    for (const tree of grandchildTrees) {
                        const grandchild = map.importTree(tree)
                        map.appendChild(child, grandchild)
                    }

                    break
                }
            }

            node.data = data
        },

        onSetData(map, node, oldData) {
            const {ssCode} = node.data

            if (oldData.ssCode && ssCode !== oldData.ssCode) {
                node.data = {
                    ...node.data,
                    vmCode: '',
                    vmId: '',
                    vmName: ''
                }
            }
        },

        async readMap(map, args) {
            const mapData = await ProductNode.readMap.call(this, map, args)
            delete mapData.root.data.jobMap
            return mapData
        },
    }
}
