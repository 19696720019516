import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/DepPsDetail',
    idProp: 'psId',
    mapProp: 'map',
    name: '物理机',
    textProp: 'psName',
    type: 'PHYS',
}
