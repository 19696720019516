import {useState} from 'react'
import {SearchOutlined} from '@ant-design/icons'
import InputText from '@/components/Form/InputText.jsx'
import SelectDtoDialog from '@/pages/MainPage/dialogs/SelectDtoDialog'
import {useMapContext} from '@/components/MindMap/index.mjs'

const defaultValue = {}

const SelectDto = ({
    className,
    style,
    disabled,
    readOnly,
    value = defaultValue,
    onChange,
    ...props
}) => {
    const map = useMapContext()
    const [visible, setVisible] = useState(false)

    const handleClickInput = () => {
        if (! readOnly) {
            setVisible(true)
        }
    }

    const icon = readOnly ?
        null
        :
        <SearchOutlined
            disabled={disabled}
            onClick={handleClickInput}
        />

    const {dataTypeCode, dtoName, dtoUserCode} = value

    const text = (() => {
        if (dataTypeCode) {
            return dataTypeCode
        }
        else if (dtoName && dtoUserCode) {
            return `${dtoName}/${dtoUserCode}`
        }
        else {
            return ''
        }
    })()

    return (
        <div
            className={className}
            style={style}
            onKeyDown={e => e.stopPropagation()}
        >
            <InputText
                addonAfter={icon}
                disabled={disabled}
                readOnly
                value={text}
                onClick={handleClickInput}
            />

            <SelectDtoDialog
                style={{display: 'block'}}
                prjId={map.data.prjId}
                setVisible={setVisible}
                sVer={map.data.sVer}
                visible={visible}
                onCall={onChange}
                {...props}
            />
        </div>
    )
}

export default SelectDto
