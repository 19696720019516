import {css} from '@emotion/react'
import Menu from './Menu.jsx'

const cssToolBar = css({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#fff',
    borderBottom: '1px solid #e8e8e8',
    padding: 16,
})

const ToolBar = (props) => {
    return (
        <div
            css={cssToolBar}
            {...props}
        >
            <Menu />
        </div>
    )
}

export default ToolBar
