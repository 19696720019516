export const getNodeLineStyle = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data

        const {
            leadingLineColor,
            leadingLineWidth,
            trailingLineColor,
            trailingLineWidth,
        } = {
            ...BizNode[bizNodeType].getStyle(this, node),
            ...node.data.style,
        }

        return {
            leadingLineColor,
            leadingLineWidth,
            trailingLineColor,
            trailingLineWidth,
        }
    }
}
