import TagSelect from '@/components/Form/TagSelect.jsx'
import Input from './PropertiesControllerInput.jsx'

const PropertiesControllerTagSelect = ({
    className,
    style,
    disabled,
    hidden,
    labelSuffix,
    prop,
    required,
    title,
    value,
    ...props
}) => {
    return (
        <Input
            className={className}
            style={style}
            disabled={disabled}
            hidden={hidden}
            labelSuffix={labelSuffix}
            prop={prop}
            required={required}
            title={title}
            value={value}
        >
            <TagSelect {...props} />
        </Input>
    )
}

export default PropertiesControllerTagSelect
