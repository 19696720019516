import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'lsiNo',
    detailUrl: '/LsiDetail',
    idProp: 'lsiId',
    mapProp: 'lsiMap',
    name: '外联接口',
    textProp: 'lsiName',
    type: 'LSI',
}
