import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDb.mjs'
import Model from './ModelDb.mjs'
import PropertiesPane from './PropertiesPaneDb.jsx'
import QueryForm from './QueryFormSelectDb.jsx'
import Table from './TableSelectDb.jsx'
import useApi from './useApiDb.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(DB_(DA|DBINS))$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="D"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                archDmList: [],
                archDbInss: [],
            })
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.CYAN,
            })
        },

        async grow(map, node, growedNodes) {
            await ProductNode.grow.call(this, map, node, growedNodes)

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if (BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('DEP_DB' === bizNodeType) {
                    for (const child of [...map.children(node)]) {
                        const {bizNodeType} = child.data

                        if ('DB_DBINS' === bizNodeType) {
                            map.deleteNode(child)
                        }
                        else {
                            map.deleteTree(child)
                        }
                    }
                }
                else {
                    break
                }
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'archDmList',
                'archDbInss',
            ])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
