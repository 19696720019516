import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectLs from '../LS/SelectLs.jsx'

const PropertiesControllerLs = ({
    codeProp = 'lsCode',
    disabled,
    idProp = 'lsId',
    nameProp = 'lsName',
    readOnly,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        //[codeProp]: lsCode,
        [idProp]: lsId,
        //[nameProp]: lsName,
    } = values

    const handleChange = ({lsCode, lsId, lsName}) => {
        update({
            [codeProp]: lsCode,
            [idProp]: lsId,
            [nameProp]: lsName,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectLs
                disabled={disabled || model.disabled('codeProp')}
                readOnly={readOnly || gReadOnly}
                value={lsId}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerLs
