import useSWR from 'swr'
import Select from '@/components/Form/Select.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import useApiLs from './useApiLs.mjs'

export default function SelectLs({onChange, ...props}) {
    const map = useMapContext()
    const apiLs = useApiLs()
    const {prjId, sVer} = map.data

    const {data = []} = useSWR(
        '/lss',
        () => apiLs.readList({prjId, sVer, lsStsCodes: ['RLS', 'REVISE']})
    )

    const options = data.map(e => [e.pkid, e])
    const dict = new Map(options)

    const handleChange = id => {
        const ls = dict.get(id)
        onChange(ls)
    }

    return (
        <Select
            options={options}
            label={e => e.lsName}
            onChange={handleChange}
            {...props}
        />
    )
}
