import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        push: (data) => http.post('/prjkms/batchmodify', data),
        read: ({pkid}) => http.get(`/prjkms/${pkid}`),
        updateMap: (data) => http.put(`/prjkms/${data.pkid}/map`, data),
    }
}
