import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'

const cssText = css({
    lineHeight: '1.2',
})

const NodeExtraText = ({node, text, ...props}) => {
    const map = useMapContext()

    if (! text) {
        return null
    }

    const style = map.behaviours.getNodeTextStyle(node)

    return (
        <div
            css={cssText}
            style={style}
            {...props}
        >
            {text}
        </div>
    )
}

export default NodeExtraText
