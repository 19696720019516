import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ssUserCode',
    detailUrl: '/SsDetail',
    idProp: 'ssId',
    mapProp: 'ssMap',
    name: '子系统',
    textProp: 'ssName',
    type: 'SS',
}
