import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/bmbfs', data),

        publish: (data) => http.put(
            '/bmbfs/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/bmbfs/${pkid}/dps`),
        push: (data) => http.post('/bmbfs/batchmodify', data),
        read: ({pkid}) => http.get(`/bmbfs/${pkid}`),
        readList: (args) => http.get('/bmbfs', args),

        revise: ({pkid}) => http.put(
            '/bmbfs/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/bmbfs/${data.pkid}`, data),
        updateMap: (data) => http.put(`/bmbfs/${data.pkid}/map`, data),
    }
}
