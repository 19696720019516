import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import StoryType from '../BDSUMMARY/StoryType.jsx'
import Analyse from './PropertiesControllerAnalyse.jsx'
import Model from './ModelSvrsummary.mjs'

const PropertiesPaneSummary = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />

                <Select
                    options={StoryType}
                    prop="storyType"
                />
            </>
        }

        Model={Model}
        productSnSuffix={<Analyse />}
        {...props}
    />
)

export default PropertiesPaneSummary
