import buildUrl from '@/script/buildUrl.mjs'
import useProductNode from './useProductNode.jsx'

export default (BizNode, {archUrl, ...config}, api) => {
    const ProductNode = useProductNode(BizNode, config, null, api)

    return {
        ...ProductNode,
        archUrl,

        canDeleteTree() {
            return false
        },

        getDesc(map, node) {
            return ''
        },

        async grow(map, node, growedNodes) {
            await ProductNode.grow.call(this, map, node, growedNodes)
            const {data, data: {bizNodeType}, parent} = node

            if (parent) {
                const {name, textProp} = BizNode[bizNodeType]
                node.data = {...data, [textProp]: name}
            }
        },

        menuInsertConcept() {
            return null
        },

        async onDoubleClick(map, node, event) {
            event.preventDefault()
            const {pkid: prjId, prjNo, sVer} = map.data.project
            const url = buildUrl(this.archUrl, {prjId, prjNo, sVer})
            window.open(url)
        },
    }
}
