import {createContext, useContext, useEffect, useRef} from 'react'
import {css} from '@emotion/react'
import BaseMap from '../BaseMap.jsx'
import ToolBar from './ToolBar.jsx'
import useDiffMap from './useDiffMap.mjs'
import useMapData from './useMapData.mjs'

const cssDiffMap = css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto 1fr',
    overflow: 'hidden',
})

const cssToolBar = css({
    gridColumn: 'span 2',
})

const DiffMapContext = createContext()

const DiffMap = ({differences, left, right, q, ...props}) => {
    const refLeftMap = useRef()
    const refRightMap = useRef()
    const components = {ToolBar: () => null}

    const diffMap = useDiffMap(
        refLeftMap,
        refRightMap,
        differences,
        {selectFirstDifference: undefined === q},
    )

    return (
        <div
            css={cssDiffMap}
            {...props}
        >
            <DiffMapContext.Provider value={diffMap}>
                <ToolBar css={cssToolBar} />

                <BaseMap
                    ref={refLeftMap}
                    components={components}
                    initData={left}
                    q={q}
                />

                <BaseMap
                    ref={refRightMap}
                    components={components}
                    initData={right}
                    q={q}
                />
            </DiffMapContext.Provider>
        </div>
    )
}

export default function DiffMapWrapper({
    id,
    leftRev,
    rightRev,
    type,
    onLoad,
    ...props
}) {
    const [left, right, differences] = useMapData(id, type, leftRev, rightRev)

    useEffect(
        () => {
            if (left && right && differences) {
                onLoad?.({differences, left, right})
            }
        },

        [left, right, differences]
    )

    if (! (left && right && differences)) {
        return null
    }

    return (
        <DiffMap
            differences={differences}
            left={left}
            right={right}
            {...props}
        />
    )
}

export const useDiffMapContext = () => useContext(DiffMapContext)
