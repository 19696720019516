export const canUpdateNodeStyle = () => defaultActions => {
    return function (node) {
        if (! defaultActions.canUpdateNodeStyle(node)) {
            return false
        }

        const n = this.nodeProxy(node)
        return n.canWriteStyle()
    }
}

export const updateNodeData = BizNode => defaultActions => {
    return function (nodes, transform) {
        return defaultActions.updateNodeData(
            nodes,

            (node) => {
                const {bizNodeType} = node.data

                return BizNode[bizNodeType].mapUpdateNodeData(
                    this,
                    node,
                    transform(node),
                )
            }
        )
    }
}
