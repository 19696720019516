import {replaceWithTree} from '../../scripts/map.mjs'

export const canPullProduct = BizNode => () => {
    return function (node) {
        const {bizNodeType, pkid} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能刷新', [node])
            return false
        }

        const bn = BizNode[bizNodeType]

        if (! bn.pull) {
            this.logger.error(`${bn.name}不支持刷新`, [node])
            return false
        }

        return bn.canWriteNode(this, node)
    }
}

export const pullProduct = BizNode => () => {
    return function (nodes) {
        return Promise.allSettled(nodes.map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data
                    const tree = await BizNode[bizNodeType].pull(this, node)
                    replaceWithTree(this, node, tree)
                    BizNode[bizNodeType].onPull(this, node)
                    this.logger.info('刷新模型制品成功', [node])
                    this.selectNodes([node])
                }
                catch (err) {
                    this.logger.error(
                        `刷新模型制品失败: ${err.message}`,
                        [node]
                    )

                    err.nodes = [node]
                    throw err
                }
            }
        ))
    }
}
