import {Button} from 'antd'
import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'

const AnalyseMultiple = ({children, items, ...props}) => {
    const menuItems = items.map(([label, url]) => ({
        label,
        onClick: () => window.open(url),
    }))

    return (
        <PopoverTrigger
            align="right"
            content={<ContextMenu items={menuItems} />}
            position="bottom"
            {...props}
        >
            <Button
                size="small"
                type="link"
            >
                {children}
            </Button>
        </PopoverTrigger>
    )
}

const AnalyseSingle = ({children, item, ...props}) => {
    const [, url] = item
    const handleClick = () => window.open(url)

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            {children}
        </Button>
    )
}

export default function PropertiesControllerAnalyse({items, ...props}) {
    const [{pkid}] = useProperties()

    if (! (pkid && 0 < items?.length)) {
        return null
    }

    if (1 < items.length) {
        return <AnalyseMultiple items={items} {...props} />
    }
    else {
        return <AnalyseSingle item={items[0]} {...props} />
    }
}
