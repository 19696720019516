import runAsync from '@/script/runAsync.mjs'
import useBizNode from '../../useBizNode.mjs'
import useApiSnapshot from '../../useApiSnapshot.mjs'
import {fromNavigatorMap} from '../../compatibility.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (id) => {
    const BizNode = useBizNode()
    const {read} = useApiSnapshot()

    const {data: map} = useSWR(
        `/maps/${id}/snapshot`,

        () => runAsync(
            () => read({id}),
            {action: '读取地图'}
        )
    )

    if (map) {
        const {data, ...mapModel} = map
        const nodes = fromNavigatorMap(BizNode, mapModel)
        const {bizNodeType} = nodes.root.data
        const mapTypeCode = `_${bizNodeType}_MAP`
        const mapTypeName = BizNode[bizNodeType]?.name

        return {
            ...nodes,
            data: {...data, mapTypeCode, mapTypeName},
        }
    }
    else {
        return undefined
    }
}
