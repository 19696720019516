import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaUc.mjs'
import Model from './ModelUc.mjs'
import PropertiesPane from './PropertiesPaneUc.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchUc.jsx'
import QueryForm from './QueryFormSelectUc.jsx'
import Table from './TableSelectUc.jsx'
import useApi from './useApiUc.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(ROLE)$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getDefaultChildData(map, node) {
            return BizNode.ROLE.getInitData(map, node)
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill="#fff"
                    letters="M"
                    textColor="#000"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {ucOprList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.GOLD}),
                shape: 'BreakangleRectangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['ucOprList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane, PropertiesPaneBatch],
            },
        },

        onPull(map, node) {
            ProductNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BD: 'sfList',
                BF: 'sfList',
                SVR: 'sfList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },
    }
}
