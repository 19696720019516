export const canCopyNodeText = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canCopyNodeText(node)
        )
    }
}

export const copyNodeText = () => () => {
    return async function (nodes) {
        await this.cmd(
            () => this.actions.copyNodeText(nodes)
        )
    }
}
