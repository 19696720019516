import Tree from '@/script/Tree.mjs'
import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaLsi.mjs'
import Model from './ModelLsi.mjs'
import PropertiesPane from './PropertiesPaneLsi.jsx'
import QueryForm from './QueryFormSelectLsi.jsx'
import Table from './TableSelectLsi.jsx'
import useApi from './useApiLsi.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(LSI_(ALG|INPUT|OUTPUT|SF))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, query) => {
                for (const n of map.walkUp(node)) {
                    const {bizNodeType, lsCode} = n.data

                    if ('LS' === bizNodeType) {
                        return {lsCode, ...query}
                    }
                }

                return query
            }

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getInitData(map, parent) {
            const d = ProductNode.getInitData.call(this, map, parent)

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    lsCode,
                    lsName,
                    pkid: lsId,
                } = ancestor.data

                if ('LS' === bizNodeType) {
                    Object.assign({
                        lsCode,
                        lsId,
                        lsName,
                    })

                    break
                }
            }

            return d
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.PURPLE}
                    letters="LI"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                inputAlgList: [],
                outputAlgList: [],
                progList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.PURPLE}),
                shape: 'EllipseRectangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['progList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        async onInsert(map, node) {
            for (const n of map.walkUpNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {pkid} = node.data
                    const treeData = await this.readTree(map, {pkid})
                    const tree = new Tree(treeData)

                    const lsiInput = (() => {
                        for (const n of tree.children(tree.root)) {
                            if ('LSI_INPUT' === n.data.bizNodeType) {
                                return n
                            }
                        }
                    })()

                    if (! lsiInput) {
                        break
                    }

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            BizNode[t].isCategory
                        )

                        return {yieldChildren, yieldNode}
                    }

                    const addAr = node.nextSibling ?
                        ar => map.insertSiblingBefore(node.nextSibling, ar) :
                        ar => map.appendChild(node.parent, ar)

                    for (
                        const n of
                        tree.walkDown(lsiInput, {excludeTarget: true, next})
                    ) {
                        const {
                            arTypeCode,
                            [BizNode.AR.textProp]: t,
                        } = n.data

                        if ('VAR' !== arTypeCode) {
                            continue
                        }

                        const text = t.replace(
                            /^(?:<IP>)? *(\$*.*)$/,
                            '<IP> $1'
                        )

                        const data = {
                            ...n.data,
                            arTypeCode: 'SET_IP',
                            [BizNode.AR.textProp]: text,
                        }

                        const ar = map.importTree({data})
                        addAr(ar)
                    }
                }

                break
            }

            await ProductNode.onInsert.call(this, map, node)
        },
    }
}
