import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSs.mjs'
import Model from './ModelSs.mjs'
import PropertiesPane from './PropertiesPaneSs.jsx'
import QueryForm from './QueryFormSelectSs.jsx'
import Table from './TableSelectSs.jsx'
import useApi from './useApiSs.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(FC_FA|SS_SSINS)$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="S"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                archVmList: [],
                archSsInss: [],
            })
        },

        getStyle(map, node) {
            const {isUi} = node.data

            return this._getStyle(map, node, {
                borderColor: {
                    0: Color.GREEN_YELLOW,
                    1: Color.LIGHT_BLUE,
                    2: Color.GOLD,
                }[isUi]
            })
        },

        async grow(map, node, growedNodes) {
            await ProductNode.grow.call(this, map, node, growedNodes)

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if (BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('DEP_SS' === bizNodeType) {
                    for (const child of [...map.children(node)]) {
                        const {bizNodeType} = child.data

                        if ('SS_SSINS' === bizNodeType) {
                            map.deleteNode(child)
                        }
                        else {
                            map.deleteTree(child)
                        }
                    }
                }
                else {
                    break
                }
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'archVmList',
                'archSsInss',
            ])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
