import runAsync from '@/script/runAsync.mjs'
import useApiShare from '../../useApiShare.mjs'
import useBizNode from '../../useBizNode.mjs'
import {fromNavigatorMap} from '../../compatibility.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (id) => {
    const BizNode = useBizNode()
    const {read} = useApiShare()

    const {data: map} = useSWR(
        `/maps/${id}/share`,

        () => runAsync(
            () => read({id}),
            {action: '读取地图'}
        )
    )

    if (map) {
        const {data, ...mapModel} = map

        return {
            ...fromNavigatorMap(BizNode, mapModel),
            data,
        }
    }
    else {
        return undefined
    }
}
