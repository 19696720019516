import {Button} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

const PropertiesPaneButtonIs = (props) => {
    const map = useMapContext()
    const {prjId, sVer} = map.data

    const handleClick = async () => {
        const url = buildUrl('/IsList', {prjId, sVer})
        window.open(url)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >信息结构</Button>
    )
}

export default PropertiesPaneButtonIs
