import BaseMap from '../BaseMap.jsx'
import useMapData from './useMapData.mjs'

export default function SnapshotMap({id, ...props}) {
    const initData = useMapData(id)
    const components = {ToolBar: () => null}

    return (
        <BaseMap
            components={components}
            initData={initData}
            {...props}
        />
    )
 }
