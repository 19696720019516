import useCategoryRootNode from '../useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootPrj.jsx'
import meta from './metaCatRootPrj.mjs'

export default (BizNode) => {
    const CategoryRootNode = useCategoryRootNode(BizNode, meta)

    return {
        ...CategoryRootNode,

        canMountType(map, node, type) {
            return /^([BFU]A|CAT_(DB|DOC))$/.test(type)
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
