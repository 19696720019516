import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {canCopyNodeText, copyNodeText} = map.commands
    const nodes = canCopyNodeText(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'copy-node-text',
        label: '复制文本',
        suffix: '',
        onClick: () => copyNodeText(nodes),
    }
}
