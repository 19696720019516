export function canFold(node) {
    if (node.isFolded) {
        return false
    }

    const notHidden = n => ! this.behaviours.isNodeHidden(n)
    return [...this.children(node)].some(notHidden)
}

export function foldNode(nodes) {
    const nodesToSelect = new Set(this.selectedNodes)

    for (const node of nodes) {
        node.isFolded = true
    }

    for (const node of this.topNodes(nodes)) {
        for (const descendant of this.walkDown(node, {excludeTarget: true})) {
            if (this.selectedNodes.has(descendant)) {
                nodesToSelect.delete(descendant)
            }
        }
    }

    this.selectNodes(nodesToSelect)
}

export function foldTree(nodes) {
    const nodesToSelect = new Set(this.selectedNodes)

    for (const node of this.topNodes(nodes)) {
        node.isFolded = true

        for (const descendant of this.walkDown(node, {excludeTarget: true})) {
            descendant.isFolded = true

            if (this.selectedNodes.has(descendant)) {
                nodesToSelect.delete(descendant)
            }
        }
    }

    this.selectNodes(nodesToSelect)
}
