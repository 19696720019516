import useAbstractNode from '../useAbstractNode.jsx'
import meta from './metaUe_.mjs'
import QueryForm from './QueryFormSelectUe_.jsx'
import Table from './TableSelectUe_.jsx'
import useApi from './useApiUe_.mjs'

export default (BizNode) => {
    const api = useApi()
    const AbstractNode = useAbstractNode(BizNode, meta, api)

    return {
        ...AbstractNode,

        async chooseProduct(map, node) {
            const getQuery = (map, node, query) => {
                const {bizNodeType, ssCode, uiUserCode} = map.root.data

                if ('UI' === bizNodeType) {
                    return {ssCode, uiUserCode, ...query}
                }
                else {
                    return query
                }
            }

            const items = await this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )

            return items.map(e => ({
                ...e,

                data: {
                    ...e.data,

                    bizNodeType: {
                        GENERAL: 'UIALG',
                        UE: 'UE',
                    }[e.data.algType],
                },
            }))
        },
    }
}
