import useBaseNode from './useBaseNode.jsx'

export default (BizNode) => {
    const BaseNode = useBaseNode(BizNode)

    return {
        ...BaseNode,

        canDuplicate() {
            return false
        },

        canWriteNode() {
            return false
        },

        canWriteTree() {
            return false
        },

        getStyle(map, node) {
            return {
                ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: 'lightyellow',
                borderColor: 'red',
            }
        },

        getText(map, node) {
            const {bizNodeType} = node.data
            return `未知类型: ${bizNodeType}`
        },
    }
}
