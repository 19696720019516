import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/dmdts', data),

        publish: (data) => http.put(
            '/dmdts/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        push: (data) => http.post('/dmdts/batchmodify', data),
        pull: ({pkid}) => http.get(`/dmdts/${pkid}/dps`),
        read: ({pkid}) => http.get(`/dmdts/${pkid}`),
        readList: (args) => http.get('/dmdts', {...args, crossFlag: true}),

        revise: ({pkid}) => http.put(
            '/dmdts/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/dmdts/${data.pkid}`),
        updateMap: (data) => http.put(`/dmdts/${data.pkid}/map`, data),
    }
}
