import {css} from '@emotion/react'

const cssTopBar = css({
    backgroundColor: '#fff',
    borderBottom: '1px solid #e8e8e8',
    padding: 12,
})

export default function TopBar(props) {
    return (
        <div
            css={cssTopBar}
            {...props}
        />
    )
}
