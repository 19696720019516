import Check from '../../SidePanel/NodePanel/PropertiesControllerCheck.jsx'
import Number from '../../SidePanel/NodePanel/PropertiesControllerNumber.jsx'
import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import DataType from './PropertiesControllerDataType.jsx'
import DfPrecision from './PropertiesControllerDfPrecision.jsx'
import ButtonMakeFk from './PropertiesPaneButtonMakeFk.jsx'
import Model from './ModelDf.mjs'

const PropertiesPaneBatchDf = (props) => (
    <BatchPropertiesPane
        buttons={<ButtonMakeFk />}

        controllers={
            <>
                <DataType />

                <Number
                    int
                    min={1}
                    prop="dfLen"
                />

                <DfPrecision />
                <Check prop="isPk" />
                <Check prop="isBk" />
                <Check prop="isNull" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBatchDf
