import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (node) => http.post('/dmpcdgs', node),

        publish: (data) => http.put(
            '/dmpcdgs/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/dmpcdgs/${pkid}/dps`),
        push: (data) => http.post('/dmpcdgs/batchmodify', data),
        read: ({pkid}) => http.get(`/dmpcdgs/${pkid}`),
        readList: (args) => http.get('/dmpcdgs', args),

        revise: ({pkid}) => http.put(
            '/dmpcdgs/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/dmpcdgs/${data.pkid}`, data),
        updateMap: (data) => http.put(`/dmpcdgs/${data.pkid}/map`, data),
    }
}
