import useBaseNode from './useBaseNode.jsx'

/**
 * 属性节点类型的基类
 *
 * 属性节点通常用于把其父节点的某个属性作为节点展示，
 * 其实际并不存在于地图模型中
 */
export default (BizNode, {prop, ...config}) => {
    const BaseNode = useBaseNode(BizNode, config)

    return {
        ...BaseNode,

        canCopyTree(map, node) {
            return false
        },

        canDuplicate(map, node) {
            return false
        },

        canWriteNode(map, node) {
            // 可编辑性同父节点

            const {bizNodeType} = node.parent.data
            return BizNode[bizNodeType].canWriteNode(map, node.parent)
        },

        getDesc(map, node) {
            const {data} = node.parent
            const {Model} = BizNode[data.bizNodeType]
            const model = new Model(data)
            return model.name(this.prop)
        },

        getInitData(map, parent) {
            const value = parent.data[this.prop]

            if (value) {
                return {
                    ...BaseNode.getInitData.call(this, map, parent),
                    [this.textProp]: value,
                }
            }
        },

        isMounted() {
            return true
        },

        onDeleteTree(map, node) {
            // 删除节点时清空其父节点对应属性的值

            node.parent.data = {
                ...node.parent.data,
                [prop]: '',
            }
        },

        onSetData(map, node, oldData) {
            // 自身文本值改变时同步其父节点对应属性的值

            const newValue = node.data[this.textProp]
            const oldValue = oldData[this.textProp]

            if (newValue === oldValue) {
                return
            }

            node.parent.data = {
                ...node.parent.data,
                [prop]: newValue,
            }
        },

        prop,
    }
}
