import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaBk.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_CYAN}
                    letters="B"
                    textColor="#666"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...CategoryNode.getStyle.call(this, map, node),
                backgroundColor: '#fff',
                borderColor: Color.CYAN,
            }
        },
    }
}
