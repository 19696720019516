import useAbstractNode from '../useAbstractNode.jsx'
import meta from './metaVr_.mjs'
import QueryForm from '../VR/QueryFormSelectVr.jsx'
import Table from '../VR/TableSelectVr.jsx'
import useApi from './useApiVr_.mjs'

export default (BizNode) => {
    const api = useApi()
    const AbstractNode = useAbstractNode(BizNode, meta, api)

    return {
        ...AbstractNode,

        async chooseProduct(map, node) {
            const items = await this._chooseProduct(
                map, node, QueryForm, Table
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'VR'},
            }))
        },
    }
}
