import React, { useEffect, useState } from 'react';
import { Form, Input, Upload, Space, Button, Tabs, Badge } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import FileUtils from '@/script/FileUtils';
import Modal from '@/components/Modal/Modal';
import Permission from '@/components/Permission.jsx';

/**
 * 导入：界面结构
 * 使用EXCEL模块批量导入界面入口
 * @param prjId 项目ID 必传 
 * @param prjNo 项目编号 必传 
 * @param sVer 基线号 必传 
 * @returns 
 */
const Pane = ({ prjId, prjNo, sVer, onOk, onCancel, ...props }) => {
    const { TabPane } = Tabs;
    const http = useHTTP();
    const [form] = Form.useForm();

    // 数据
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFileList([]);
        form.resetFields();
        form.setFieldsValue({ prjNo });
    }, []);

    // 导入业务模块
    const importBm = async () => {
        if (fileList.length == 0) {
            return Modal.info({ content: '请选择上传文件' });
        }

        try {
            setLoading(true);
            const param = { prjId, sVer, file: fileList[0] };
            const header = { headers: { 'Content-Type': 'multipart/form-data' } };

            const data = await runAsync(
                async () => {
                    return await http.post('/archuas/importUm', param, header);
                }, {
                error: { content: (err) => `导入失败: ${err.message}` },
                loading: { show: true },
                success: { show: true, content: () => '导入成功' },
            });

            doInfo(data);
            onOk();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 打印导入日志
    const doInfo = (data) => {
        const adds = data?.adds;
        const exists = data?.exists;
        const infos = data?.infos;
        const invalids = data?.invalids;

        return Modal.info({
            width: 800,
            title: '导入结果',
            content: <>
                <Tabs type="list" style={{ overflow: 'hidden' }}>
                    {
                        adds && adds.length > 0 &&
                        <TabPane key="adds" tab={<BadgeExt name="新增记录" count={adds.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {adds.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        exists && exists.length > 0 &&
                        <TabPane key="upts" tab={<BadgeExt name="已存在记录" count={exists.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {exists.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        infos && infos.length > 0 &&
                        <TabPane key="infos" tab={<BadgeExt name="信息" count={infos.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {infos.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        invalids && invalids.length > 0 &&
                        <TabPane key="invalids" tab={<BadgeExt name="不合法记录" count={invalids.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {invalids.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }
                </Tabs>
            </>
        });
    }

    const BadgeExt = ({ name, count }) => {
        return <Badge size="small" offset={[10, 0]} count={count}>{name}</Badge>;
    }

    return (
        <Modal
            title={"导入界面结构"}
            width={500}
            style={{ top: 20 }}
            bodyStyle={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '200px', background: '#fff', padding: 12 }}
            closable={true}
            onCancel={onCancel}
            footer={[
                <Space>
                    <Permission permission="ARCH_UA_UPT" projects={[prjId]}>
                        <Button size="small" onClick={onCancel}>取消</Button>
                        <Button size="small" type="primary" onClick={importBm} loading={loading}>确定</Button>
                    </Permission>
                </Space>
            ]}
            {...props}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="prjNo" label="项目编号">
                    <Input disabled />
                </Form.Item>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Upload
                        accept=".xls,.xlsx"
                        fileList={fileList}
                        showUploadList={true}
                        beforeUpload={(file, fileList) => {
                            setFileList(fileList);
                            return false;
                        }}
                        onRemove={(file) => {
                            setFileList([]);
                        }}
                    >
                        <div className="ant-upload-btn bg-grey-light">
                            <PlusOutlined />
                            <div style={{ marginTop: 4 }}>上传</div>
                        </div>
                    </Upload>

                    <Space>
                        <a onClick={async () => {
                            try {
                                const data = await runAsync(
                                    async () => {
                                        return await http.get(`/api/download?type=um`);
                                    }, {
                                    error: { content: (err) => `下载失败: ${err.message}` },
                                    loading: { show: false },
                                    success: { show: false },
                                });
                                FileUtils.download(data.blob, data.fileName);
                            } catch (err) {
                                console.log(err);
                            }
                        }}>下载模板</a>
                    </Space>
                </div>
            </Form>
        </Modal>
    );
}

export default Pane;