import useCategoryRootNode from '../useCategoryRootNode.jsx'
import PropertiesPane from '../CAT_ROOT_PRJ/PropertiesPaneCatRootPrj.jsx'
import meta from './metaCatRootPrjDep.mjs'

export default (BizNode) => {
    const CategoryRootNode = useCategoryRootNode(BizNode, meta)

    return {
        ...CategoryRootNode,

        canLinkType(map, node, type) {
            return /^(PHYS|VR)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.CAT.getInitData(map, node)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['PHYS'],
                ['VR'],
            ]
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
