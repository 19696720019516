import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaPhysDeployedOn.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(PHYS_DEPLOYED_((DB|MID|SS)INS|VR))$/.test(type)
        },
    }
}
