import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDt.mjs'
import Model from './ModelDt.mjs'
import PropertiesPane from './PropertiesPaneDt.jsx'
import QueryForm from './QueryFormSelectDt.jsx'
import Table from './TableSelectDt.jsx'
import useApi from './useApiDt.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(CAT|DF)$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {dms = [], ...query}) => {
                const {prjNo} = map.data.project

                const dbCode = (() => {
                    for (const n of map.walkUp(node)) {
                        const {bizNodeType} = n.data

                        if (BizNode[bizNodeType].isComment) {
                            continue
                        }
                        else if ('DV_DS' === bizNodeType) {
                            return n.parent.data.dbCode
                        }
                        else {
                            return
                        }
                    }
                })()

                return {
                    prjNo,
                    ...query,
                    dbCode,
                    dmIds: dms.map(({dmId}) => dmId),
                }
            }

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getDefaultChildData(map, node) {
            return BizNode.DF.getInitData(map, node)
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.DARK_CYAN}
                        letters="DT"
                        textColor="#666"
                    />
                ]
            }
        },

        getInitData(map, parent) {
            const d = ProductNode.getInitData.call(this, map, parent)

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    dmCode,
                    dmId,
                    dmName,
                } = ancestor.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    Object.assign(d, {
                        dmCode,
                        dmId,
                        dmName,
                    })

                    break
                }
            }

            return d
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {dmDfList: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.CYAN,
                }),

                shape: 'SingleBreakangle',
            }
        },

        getTextSuffix(map, node) {
            const {dtAlias} = node.data

            if (dtAlias) {
                return ` (${dtAlias})`
            }
            else {
                return ProductNode.getTextSuffix.call(this, map, node)
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['dmDfList'])
        },

        onSetData(map, node, oldData) {
            const {dbCode} = node.data

            if (oldData.dbCode && dbCode !== oldData.dbCode) {
                node.data = {
                    ...node.data,
                    dmCode: '',
                    dmId: '',
                    dmName: '',
                }
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('DGF' === bizNodeType) {
                BizNode.DF.castFrom(map, child)
            }

            ProductNode.onAttached.call(this, map, node, child)
        },

        async onInsert(map, node) {
            for (const n of map.walkUpNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {arTypeCode} = n.data

                    if (! /^DB_[CU]$/.test(arTypeCode)) {
                        break
                    }

                    const {pkid} = node.data
                    const {children} = await BizNode.DT.readTree(map, {pkid})

                    const ar = map.importTree({
                        data: {
                            ...BizNode.AR.getInitData(map, node),
                            arTypeCode: 'SET_DF',
                        }
                    })

                    map.insertSiblingAfter(node, ar)

                    for (const tree of children) {
                        const {bizNodeType} = tree.data

                        if (/^[BP]K$/.test(bizNodeType)) {
                            continue
                        }

                        const child = map.importTree(tree)
                        map.appendChild(ar, child)
                        await BizNode[bizNodeType].onAttachTo(map, child)
                    }
                }

                break
            }

            await ProductNode.onInsert.call(this, map, node)
        },
    }
}
