import buildUrl from '@/script/buildUrl.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'

export default function PropertiesControllerAnalyse(props) {
    const [values] = useProperties()
    const {eventTitle, bizNodeType, pkid, rev} = values

    const items1 = [
        [
            '影响分析',

            buildUrl(
                '/BdEventAnalyse',

                {
                    eventId: pkid,
                    eventTitle,
                    mode: 'effect',
                    rev,
                }
            )
        ],
    ]

    const items2 = [
        [
            '依赖分析',

            buildUrl(
                '/BdEventAnalyse',

                {
                    eventId: pkid,
                    eventTitle,
                    mode: 'depend',
                    rev,
                }
            )
        ],

        //[
            //'带版本依赖分析',
            //buildUrl('/DpAnalyse', {bizNodeType, pkid, rev})
        //],
    ]

    return (
        <div
            style={{display: 'flex'}}
            {...props}
        >
            <Analyse items={items1}>影响分析</Analyse>
            <Analyse items={items2}>依赖分析</Analyse>
        </div>
    )
}
