import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaLs.mjs'
import Model from './ModelLs.mjs'
import PropertiesPane from './PropertiesPaneLs.jsx'
import QueryForm from './QueryFormSelectLs.jsx'
import Table from './TableSelectLs.jsx'
import useApi from './useApiLs.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(CAT|DOC|LSI|SF)$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getDefaultChildData(map, node) {
            return BizNode.LSI.getInitData(map, node)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="L"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: '#fff',
                borderColor: Color.PURPLE,
            })
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['LSI'],
                ['SF'],
            ]
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
