import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaPhysDeployedSsIns.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,
    }
}
