import useDesignNode from './useDesignNode.jsx'

export default (BizNode, config, api) => {
    const DesignNode = useDesignNode(BizNode, config, null, api)

    return {
        ...DesignNode,

        menuInsertConcept() {
            return null
        },
    }
}
