import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemUiName = (props) => {
    return (
        <Form.Item
            label="界面名称"
            name="uiName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemUiCode = (props) => {
    return (
        <Form.Item
            label="界面代码"
            name="uiUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemUeName = (props) => {
    return (
        <Form.Item
            label="能力名称"
            name="ueName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const QueryFormSelectUe = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemUiName />
            </Col>

            <Col span={6}>
                <FormItemUiCode />
            </Col>

            <Col span={6}>
                <FormItemUeName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default QueryFormSelectUe
