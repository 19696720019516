import {Button} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'

const PropertiesPaneButtonPreview = (props) => {
    const [{demoType, pkid: uvId}] = useProperties()

    if (! (uvId && 'DEMO' === demoType)) {
        return null
    }

    const handleClick = () => {
        const url = buildUrl('/formilyPreview', {uvId})
        window.open(url)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            预览
        </Button>
    )
}

export default PropertiesPaneButtonPreview
