import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    algType: 'SUMMARY',
    name: '用户故事',
    ownerIdProp: 'svrId',
    ownerTextProp: 'svrName',
    ownerType: 'SVR',
    type: 'SVRSUMMARY',
}
