import {css} from '@emotion/react'
import MenuButtonEdit from './Edit/MenuButtonEdit.jsx'
import MenuButtonMap from './Map/MenuButtonMap.jsx'
import MenuButtonInsertNode from './menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProduct from './menuButtons/MenuButtonInsertProduct.jsx'
import MenuButtonInsertProducts from './menuButtons/MenuButtonInsertProducts/MenuButtonInsertProducts.jsx'
import MenuButtonRefreshProduct from './menuButtons/MenuButtonRefreshProduct.jsx'
import MenuButtonPullProduct from './menuButtons/MenuButtonPullProduct.jsx'
import MenuButtonPushProduct from './menuButtons/MenuButtonPushProduct.jsx'

const cssMenu = css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 8,
})

export default function Menu(props) {
    return (
        <div
            css={cssMenu}
            {...props}
        >
            <MenuButtonMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertProduct />
            <MenuButtonInsertProducts />
            <MenuButtonPushProduct />
            <MenuButtonPullProduct />
            <MenuButtonRefreshProduct />
        </div>
    )
}
