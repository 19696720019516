import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import useSWR from './useSWRNoCacheOnMount.mjs'

const useProject = (prjId) => {
    const http = useHTTP()

    const fetchProject = () => runAsync(
        () => http.get(`/prjs/${prjId}/info`),

        {
            error: {
                content: (err) => `读取项目失败: ${err.message}`,
            },

            loading: {
                content: '正在读取项目，请稍候',
            },
        }
    )

    return useSWR(
        prjId ? `/projects/${prjId}` : undefined,
        fetchProject
    )
}

export default useProject
