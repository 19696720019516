import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaEventDesc.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,
    }
}
