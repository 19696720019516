import {useOpenModal} from '@/components/Modal/Modal.jsx'
import SelectMapVerDialog from '@/pages/MainPage/dialogs/SelectMapVerDialog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {fromNavigatorMap} from '../../compatibility.mjs'
import useBizNode from '../../useBizNode.mjs'
import {replaceWithTree} from '../../scripts/map.mjs'

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()
    const BizNode = useBizNode()

    if (
        ! permission.has('write') ||
        '_DES_MAP' === map.data.mapTypeCode
    ) {
        return null
    }

    const onClick = async () => {
        const data = await openModal(
            <SelectMapVerDialog id={map.data.pkid} />
        )

        if (! data) {
            return
        }

        const {root} = JSON.parse(data.dataMap)

        await map.cmd(
            () => replaceWithTree(
                map,
                map.root,
                fromNavigatorMap(BizNode, {root}).root
            )
        )
    }

    return {
        key: 'import-snapshot',
        label: '导入历史版本',
        onClick,
    }
}
