import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    algType: 'DEVELOP',
    name: '业务事务能力',
    ownerIdProp: 'svrId',
    ownerTextProp: 'svrName',
    ownerType: 'SVR',
    type: 'SVRALG',
}
