import {useMidType} from '@/biz/bizTable.mjs'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'

export default function PropertiesControllerSelectMidType(props) {
    const {pairs: midTypePairs} = useMidType()

    return (
        <Select
            label={e => e.middlewareTypeName}
            options={midTypePairs}
            prop="middlewareType"
            {...props}
        />
    )
}
