import createShape from '../../createShape.mjs'

export function getNodeShape(node, textRect) {
    const style = this.behaviours.getNodeShapeStyle(node)
    const paddings = new Array(4).fill(style.borderWidth)

    const draw = (w, h) => `
        <rect
            x="${style.borderWidth / 2}"
            y="${style.borderWidth / 2}"
            width="${w - style.borderWidth}"
            height="${h - style.borderWidth}"
        />
    `

    const shape = createShape(draw, textRect, paddings, style)
    return [shape, paddings]
}
