import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import IconCircle from '../icons/IconCircle.jsx'
import meta from './metaBj.mjs'
import Model from './ModelBj.mjs'
import PropertiesPane from './PropertiesPaneBj.jsx'

export default (BizNode) => {
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        attrNodes: {
            top: ['ATTR_BJ_POS'],
        },

        canMountType(map, node, type) {
            return /^(BJ_(DESC|REQ))$/.test(type)
        },

        getIcons(map, node) {
            const {onlineFlag, pkid} = node.data

            if (! pkid) {
                return []
            }
            else if ('1' === onlineFlag) {
                return [
                    <IconCircle
                        key="type"
                        fill={Color.GOLD}
                    />]
            }
            else {
                return [
                    <IconCircle
                        key="type"
                        fill={Color.GREY}
                    />
                ]
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW
                }),

                shape: 'Arrow'
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onCreate(map, node) {
            const bfTypeCode = (() => {
                for (const ancestor of map.walkUp(node.parent)) {
                    const {
                        bfTypeCode = 'TARGET',
                        bizNodeType,
                    } = ancestor.data

                    if ('BF' === bizNodeType) {
                        return bfTypeCode
                    }
                }

                return 'TARGET'
            })()

            const childTypes = {
                TARGET: ['BJ_DESC'],
                SITUATION: ['BJ_DESC', 'BJ_REQ'],
            }[bfTypeCode]

            for (const type of childTypes) {
                const data = BizNode[type].getInitData(map, node)
                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                BF: 'bmBjList',
            }[type]

            if (! key) {
                return
            }

            const {bizNodeType} = node.data

            const tree = BizNode[bizNodeType].exportTree(
                map, node, {excludeGrowed: true}
            )

            const realChildren = tree.children.filter(
                (e) => ! /^ATTR_/.test(e.data.bizNodeType)
            )

            const pushData = this._getPushData(map, node, {sfCodes: []})

            data[key].push({
                ...pushData,
                bjMap: JSON.stringify(realChildren),
            })
        },
    }
}
