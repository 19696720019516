export const canDeleteNode = BizNode => defaultActions => {
    return function (node) {
        const {bizNodeType} = node.data

        return (
            defaultActions.canDeleteNode(node) &&
            BizNode[bizNodeType].canDeleteNode(this, node)
        )
    }
}
