import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    mapProp: 'map',
    name: '数据库实例',
    ownerIdProp: 'dbId',
    ownerTextProp: 'dbName',
    ownerType: 'DB',
    textProp: 'dbInsName',
    type: 'DBINS',
}

