import {useEffect, useRef} from 'react'
import {Alert, useOpenModal} from '@/components/Modal/Modal.jsx'
import Logs from '@/pages/MainPage/frames/MapOptLog/MapOptLog.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

const ModalLogs = ({id, ...props}) => {
    const refEl = useRef()

    useEffect(
        () => {
            refEl.current.querySelector('header').remove()
        },

        []
    )

    return (
        <Alert
            title="操作日志"
            width={1200}
            {...props}
        >
            <div ref={refEl}>
                <Logs id={id} />
            </div>
        </Alert>
    )
}

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()

    if (isProductMap(map)) {
        return null
    }

    return {
        key: 'logs',
        label: '操作日志',
        onClick: () => openModal(<ModalLogs id={map.data.pkid} />),
    }
}
