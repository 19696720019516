import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'eventUserCode',
    name: '业务事件',
    ownerIdProp: 'svrId',
    ownerTextProp: 'svrName',
    ownerType: 'SVR',
    textProp: 'eventTitle',
    type: 'SVREVENT',
}
