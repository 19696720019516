import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ruleUserCode',
    mapProp: 'ruleMap',
    name: '界面规则',
    ownerIdProp: 'uiId',
    ownerTextProp: 'uiName',
    ownerType: 'UI',
    textProp: 'ruleTitle',
    type: 'UIRULE',
}
