import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaSsins.mjs'
import Model from './ModelSsins.mjs'
import PropertiesPane from './PropertiesPaneSsins.jsx'

export default (BizNode) => {
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        canMountType(map, node, type) {
            return /^(SSINS_(DEPENDENCE_ON|DEPLOYED_IN))$/.test(type)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="S"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {ssInsEnv} = node.data

            if (ssInsEnv) {
                return `${defaultPrefix}【${ssInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onCreate(map, node) {
            const childTypes = [
                'SSINS_DEPLOYED_IN',
                'SSINS_DEPENDENCE_ON',
            ]

            for (const type of childTypes) {
                const data = BizNode[type].getInitData(map, node)
                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            ComponentNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            if ('SS' !== type) {
                return
            }

            const pushData = this._getPushData(map, node, {
                archSsInsDeploys: [],
                archSsInsDependences: [],
            })

            const tree = this.exportTree(map, node, {excludeGrowed: true})

            data.archSsInss.push({
                ...pushData,
                map: JSON.stringify(tree.children)
            })
        },
    }
}
