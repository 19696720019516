import runAsync from '@/script/runAsync.mjs'
import useProject from '../useProject.mjs'
import useApiMap from '../../useApiMap.mjs'
import useBizNode from '../../useBizNode.mjs'
import {fromNavigatorMap} from '../../compatibility.mjs'
import useSWR from '../useSWRNoCacheOnMount.mjs'

export default (pkid) => {
    const BizNode = useBizNode()
    const {read} = useApiMap()

    const {data: mapData} = useSWR(
        `/maps/${pkid}`,

        () => runAsync(
            () => read({pkid}),
            {action: '读取地图'}
        )
    )

    const {data: project} = useProject(mapData?.prjId)

    if (mapData && project) {
        const {mapModel, ...data} = mapData
        const {prjName, prjNo, sVer} = project

        return {
            ...fromNavigatorMap(BizNode, mapModel),
            data: {...data, prjName, prjNo, project, sVer},
        }
    }
    else {
        return undefined
    }
}
