import Tree from '@/script/Tree.mjs'
import {fromProductMap} from '../../compatibility.mjs'
import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import {replaceWithTree} from '../../scripts/map.mjs'
import meta from './metaUi.mjs'
import Model from './ModelUi.mjs'
import PropertiesPane from './PropertiesPaneUi.jsx'
import QueryForm from './QueryFormSelectUi.jsx'
import Table from './TableSelectUi.jsx'
import useApi from './useApiUi.mjs'
import useContextMenu from './useContextMenuUi.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)
    const contextMenu = useContextMenu(BizNode)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(UI_(CALG|DC|DEMO|PARAM|RULE|UE))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {fms = [], ss, ...query}) => ({
                ...query,
                fmIds: fms.map(({fmId}) => fmId),
                ssId: ss?.ssId,
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        contextMenu,

        getInitData(map, parent) {
            const d = ProductNode.getInitData.call(this, map, parent)

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    fmCode,
                    fmId,
                    fmName,
                    ssCode,
                    ssId,
                    ssName,
                } = ancestor.data

                if (/^CAT_ROOT_[FU]M$/.test(bizNodeType)) {
                    Object.assign(d, {
                        fmCode,
                        fmId,
                        fmName,
                        ssCode,
                        ssId,
                        ssName,
                    })

                    break
                }
            }

            return d
        },

        getIcons(map, node) {
            if (node.data.pkid) {
                return []
            }
            else {
                return [
                    <IconLetters
                        key="type"
                        fill={Color.BLUE}
                        letters="U"
                        textColor="#fff"
                    />
                ]
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                cAlgList: [],
                demoList: [],
                inputAlgList: [],
                outputAlgList: [],
                ruleList: [],
                umUeList: [],
                uvList: [],
                varAlgList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_BLUE,
                }),

                shape: 'Rectangle',
            }
        },

        async grow(map, node, growedNodes) {
            const {isMigrate, mapTypeCode} = map.data

            if (
                1 === isMigrate ||
                /^(BDM|UI)_MAP$/.test(mapTypeCode)
            ) {
                if (! this.canGrow(map, node)) {
                    return
                }

                const {deliverRev, deliverVer, lastRev, pkid, rev} = node.data

                if (growedNodes?.has(pkid)) {
                    map.logger.warn(
                        '为避免无限加载，跳过已加载过的节点',
                        [node]
                    )

                    return
                }

                const {migrateMap} = await api.readMigrateMap({pkid})
                const mapData = JSON.parse(migrateMap)
                const {root} = fromProductMap(BizNode, mapData)
                replaceWithTree(map, node, root)

                node.data = {
                    ...node.data,
                    deliverRev,
                    deliverVer,
                    lastRev,
                    rev,
                }

                node.isFolded = 0 < growedNodes?.size

                for (
                    const n of
                    map.walkDown(node, {excludeTarget: true})
                ) {
                    n.isFolded = false
                }

                await this._growChildren(map, node, growedNodes)
            }
            else {
                await ProductNode.grow.call(this, map, node, growedNodes)

                for (const n of map.walkUpNoComment(node.parent)) {
                    const {bizNodeType} = n.data

                    if ('FRES' !== bizNodeType) {
                        break
                    }

                    const next = chain => {
                        const [n] = chain
                        const {bizNodeType} = n.data
                        const bn = BizNode[bizNodeType]
                        const yieldNode = bn.isMounted(map, n)
                        const yieldChildren = bn.isMounted(map, n)
                        return {yieldChildren, yieldNode}
                    }

                    for (
                        const n of
                        map.walkDown(node, {excludeTarget: true, next})
                    ) {
                        const {bizNodeType, ueTypeCode} = n.data

                        if (
                            'UE' === bizNodeType &&
                            'INI' === ueTypeCode
                        ) {
                            map.unlinkTree(n)
                            map.prependChild(node, n)

                            while (n.nextSibling) {
                                map.deleteTree(n.nextSibling)
                            }
                        }
                    }
                }
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, [
                'cAlgList',
                'demoList',
                'inputAlgList',
                'outputAlgList',
                'ruleList',
                'umUeList',
                'uvList',
                'varAlgList',
            ])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        async onInsert(map, node) {
            for (const n of map.walkUpNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {pkid} = node.data
                    const treeData = await this.readTree(map, {pkid})
                    const tree = new Tree(treeData)

                    const uiInput = (() => {
                        for (const n of tree.children(tree.root)) {
                            if ('UI_INPUT' === n.data.bizNodeType) {
                                return n
                            }
                        }
                    })()

                    if (! uiInput) {
                        break
                    }

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            BizNode[t].isCategory
                        )

                        return {yieldChildren, yieldNode}
                    }

                    const addAr = node.nextSibling ?
                        ar => map.insertSiblingBefore(node.nextSibling, ar) :
                        ar => map.appendChild(node.parent, ar)

                    for (
                        const n of
                        tree.walkDown(uiInput, {excludeTarget: true, next})
                    ) {
                        const {
                            arTypeCode,
                            [BizNode.AR.textProp]: t,
                        } = n.data

                        if ('VAR' !== arTypeCode) {
                            continue
                        }

                        const text = t.replace(
                            /^(?:<IP>)? *(\$*.*)$/,
                            '<IP> $1'
                        )

                        const data = {
                            ...n.data,
                            arTypeCode: 'SET_IP',
                            [BizNode.AR.textProp]: text,
                        }

                        const ar = map.importTree({data})
                        addAr(ar)
                    }
                }

                break
            }

            await ProductNode.onInsert.call(this, map, node)
        },
    }
}
