import { Button } from 'antd'
import useHTTP from '@/hooks/useHTTP.mjs'
import { writeFileToDisk } from '@/script/file.mjs'
import runAsync from '@/script/runAsync.mjs'

const PropertiesPaneButtonExport = ({ nodes, ...props }) => {
    const http = useHTTP()

    const pkids = nodes
        .filter(({ data: { pkid } }) => pkid)
        .map(({ data: { pkid } }) => pkid)

    if (0 === pkids.length) {
        return null
    }

    const handleClick = async () => {
        const { blob, fileName } = await runAsync(
            () => http.post('/rmucs/export', { pkids }),
            { action: '导出用例' }
        )

        const types = [{ accept: { "application/msexcel": [".xlsx"] } }];
        writeFileToDisk(blob, { suggestedName: fileName, types })
    }

    return (
        <Button
            type="primary"
            onClick={handleClick}
            {...props}
        >导出用例</Button>
    )
}

export default PropertiesPaneButtonExport
