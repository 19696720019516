import MenuButton from '../MenuButton.jsx'
import useMenuItemCopy from './useMenuItemCopy.jsx'
import useMenuItemCopyStyle from './useMenuItemCopyStyle.jsx'
import useMenuItemCut from './useMenuItemCut.jsx'
import useMenuItemDelete from './useMenuItemDelete.jsx'
import useMenuItemDeleteSingleNode from './useMenuItemDeleteSingleNode.jsx'
import useMenuItemDuplicate from './useMenuItemDuplicate.jsx'
import useMenuItemFold from './useMenuItemFold.jsx'
import useMenuItemMoveDown from './useMenuItemMoveDown.jsx'
import useMenuItemMoveUp from './useMenuItemMoveUp.jsx'
import useMenuItemPaste from './useMenuItemPaste.jsx'
import useMenuItemPasteAfter from './useMenuItemPasteAfter.jsx'
import useMenuItemPasteStyle from './useMenuItemPasteStyle.jsx'
import useMenuItemRedoChange from './useMenuItemRedoChange.jsx'
import useMenuItemRedoSelection from './useMenuItemRedoSelection.jsx'
import useMenuItemResetStyle from './useMenuItemResetStyle.jsx'
import useMenuItemSelectAll from './useMenuItemSelectAll.jsx'
import useMenuItemSelectRoot from './useMenuItemSelectRoot.jsx'
import useMenuItemUndoChange from './useMenuItemUndoChange.jsx'
import useMenuItemUndoSelection from './useMenuItemUndoSelection.jsx'

export default function MenuButtonEdit(props) {
    const items = [
        useMenuItemUndoChange(),
        useMenuItemRedoChange(),
        useMenuItemUndoSelection(),
        useMenuItemRedoSelection(),
        {divider: true},
        useMenuItemCut(),
        useMenuItemCopy(),
        useMenuItemPaste(),
        useMenuItemPasteAfter(),
        useMenuItemDuplicate(),
        useMenuItemDelete(),
        useMenuItemDeleteSingleNode(),
        {divider: true},
        useMenuItemCopyStyle(),
        useMenuItemPasteStyle(),
        useMenuItemResetStyle(),
        {divider: true},
        useMenuItemMoveUp(),
        useMenuItemMoveDown(),
        useMenuItemFold(),
        {divider: true},
        useMenuItemSelectRoot(),
        useMenuItemSelectAll(),
    ]

    return (
        <MenuButton
            items={items}
            {...props}
        >
            编辑
        </MenuButton>
    )
}
