export const canGrowNode = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data
        return BizNode[bizNodeType].canGrow(this, node)
    }
}

export const growNode = BizNode => () => {
    return function (nodes, recursive) {
        return Promise.allSettled(nodes.map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data
                    const growedNodes = recursive ? new Set() : undefined
                    await BizNode[bizNodeType].grow(this, node, growedNodes)
                    this.logger.info('加载节点成功', [node])
                }
                catch (err) {
                    this.logger.error(`加载节点失败: ${err.message}`, [node])
                    err.nodes = [node]
                    throw err
                }
            }
        ))
    }
}
