import Color from '../Color.mjs'
import useModuleNode from '../useModuleNode.jsx'
import meta from './metaUm.mjs'
import Model from './ModelUm.mjs'
import PropertiesPane from './PropertiesPaneUm.jsx'

export default (BizNode) => {
    const ModuleNode = useModuleNode(BizNode, meta, Model)

    return {
        ...ModuleNode,

        canMountType(map, node, type) {
            return /^(UM)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return this.getInitData(map, node)
        },

        getStyle(map, node) {
            const {bizNodeType} = node.parent.data

            const shape = this.type === bizNodeType ?
                'HorizontalHexagon' : 'RoundedRectangle'

            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_BLUE}),
                shape,
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onPush(map, node, type, data) {
            const key = {
                UA: 'archUmList',
                UM: 'child'
            }[type]

            if (key) {
                data[key].push(
                    this._getPushData(map, node, {child: []})
                )
            }
        },
    }
}
