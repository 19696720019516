import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import {fromTree} from '../../compatibility.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaSvrevent.mjs'
import Model from './ModelSvrevent.mjs'
import PropertiesPane from './PropertiesPaneSvrevent.jsx'

export default (BizNode) => {
    const http = useHTTP()
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        canMountType(map, node, type) {
            return /^EVENT_(PUBLISHER|SUBSCRIBER)$/.test(type)
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.LIGHT_YELLOW}
                    letters="E"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
                shape: 'EllipseRectangle',
            })
        },

        async grow(map, node, growedNodes) {
            return map.BizNode.BDEVENT.grow.call(this, map, node, growedNodes)
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onCreate(map, node) {
            return map.BizNode.BDEVENT.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                SVR: 'eventList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },

        _getPushData(map, node, slots) {
            const data = ComponentNode._getPushData.call(this, map, node, slots)
            const n = map.nodeProxy(node)
            const tree = n.exportTree(map, node, {excludeGrowed: true})
            const eventMap = JSON.stringify(tree.children)
            return {...data, eventMap}
        },
    }
}
