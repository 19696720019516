export default () => {
    const watchers = {
        selected_nodes_change(selectedNodes) {
            for (const node of this.bottomNodes(selectedNodes)) {
                for (const anscestor of this.walkUp(node.parent)) {
                    anscestor.isFolded = false
                }
            }
        },
    }

    return {watchers}
}
