import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectVm from '../VM/SelectVm.jsx'

const PropertiesControllerVm = ({
    codeProp = 'vmCode',
    disabled,
    idProp = 'vmId',
    nameProp = 'vmName',
    ssCodeProp = 'ssCode',
    ssIdProp = 'ssId',
    ssNameProp = 'ssName',
    readOnly,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        ssCode,
        [codeProp]: vmCode,
        [idProp]: vmId,
        [nameProp]: vmName,
    } = values

    const handleChange = vm => {
        if (vm) {
            const {
                ssCode,
                ssId,
                ssName,
                vmCode,
                vmId,
                vmName,
            } = vm

            update({
                [codeProp]: vmCode,
                [idProp]: vmId,
                [nameProp]: vmName,
                [ssCodeProp]: ssCode,
                [ssIdProp]: ssId,
                [ssNameProp]: ssName,
            })
        }
        else {
            update({
                [codeProp]: '',
                [idProp]: '',
                [nameProp]: '',
            })
        }
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectVm
                ssCode={ssCode}
                disabled={disabled || model.disabled('codeProp')}
                readOnly={readOnly || gReadOnly}
                value={{vmCode, vmId, vmName}}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerVm
