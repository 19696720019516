import useAbstractNode from '../useAbstractNode.jsx'
import meta from './metaSf[Ui]_.mjs'
import QueryForm from './QueryFormSelectSf[Ui]_.jsx'
import Table from './TableSelectSf[Ui]_.jsx'
import useApi from './useApiSf[Ui]_.mjs'

export default (BizNode) => {
    const api = useApi()
    const AbstractNode = useAbstractNode(BizNode, meta, api)

    return {
        ...AbstractNode,

        async chooseProduct(map, node) {
            const items = await this._chooseProduct(
                map, node, QueryForm, Table
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'SF'},
            }))
        },
    }
}
