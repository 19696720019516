import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import PropertiesPane from '../BDALG/PropertiesPaneBdalg.jsx'
import PropertiesPaneBatch from '../BDALG/PropertiesPaneBatchBdalg.jsx'
import meta from './metaBdbase.mjs'

export default (BizNode) => {
    const AlgNode = useAlgNode(BizNode, meta)

    return {
        ...AlgNode,
        algType: 'BASE',

        canMountType(map, node, type) {
            return BizNode.BDALG.canMountType.call(this, map, node, type)
        },

        getInitData(map, parent) {
            return BizNode.BDALG.getInitData.call(this, map, parent)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.LIGHT_YELLOW,
            })
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane, PropertiesPaneBatch],
            },
        },

        onCreate(map, node) {
            const childTypes = [
                'ALG_RULE',
                'ALG_ALG',
                'ALG_API',
            ]

            for (const type of childTypes) {
                const data = BizNode[type].getInitData(map, node)
                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            AlgNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            return BizNode.BDALG.onPush.call(this, map, node, type, data)
        },
    }
}
