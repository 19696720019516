import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import Model from './ModelBfdataflow.mjs'
import Analyse from './PropertiesControllerAnalyse.jsx'

const PropertiesPaneBfdataflow = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <Text prop="algTitle" />
            </>
        }

        Model={Model}
        productSnSuffix={<Analyse />}
        {...props}
    />
)

export default PropertiesPaneBfdataflow
