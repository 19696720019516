import useAlgCatNode from '../_ALG_CAT/useAlgCatNode.jsx'
import meta from './metaBdDevelop.mjs'

export default (BizNode) => {
    const AlgCatNode = useAlgCatNode(BizNode, meta)

    return {
        ...AlgCatNode,
        algNodeType: 'BDALG',

        canMountType(map, node, type) {
            return /^(BDALG|CAT)$/.test(type)
        },

        getInitData(map, parent) {
            return {
                ...AlgCatNode.getInitData.call(this, map, parent),
                algType: 'DEVELOP',
            }
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '2' === bdType
        },
    }
}
