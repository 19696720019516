import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaAlgInput.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        onPull(map, node) {
            ArCatNode.onPull.call(this, map, node)
            node.isFolded = false
        },
    }
}
