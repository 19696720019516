import useCategoryRootNode from '../useCategoryRootNode.jsx'
import PropertiesPane from './PropertiesPaneCatRootDm.jsx'
import meta from './metaCatRootDm.mjs'

export default (BizNode) => {
    const CategoryRootNode = useCategoryRootNode(BizNode, meta)

    return {
        ...CategoryRootNode,

        canLinkType(map, node, type) {
            return /^(CDT)$/.test(type)
        },

        canMountType(map, node, type) {
            return (
                /^(CDG|DT)$/.test(type) ||
                CategoryRootNode.canMountType.call(this, map, node, type)
            )
        },

        getDefaultChildData(map, node) {
            return BizNode.DT.getInitData(map, node)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['CDG'],
                ['DT'],
                ['CDT'],
            ]
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
