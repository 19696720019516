import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'termUserCode',
    mapProp: 'termMap',
    name: '业务术语',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    textProp: 'termTitle',
    type: 'BDTERM',
}
