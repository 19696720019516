import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        publish: (data) => http.put(
            '/archfas/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/archfas/${pkid}/dps`),
        push: (data) => http.post('/archfas/batchmodify', data),

        read: ({pkid}) => http.get(`/archfas/${pkid}`),

        revise: ({pkid}) => http.put(
            '/archfas/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/archfas/${data.pkid}`, data),
        updateMap: (data) => http.put(`/archfas/${data.pkid}/map`, data),
    }
}
