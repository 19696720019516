export default (map, BizNode, node) => {
    const {bizNodeType} = node.data
    const {ownerIdProp, ownerType} = BizNode[bizNodeType]
    const {pkid, [ownerIdProp]: ownerId} = node.data

    if (! (ownerId && pkid)) {
        return null
    }

    for (const ancestor of map.walkUp(node.parent)) {
        const {bizNodeType: bnt, pkid: id} = ancestor.data

        if (ownerType === bnt) {
            if (ownerId === id) {
                return ancestor
            }

            break
        }
    }

    return null
}
