import {BizNodeContext} from './useBizNode.mjs'
import useUnknown from './nodes/useUnknownNode.jsx'

const modules = import.meta.glob(
    './nodes/*/useNode*.jsx',

    {
        import: 'default',
        eager: true,
    }
)

export default function BizNodeProvider(props) {
    // 先定义再赋属性值以避免循环引用
    const BizNode = {}

    const BizNodeProxy = new Proxy(BizNode, {
        get(target, prop) {
            if (target[prop]) {
                return target[prop]
            }
            else {
                return Unknown
            }
        }
    })

    const Unknown = useUnknown(BizNodeProxy)

    for (const useBizNode of Object.values(modules)) {
        const bn = useBizNode(BizNodeProxy)
        BizNode[bn.type] = bn
    }

    return (
        <BizNodeContext.Provider
            value={BizNodeProxy}
            {...props}
        />
    )
}
