import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/archsss', data),

        publish: (data) => http.put(
            '/archsss/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/archsss/${pkid}/dps`),
        push: (data) => http.post('/archsss/batchmodify', data),
        read: ({pkid}) => http.get(`/archsss/${pkid}`),
        readList: (args) => http.get('/archsss', args),

        revise: ({pkid}) => http.put(
            '/archsss/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/archsss/${data.pkid}`, data),
        updateMap: (data) => http.put(`/archsss/${data.pkid}/map`, data),
    }
}
