import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaSfAlg.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(ALG_(INPUT|OUTPUT|SUMMARY))$/.test(type)
        },

        mapProp: meta.mapProp,

        onPush(map, node, type, data) {
            if ('SF' !== type) {
                return
            }

            const tree = this.exportTree(map, node, {excludeGrowed: true})
            const algMap = JSON.stringify(tree.children)
            data.algList.push({...tree.data, algMap})
        },

        isHidden(map, node) {
            return /^(SF)_LVL$/.test(map.data.project?.desgAcc)
        },
    }
}
