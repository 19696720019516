import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaCdtseqval.mjs'
import Model from './ModelCdtseqval.mjs'
import PropertiesPane from './PropertiesPaneCdtseqval.jsx'

export default (BizNode) => {
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.CYAN})
        },

        getTextSuffix(map, node) {
            const {
                pkid,
                [this.codeProp]: code,
            } = node.data

            if (pkid) {
                return ` ${code}`
            }
            else {
                return ComponentNode.getTextSuffix.call(this, map, node)
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onPull(map, node) {
            ComponentNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            const key = {
                CDT: 'children',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },
    }
}
