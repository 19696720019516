import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDv.mjs'
import Model from './ModelDv.mjs'
import PropertiesPane from './PropertiesPaneDv.jsx'
import QueryForm from './QueryFormSelectDv.jsx'
import Table from './TableSelectDv.jsx'
import useApi from './useApiDv.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(DV_(DS|MEMO))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {dms = [], ...query}) => {
                const {prjNo} = map.data.project

                const dbCode = (() => {
                    for (const n of map.walkUp(node)) {
                        const {bizNodeType} = n.data

                        if (BizNode[bizNodeType].isComment) {
                            continue
                        }
                        else if ('DV_DS' === bizNodeType) {
                            return n.parent.data.dbCode
                        }
                        else {
                            return
                        }
                    }
                })()

                return {
                    prjNo,
                    ...query,
                    dbCode,
                    dmIds: dms.map(({dmId}) => dmId),
                }
            }

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill="#fff"
                    letters="V"
                    textColor="#000"
                />
            ]
        },

        getInitData(map, parent) {
            const d = ProductNode.getInitData.call(this, map, parent)

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    dmCode,
                    dmId,
                    dmName,
                } = ancestor.data

                if ('CAT_ROOT_DM' === bizNodeType) {
                    Object.assign(d, {
                        dmCode,
                        dmId,
                        dmName,
                    })

                    break
                }
            }

            return d
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.CYAN,
                }),

                shape: 'SingleBreakangle',
            }
        },

        mapUpdateNodeData(map, node, data) {
            const d = {...data}
            const oldData = node.data

            if (
                ! data.dmCode &&
                oldData.dbCode !== data.dbCode
            ) {
                Object.assign(d, {
                    dmCode: '',
                    dmId: '',
                    dmName: '',
                })
            }

            return d
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
