import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/dmbds', data),

        publish: (data) => http.put(
            '/dmbds/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/dmbds/${pkid}/dps`),
        push: (data) => http.post('/dmbds/batchmodify', data),
        read: ({pkid}) => http.get(`/dmbds/${pkid}`),
        readList: (args) => http.get('/dmbds', args),

        revise: ({pkid}) => http.put(
            '/dmbds/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/dmbds/${data.pkid}`, data),

        updateMigrate: (data) => http.put(
            `/dmbds/${data.pkid}/migrate`,
            data
        ),

        updateMap: (data) => http.put(`/dmbds/${data.pkid}/map`, data),
    }
}
