import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import ProgType from './ProgType.mjs'
import Model from './ModelProg.mjs'

const PropertiesPaneFprog = (props) => (
    <ComponentPropertiesPane
        controllers={
            <>
                <LongText prop="progTitle" />

                <Select
                    options={ProgType}
                    prop="progTypeCode"
                />

                <LongText prop="progDesc" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneFprog
