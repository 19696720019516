import useBaseNode from './useBaseNode.jsx'

/**
 * 通用节点类型的基类
 *
 * 通用节点可通过「插入通用节点」的方式插入地图
 */
export default (BizNode, config) => {
    const BaseNode = useBaseNode(BizNode, config)

    return {
        ...BaseNode,

        menuInsertCommon(map, nodes) {
            return this._menuInsertCommon(map, nodes)
        },

        _menuInsertCommon(
            map,
            nodes,

            {
                key = this.type,
                label = this.name,

                onClick = () => {
                    map.commands.insertBizNode(
                        map.selectedNodes,
                        this.type
                    )
                },

                rank = Infinity,
            } = {}
        ) {
            for (const node of nodes) {
                const {bizNodeType} = node.data
                const bn = BizNode[bizNodeType]

                if (! bn.canMountType(map, node, this.type)) {
                    return null
                }
            }

            return [rank, {key, label, onClick}]
        }
    }
}
