import meta from './metaIprog.mjs'
import useProgNode from '../PROG/useProgNode.jsx'

export default (BizNode) => {
    const ProgNode = useProgNode(BizNode, meta)

    return {
        ...ProgNode,

        canLinkType(map, node, type) {
            return /^(FC)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['FC'],
            ]
        },

        onPush(map, node, type, data) {
            if ('LSI' !== type) {
                return
            }

            const d = {
                ...this._getPushData(map, node),
                fcCode: '',
                ssCode: '',
            }

            if ('FC' === node.data.progTypeCode) {
                for (const child of map.children(node)) {
                    const {bizNodeType, fcCode, ssCode} = child.data

                    if ('FC' === bizNodeType) {
                        Object.assign(d, {fcCode, ssCode})
                        break
                    }
                }
            }

            data.progList.push(d)
        },
    }
}
