import {Button} from 'antd'
import buildUrl from '@/script/buildUrl.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'

export default function PropertiesControllerProjectDetail(props) {
    const [values] = useProperties()
    const {pkid: prjId, prjName, prjNo} = values

    const handleClickDetail = () => {
        const url = buildUrl('/ProjectDetail', {prjId, prjName, prjNo})
        window.open(url)
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClickDetail}
            {...props}
        >项目详情</Button>
    )
}
