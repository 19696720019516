import MenuButton from '../MenuButton.jsx'
import useMenuItemGrowNode from './useMenuItemGrowNode.jsx'
import useMenuItemGrowNodeRecursive from './useMenuItemGrowNodeRecursive.jsx'
import useMenuItemImportSnapshot from './useMenuItemImportSnapshot.jsx'
import useMenuItemImportTemplate from './useMenuItemImportTemplate.jsx'
import useMenuItemListSnapshot from './useMenuItemListSnapshot.jsx'
import useMenuItemLogs from './useMenuItemLogs.jsx'
import useMenuItemMakeAllProducts from './useMenuItemMakeAllProducts.jsx'
import useMenuItemMakeProduct from './useMenuItemMakeProduct.jsx'
import useMenuItemPublishMap from './useMenuItemPublishMap.jsx'
import useMenuItemPullProduct from './useMenuItemPullProduct.jsx'
import useMenuItemPushProduct from './useMenuItemPushProduct.jsx'
import useMenuItemRefreshProduct from './useMenuItemRefreshProduct.jsx'
import useMenuItemReviseMap from './useMenuItemReviseMap.jsx'
import useMenuItemSaveMap from './useMenuItemSaveMap.jsx'
import useMenuItemSaveProduct from './useMenuItemSaveProduct.jsx'
import useMenuItemShareMap from './useMenuItemShareMap.jsx'
import useMenuItemNodeDesc from './useMenuItemNodeDesc.jsx'
import useMenuItemShrinkNode from './useMenuItemShrinkNode.jsx'

export default function MenuButtonMap(props) {
    const items = [
        useMenuItemImportTemplate(),
        useMenuItemSaveMap(),
        useMenuItemPublishMap(),
        useMenuItemReviseMap(),
        useMenuItemPushProduct(),
        useMenuItemSaveProduct(),
        useMenuItemRefreshProduct(),
        useMenuItemPullProduct(),
        {divider: true},
        useMenuItemGrowNode(),
        useMenuItemGrowNodeRecursive(),
        useMenuItemShrinkNode(),
        {divider: true},
        useMenuItemMakeProduct(),
        useMenuItemMakeAllProducts(),
        {divider: true},
        useMenuItemListSnapshot(),
        useMenuItemImportSnapshot(),
        useMenuItemLogs(),
        {divider: true},
        useMenuItemShareMap(),
        {divider: true},
        useMenuItemNodeDesc(),
    ].filter(a => a)

    return (
        <MenuButton
            items={items}
            {...props}
        >
            地图
        </MenuButton>
    )
}
