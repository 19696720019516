import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'bfNo',
    detailUrl: '/BfDetail',
    idProp: 'bfId',
    mapProp: 'bfMap',
    name: '业务流程',
    textProp: 'bfName',
    type: 'BF',
}
