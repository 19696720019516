import {Image} from 'antd'
import useExistedUrl from '@/hooks/useExistedUrl.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'

const PropertiesControllerThumb = (props) => {
    const [{fileId}] = useProperties()

    const src = useExistedUrl(
        fileId && `${import.meta.env.VITE_HTTP_BASE_URL}/files/${fileId}`
    )

    if (! src) {
        return null
    }

    return (
        <Image
            src={src}
            {...props}
        />
    )
}

export default PropertiesControllerThumb
