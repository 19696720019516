import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Input, Radio, Checkbox, Select, Button, Space, Modal, Tree, TreeSelect, Empty, Tabs } from 'antd';
import { SearchOutlined, CaretRightOutlined } from '@ant-design/icons';
import Table from '@/pages/MainPage/components/Table';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Page from '@/script/Page';

/**
 * SelectDtoDialog ：选择DTO【默认单选，可支持多选】
 * 
 * 使用场景：
 * 
 * 参数说明：
 * visible:     【必填】 当前Dialog是否展示 
 * setVisible:  【必填】 当前Dialog展示的操作方法 
 * onCall:      【必填】 回调方法 
 * prjId:       【必填】 prjId 项目ID
 * sVer:        【必填】 sVer  项目基线号
 * ssCode:      【必填】 ssCode  子系统Code
 * isSingle:    【可选】 true(单选、默认)  false(多选)  
 * dataType:    【可选】 Basic Ref
 * 
 * @param {*} param
 * @returns 单选，返回对象；多选返回数组；
 **/
const Pane = ({ visible, setVisible, onCall, prjId, sVer, ssCode, isSingle = true, dataType, ...props }) => {
    const http = useHTTP();
    const { TabPane } = Tabs;
    const { DirectoryTree } = Tree;
    const [form] = Form.useForm();

    // 数据模型，表格多选服务
    const defRef = useRef({});
    const listCount = useRef(0);
    const [dataList1, setDataList1] = useState([]);
    const [dataList2, setDataList2] = useState([]);

    // 被选择的数据
    const [type, setType] = useState('Basic');
    const [selectedList1, setSelectedList1] = useState([]);
    const [selectedList2, setSelectedList2] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);

    // 组件挂载执行一次
    useEffect(() => {
        if (visible) {
            // 分页
            defRef.current.pageSize = Page.pageSize;
            defRef.current.currentPage = 1;

            setDataList2([]);
            setSelectedList1([]);
            setSelectedList2([]);

            if (null != dataType && dataType != undefined) {
                setType(dataType);
                doSearch();
                init();
            } else {
                setType('Basic');
                doSearch();
                init();
            }
        }
        listCount.current = 0;
    }, [visible]);

    const init = () => {
        let data = [];
        // data.push({ pkid: 1, code: 'Byte', name: '字节' });
        data.push({ pkid: 2, code: 'Short', name: '短整型' });
        data.push({ pkid: 3, code: 'Integer', name: '整型' });
        data.push({ pkid: 4, code: 'Long', name: '长整型' });
        data.push({ pkid: 5, code: 'Float', name: '浮点型' });
        data.push({ pkid: 6, code: 'Double', name: '双浮点型' });
        data.push({ pkid: 7, code: 'Boolean', name: '布尔型' });
        data.push({ pkid: 8, code: 'Character', name: '字符型' });
        data.push({ pkid: 9, code: 'String', name: '字符串' });
        data.push({ pkid: 10, code: 'LocalDate', name: '日期' });
        data.push({ pkid: 11, code: 'LocalDateTime', name: '日期时间' });
        data.push({ pkid: 12, code: 'BigDecimal', name: '16位有效位的数进行精确的运算' });
        setDataList1(data);
    }

    // 查询List
    const doSearch = async () => {
        try {
            setLoading(true);
            const formData = form.getFieldsValue();
            formData.prjId = prjId;
            formData.sVer = sVer;
            formData.stsCodes = ['RLS', 'REVISE'];

            const data = await runAsync(
                async () => {
                    return await http.get(`/dmdtos`, { ...formData, ...props });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            setDataList2(data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = () => {
        form.resetFields();
        doSearch();
    }

    // 确认回调
    const doConfirm = () => {
        if (type == "Basic") {
            if (isSingle) {
                if (selectedList1.length == 0) {
                    onCall(null);
                } else {
                    let data = selectedList1[0];
                    onCall({ dataTypeCode: data.code, grpCode: 'Basic' });
                }
            }
        }

        if (type == "Ref") {
            if (isSingle) {
                if (selectedList2.length == 0) {
                    onCall(null);
                } else {
                    let dto = selectedList2[0];
                    console.log(dto);
                    onCall({
                        dtoId: dto.pkid, dtoCode: dto.dtoCode, dtoName: dto.dtoName, dtoUserCode: dto.dtoUserCode, grpCode: 'Ref',
                        dpSn: dto.dpSn, stsCode: dto.stsCode, stsName: dto.stsName, rev: dto.rev,
                    });
                }
            }
        }
        doClose();
    }

    // 分页下标点击回调事件
    const onPage = (current, pageSize) => {
        defRef.current.currentPage = current;
        defRef.current.pageSize = pageSize;
        doSearch();
    }

    // 关闭Dialog
    const doClose = () => {
        setVisible(false);
        form.resetFields();
    }

    // 递归遍历
    const mapMenuNode = (obj) => {
        if (obj.child) {
            return {
                key: obj.pkid,
                title: obj.name,
                dataObj: { ...obj },
                // disabled: (isLeaf && !obj.isLeaf),
                children: obj.child.map(mapMenuNode)
            }
        } else {
            return {
                key: obj.pkid,
                title: obj.name,
                icon: <CaretRightOutlined />,
                dataObj: { ...obj }
            }
        }
    }

    const rowSelection1 = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedList1.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedList1(selectedRows);
        },
    }

    const rowSelection2 = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedList2.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedList2(selectedRows);
        },
    }

    // 表格列头定义
    const columns1 = [
        {
            title: '类型',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        },
    ];

    const columns2 = [
        // {
        //     title: '子系统',
        //     dataIndex: 'ssName',
        //     key: 'ssName',
        // },
        {
            title: 'DTO',
            dataIndex: 'dtoUserCode',
            key: 'dtoUserCode',
            // render: (text, record) => {
            //     return record.dtoName + '/' + text;
            // }
        },
        {
            title: '名称',
            dataIndex: 'dtoName',
            key: 'dtoName',
        },
    ];

    return (
        <Modal
            visible={visible}
            width={1000}
            title='选择数据类型'
            okText="确认"
            cancelText="取消"
            onOk={() => doConfirm()}
            onCancel={() => doClose()}
            bodyStyle={{ background: '#f0f2f5', padding: '4px' }}
        >
            <div style={{ padding: '12px 12px 0 12px', overflow: 'hidden', background: '#fff', height: '480px' }}>
                <Tabs activeKey={type} type="card" style={{ overflow: 'hidden' }}
                    onChange={(value) => {
                        setType(value);
                        setSelectedList1([]);
                        setSelectedList2([]);

                        if ('Ref' == value && listCount.current == 0) {
                            // doSearch();
                            listCount.current = 1;
                        }
                    }}
                >
                    {/*  基础类型 */}
                    {
                        (!dataType || 'Basic' == dataType) &&
                        <TabPane tab="基础类型" key="Basic" style={{ background: '#f0f2f5' }}>
                            {/* 数据表格 */}
                            <div style={{ marginTop: '0px', overflow: 'auto', height: '360px', background: '#fff' }}>
                                {/* 统计区 */}
                                {
                                    selectedList1.length > 0 &&
                                    <div className="smstable-body-statis">
                                        <Space>
                                            <span>已选择</span>
                                            <span>{selectedList1.length}</span>
                                            <span>项</span>
                                        </Space>
                                        <Space>
                                            <a onClick={() => setSelectedList1([])}>取消选择</a>
                                        </Space>
                                    </div>
                                }

                                {/* 数据区 */}
                                <div style={{ marginBottom: '0' }}>
                                    <Table
                                        size="small"
                                        columns={columns1}
                                        dataSource={dataList1}
                                        pagination={Page.init(dataList1, 20)}
                                        rowKey={record => record.pkid}
                                        rowSelection={{ type: "checkbox", ...rowSelection1 }} />
                                </div>
                            </div>
                        </TabPane>
                    }

                    {/*  对象类型 */}
                    {
                        (!dataType || 'Ref' == dataType) &&
                        <TabPane tab="对象类型" key="Ref" style={{ background: '#f0f2f5' }}>
                            {/* 查询条件 */}
                            <div style={{ padding: '0px 12px 0 12px', overflow: 'hidden', background: '#fff' }}>
                                <Form form={form} onFinish={doSearch}>
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <Form.Item name="dtoUserCode" label="代码">
                                                <Input placeholder="请输入代码" allowClear />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8}>
                                            <Form.Item name="dtoName" label="名称">
                                                <Input placeholder="请输入名称" allowClear />
                                            </Form.Item>
                                        </Col>

                                        <Col span={8} style={{ textAlign: 'right' }}>
                                            <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>

                            {/* 数据表格 */}
                            <div style={{ marginTop: '0px', overflow: 'auto', height: '360px', background: '#fff' }}>
                                {/* 数据区 */}
                                <div style={{ marginBottom: '0' }}>
                                    <Table
                                        size="small"
                                        loading={loading}
                                        columns={columns2}
                                        dataSource={dataList2}
                                        pagination={Page.init(dataList2)}
                                        rowKey={record => record.pkid}
                                        rowSelection={{ type: "checkbox", ...rowSelection2 }} />
                                </div>
                            </div>
                        </TabPane>
                    }

                </Tabs>
            </div>
        </Modal >
    );
}

export default Pane;