import BaseMap from '../BaseMap.jsx'
import hotkeys from './hotkeys.mjs'
import ToolBar from './ToolBar.jsx'
import useMapData from './useMapData.mjs'
import useMindMapPlugins from './useMindMapPlugins.mjs'

export default function MigrateMap({mapData: data, ...props}) {
    const initData = useMapData(data)
    const components = {ToolBar}

    return (
        <BaseMap
            components={components}
            hotkeys={hotkeys}
            initData={initData}
            plugins={[useMindMapPlugins()]}
            {...props}
        />
    )
}
