import Color from '../Color.mjs'
import useArchNode from '../useArchNode.jsx'
import meta from './metaFa.mjs'
import PropertiesPane from './PropertiesPaneFa.jsx'
import useApi from './useApiFa.mjs'

export default (BizNode) => {
    const api = useApi()
    const ArchNode = useArchNode(BizNode, meta, api)

    return {
        ...ArchNode,

        canMountType(map, node, type) {
            return /^(FM)$/.test(type)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archFmList: []})
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.GOLD,
            })
        },

        getDefaultChildData(map, node) {
            return BizNode.FM.getInitData(map, node)
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archFmList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            BizNode.FM.onAttached(map, node, child)
        },
    }
}
