import Color from '../Color.mjs'
import useExclusiveNode from '../useExclusiveNode.jsx'
import meta from './metaScene.mjs'

export default (BizNode) => {
    const ExclusiveNode = useExclusiveNode(BizNode, meta)

    return {
        ...ExclusiveNode,

        canMountType(map, node, type) {
            return /^(CAT|FOPR)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.FOPR.getInitData(map, node)
        },

        getStyle(map, node) {
            return {
                ...ExclusiveNode.getStyle.call(this, map, node),
                borderColor: Color.GOLD,
                shape: 'BreakangleRectangle',
            }
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('COPR' === bizNodeType) {
                BizNode.FOPR.castFrom(map, child)
            }

            ExclusiveNode.onAttached.call(this, map, node, child)
        },
    }
}
