import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'
import SsType from './SsType.mjs'

const DictSsType = Object.fromEntries(SsType)

const TableSelectSs = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '子系统类型',
            dataIndex: 'isUi',
            component: <Table.ViewDict dict={DictSsType} />,
        },

        {
            title: '子系统代码',
            dataIndex: 'ssUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '子系统名称',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/sss"
            query={query}
            {...props}
        />
    )
}

export default TableSelectSs
