import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useRef,
} from 'react'

import {css} from '@emotion/react'
import useOnResize from '@/hooks/useOnResize.mjs'
import usePointer from '@/hooks/usePointer.mjs'
import {publish} from '@/script/event.mjs'
import {useTreeDocContext} from './TreeDocContext.jsx'

const cssSelection = css({
    position: 'absolute',
    backgroundColor: 'rgba(45, 189, 255, .2)',
    border: '1px solid rgba(45, 189, 255, .5)',
    borderRadius: 4,
    pointerEvent: 'none',
})

const Selection = (props) => {
    const doc = useTreeDocContext()
    const selection = doc.useCanvasSelection()

    if (! selection) {
        return null
    }

    const [x0, y0, x1, y1] = selection

    const style = {
        top: Math.min(y0, y1),
        left: Math.min(x0, x1),
        width: Math.abs(x0 - x1),
        height: Math.abs(y0 - y1),
    }

    return (
        <div
            css={cssSelection}
            style={style}
            {...props}
        ></div>
    )
}

const usePointerEvents = (doc) => usePointer({
    onClick: (e) => {
        publish(doc, 'canvas_click', e)
    },

    onDoubleClick: (e) => {
        publish(doc, 'canvas_double_click', e)
    },

    onPointerDown: (e) => {
        publish(doc, 'canvas_pointer_down', e)
    },

    onPointerMove: (e) => {
        publish(doc, 'canvas_pointer_move', e)
    },

    onPointerUp: (e) => {
        publish(doc, 'canvas_pointer_up', e)
    },

    onSwipeStart: (e) => {
        e.currentTarget.setPointerCapture(e.pointerId)
        publish(doc, 'canvas_swipe_start', e)
    },

    onSwipeMove: (e) => {
        publish(doc, 'canvas_swipe_move', e)
    },

    onSwipeEnd: (e) => {
        e.currentTarget.releasePointerCapture(e.pointerId)
        publish(doc, 'canvas_swipe_end', e)
    },
})

export default forwardRef(
    function Canvas({children, ...props}, ref) {
        const refEl = useRef()
        useImperativeHandle(ref, () => refEl.current)
        const doc = useTreeDocContext()
        //publish(doc, 'render_start', doc)

        useOnResize(
            refEl,
            size => publish(doc, 'canvas_resize', size)
        )

        useLayoutEffect(
            () => {
                doc.canvas = refEl.current
                return () => doc.canvas = null
            },

            []
        )

        useEffect(
            () => {
                const el = refEl.current

                const handleWheel = e => {
                    publish(doc, 'canvas_wheel', e)
                }

                el.addEventListener('wheel', handleWheel)
                return () => el.removeEventListener('wheel', handleWheel)
            },

            []
        )

        //useEffect(
            //() => {
                //publish(doc, 'render_end', doc)
            //},
        //)

        const pointerEventHandlers = usePointerEvents(doc)

        return (
            <div
                ref={refEl}
                tabIndex="-1"
                onContextMenu={e => e.preventDefault()}
                {...pointerEventHandlers}
                {...props}
            >
                {children}
                <Selection />
            </div>
        )
    }
)
