export const canUpgradeProduct = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data

        return Boolean(
            BizNode[bizNodeType].canUpgrade?.(this, node)
        )
    }
}

export const upgradeProduct = BizNode => () => {
    return async function (nodes) {
        const nodesToGrow = []

        const result = await Promise.allSettled(nodes.map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data
                    await BizNode[bizNodeType].upgrade(this, node)
                    this.logger.info('升级模型制品成功', [node])

                    if (node.firstChild) {
                        nodesToGrow.push(node)
                    }
                }
                catch (err) {
                    this.logger.error(
                        `升级模型制品失败: ${err.message}`,
                        [node]
                    )

                    err.nodes = [node]
                    throw err
                }
            }
        ))

        await this.actions.growNode(nodesToGrow, false)
        return result
    }
}
