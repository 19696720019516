import {Button} from 'antd'
import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

const useMakeSf = () => {
    const http = useHTTP()

    return (args) => runAsync(
        () => http.post('/dmbds/genSf', args),
        {action: '生成系统功能'}
    )
}

export default function PropertiesPaneButtonMakeSf(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const makeSf = useMakeSf()

    if ('_BD_MAP' !== map.data.mapTypeCode) {
        return null
    }

    const algs = [...selectedNodes].filter((e) => e.data.pkid)

    const bdIds = new Set(
        algs.map((e) => e.data.bdId)
    )

    if (1 !== bdIds.size) {
        return null
    }

    const handleClick = async () => {
        const [pkid] = [...bdIds]
        const algList = algs.map(({data}) => data)
        await makeSf({algList, pkid})
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >生成系统功能</Button>
    )
}
