import Color from '../Color.mjs'
import useCommentNode from '../useCommentNode.jsx'
import {IconArrowRight} from '../icons/IconArrow.jsx'
import meta from './metaCat.mjs'

export default (BizNode) => {
    const CommentNode = useCommentNode(BizNode, meta)

    return {
        ...CommentNode,

        getIcons() {
            return [
                <IconArrowRight
                    key="type"
                    fill={Color.ORANGE}
                    color="#fff"
                />
            ]
        },

        menuInsertCommon(map, nodes) {
            return this._menuInsertCommon(map, nodes, {rank: 20})
        },
    }
}
