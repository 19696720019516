import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (args) => http.post('/basmapvers', args),

        fetchIdByRev: async ({bizNodeType, pkid, rev}) => {
            const {pkid: id} = await http.get(
                '/basmapvers/judgeVer',
                {bizNodeType, pkid, rev}
            )

            return id
        },

        list: ({dataId}) => http.get('/basmapvers', {dataId}),

        read: async ({id}) => {
            const {
                crtName: rlsName,
                crtTime: rlsTime,
                dataName: mapName,
                dataMap,
                dataVer: mapRev,
                ...data
            } = await http.get(`/basmapvers/${id}`)

            const map = JSON.parse(dataMap)

            Object.assign(map.data, {
                ...data,
                crtName: '',
                mapName,
                mapRev,
                rlsName,
                rlsTime,
                stsCode: '',
                uptName: '',
            })

            return map
        },

        readByRev: async args => {
            const {
                crtName: rlsName,
                crtTime: rlsTime,
                dataName: mapName,
                dataMap,
                dataVer: mapRev,
                ...data
            } = await http.get('/basmapvers/ver', args)

            const map = JSON.parse(dataMap)

            Object.assign(map.data, {
                ...data,
                crtName: '',
                mapName,
                mapRev,
                rlsName,
                rlsTime,
                stsCode: '',
                uptName: '',
            })

            return map
        }
    }
}
