import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'dtUserCode',
    detailUrl: '/DtDetail',
    idProp: 'dtId',
    mapProp: 'dtMap',
    name: '数据表',
    textProp: 'dtName',
    type: 'DT',
}
