import superMeta from '../metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'vmUserCode',
    mapIdProp: 'fcMapId',
    mapStsCodeProp: 'fcMapStsCode',
    mapTypeCode: 'FC_MAP',
    name: '程序模块',
    ownerIdProp: 'ssId',
    ownerTextProp: 'ssName',
    ownerType: 'SS',
    textProp: 'vmName',
    type: 'VM',
}
