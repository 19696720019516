export const canInsertParent = BizNode => defaultActions => {
    return function (node) {
        if (! defaultActions.canInsertParent(node)) {
            return false
        }

        const p = node.parent
        const {bizNodeType} = p.data
        return BizNode[bizNodeType].canMountType(this, p, 'CAT')
    }
}

export const insertParent = BizNode => () => {
    return function (nodes) {
        const nodesToSelect = new Set()

        const insert = (trees) => {
            for (const [, descendants] of trees) {
                insert(descendants)
            }

            const nodes = trees.map(([node]) => node)

            for (
                const [node, childNodes] of
                this.groupByParent(nodes)
            ) {
                const data = BizNode.CAT.getInitData(this, node)
                const parent = this.importTree({data})
                this.insertParent(parent, childNodes)
                BizNode.CAT.onCreate(this, parent)

                if (! parent.isDeleted) {
                    this.logger.info('已插入父节点', childNodes)
                    nodesToSelect.add(node)
                }
            }
        }

        const trees = this.treelize(nodes)
        insert(trees)
        this.selectNodes(nodesToSelect)
    }
}
