import buildUrl from '@/script/buildUrl.mjs'
import useBaseNode from './useBaseNode.jsx'

/**
 * 预置根分类节点
 */
export default (BizNode, config) => {
    const BaseNode = useBaseNode(BizNode, config)

    return {
        ...BaseNode,

        canMountType(map, node, type) {
            return /^(CAT|DOC|MARK|PIC|URL)$/.test(type)
        },

        canWriteNode() {
            return false
        },

        getStyle(map, node) {
            return {
                ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: '#f6212d',
                textColor: '#fff',
            }
        },

        getUrl(map, node, childPath = []) {
            const {[this.textProp]: text = ''} = node.data
            const path = [encodeURIComponent(text), ...childPath]
            const mapPath = `/${path.join('/')}/`

            return buildUrl(
                '/SoftwareMap',

                {
                    id: map.data.pkid,
                    mapPath,
                }
            )
        },
    }
}
