import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'ruleUserCode',
    mapProp: 'ruleMap',
    name: '业务规则',
    ownerIdProp: 'svrId',
    ownerTextProp: 'svrName',
    ownerType: 'SVR',
    textProp: 'ruleTitle',
    type: 'SVRRULE',
}
