import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Select from '../../SidePanel/NodePanel/PropertiesControllerSelect.jsx'
import TagSelect from '../../SidePanel/NodePanel/PropertiesControllerTagSelect.jsx'
import Model from './ModelBdlogic.mjs'
import RuleType from './RuleType.jsx'
import ButtonMakeSf from './PropertiesPaneButtonMakeSf.jsx'

const PropertiesPaneBatchBdlogic = (props) => (
    <BatchPropertiesPane
        buttons={<ButtonMakeSf />}

        controllers={
            <>
                <Select
                    options={RuleType}
                    prop="ruleType"
                />

                <TagSelect prop="ruleKey" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBatchBdlogic
