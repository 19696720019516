import {Button} from 'antd'
import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'
import useBizNode from '../../useBizNode.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

const useMakeSf = () => {
    const http = useHTTP()

    return (args) => runAsync(
        () => http.post('/dmsvrs/genSf', args),
        {action: '生成系统功能'}
    )
}

export default function PropertiesPaneButtonMakeSf(props) {
    const BizNode = useBizNode()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const makeSf = useMakeSf()

    if ('_SVR_MAP' !== map.data.mapTypeCode) {
        return null
    }

    const algs = [...selectedNodes].filter((e) => e.data.pkid)

    const svrIds = new Set(
        algs.map((e) => e.data.svrId)
    )

    if (1 !== svrIds.size) {
        return null
    }

    const handleClick = async () => {
        const [pkid] = [...svrIds]
        const algList = algs.map(({data}) => data)
        const sfs = await makeSf({algList, pkid})
        const {mapProp} = BizNode.SF

        await map.cmd(() => {
            const nodesToSelect = new Set()

            for (const [i, alg] of algs.entries()) {
                const {
                    [mapProp]: _,
                    ...sf
                } = sfs[i]

                const algSf = (() => {
                    for (const child of map.children(alg)) {
                        const {bizNodeType} = child.data

                        if ('ALG_SF' === bizNodeType) {
                            return child
                        }
                    }
                })()

                if (algSf) {
                    const data = {...sf, bizNodeType: 'SF'}
                    const node = map.importTree({data})
                    map.appendChild(algSf, node)
                    nodesToSelect.add(node)
                }
            }

            map.selectNodes(nodesToSelect)
        })
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >生成系统功能</Button>
    )
}
