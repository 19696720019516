import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        publish: (data) => http.put(
            '/archuas/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/archuas/${pkid}/dps`),
        push: (data) => http.post('/archuas/batchmodify', data),
        read: ({pkid}) => http.get(`/archuas/${pkid}`),

        revise: ({pkid}) => http.put(
            '/archuas/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/archuas/${data.pkid}`, data),
        updateMap: (data) => http.put(`/archuas/${data.pkid}/map`, data),
    }
}
