/* eslint-disable react/jsx-key */

import IconLightning from '../icons/icon-lightning.svg?react'
import Color from '../Color.mjs'

export default [
    [
        'BT',
        '业务事务',
        <IconLightning style={{fill: Color.GOLD}} />
    ],

    [
        'BL',
        '基本逻辑',
        <IconLightning style={{fill: Color.GREEN}} />
    ],
]
