import {ReadOnlyContext} from '@/components/Form/ReadOnly.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import SidePane from '../SidePane.jsx'
import {PropertiesContext} from './Properties.mjs'
import Text from './PropertiesControllerText.jsx'

const MapPane = () => {
    const map = useMapContext()

    return (
        <PropertiesContext.Provider value={[map.data]}>
            <ReadOnlyContext.Provider value={true}>
                <SidePane>
                    <Text
                        prop="prjName"
                        title="项目名称"
                    />

                    <Text
                        prop="sVer"
                        title="基线号"
                    />

                    <Text
                        prop="mapRev"
                        title="地图版本号"
                    />

                    <Text
                        prop="uptTime"
                        title="地图更新时间"
                    />
                </SidePane>
            </ReadOnlyContext.Provider>
        </PropertiesContext.Provider>
    )
}

export default MapPane
