import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaVr.mjs'
import Model from './ModelVr.mjs'
import PropertiesPane from './PropertiesPaneVr.jsx'
import QueryForm from './QueryFormSelectVr.jsx'
import Table from './TableSelectVr.jsx'
import useApi from './useApiVr.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(VR_DEPLOYED_(IN|ON))$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GREEN}
                    letters="|||"
                    textColor="#fff"
                />
            ]
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {depVsDeploys: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.DARK_GREEN,
                }),
            }
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ProductNode.getTextPrefix.call(
                this, map, node
            )

            const {vsEnv} = node.data

            if (vsEnv) {
                return `${defaultPrefix}【${vsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        async grow(map, node, growedNodes) {
            await ProductNode.grow.call(this, map, node, growedNodes)

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if (BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('CAT_ROOT_PRJ_DEP' === bizNodeType) {
                    for (const child of [...map.children(node)]) {
                        const {bizNodeType} = child.data

                        if ('VR_DEPLOYED_ON' === bizNodeType) {
                            map.deleteNode(child)
                        }
                        else {
                            map.deleteTree(child)
                        }
                    }
                }
                else {
                    break
                }
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onPush(map, node, type, data) {
            const {pkid} = node.data

            if (! pkid) {
                return
            }

            const key = {
                DBINS: 'archDbInsDeploys',
                MIDINS: 'archMiddlewareInsDeploys',
                SSINS: 'archSsInsDeploys',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)

            data[key].push({
                ...pushData,

                ...({
                    DBINS: () => {
                        const {prjId, sVer, vsCode} = node.data

                        return {
                            deployType: 'VS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: vsCode,
                        }
                    },

                    MIDINS: () => {
                        const {prjId, sVer, vsCode} = node.data

                        return {
                            deployType: 'VS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: vsCode,
                        }
                    },

                    SSINS: () => {
                        const {prjId, sVer, vsCode} = node.data

                        return {
                            deployType: 'VS',
                            deployPrjId: prjId,
                            deploySVer: sVer,
                            deployCode: vsCode,
                        }
                    },
                }[type])(),
            })
        },
    }
}
