import Color from '../Color.mjs'
import useArchNode from '../useArchNode.jsx'
import meta from './metaBa.mjs'
import PropertiesPane from './PropertiesPaneBa.jsx'
import useApi from './useApiBa.mjs'

export default (BizNode) => {
    const api = useApi()
    const ArchNode = useArchNode(BizNode, meta, api)

    return {
        ...ArchNode,

        canMountType(map, node, type) {
            return /^(BM)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.BM.getInitData(map, node)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archBmList: []})
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.LIGHT_YELLOW,
            })
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archBmList'])
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            BizNode.BM.onAttached(map, node, child)
        },
    }
}
