import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ButtonMap from '../components/PropertiesPaneButtonMap.jsx'
import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import Model from './ModelBm.mjs'

const PropertiesPaneBm = (props) => (
    <ComponentPropertiesPane
        buttons={
            <>
                <ButtonMap />
                <ButtonExportModel type="bm" />
            </>
        }

        controllers={
            <>
                <Text prop="bmNo" />
                <Text prop="bmName" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBm
