import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDgf from '../DGF/SelectDgf.jsx'

const PropertiesControllerDgf = ({disabled, readOnly, ...props}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        cdgCode,
        cdgDfCode,
        cdgDfName,
        cdgDfUserCode,
        cdgName,
        isCdgDf,
    } = values

    if ('1' !== isCdgDf) {
        return null
    }

    const handleChange = (cdg) => {
        const {
            dfCode,
            dfName,
            dfUserCode,
            pcdgCode,
            pcdgName,
            ...value
        } = cdg

        update({
            ...value,
            dfName,
            dfUserCode,
            cdgCode: pcdgCode,
            cdgDfCode: dfCode,
            cdgDfName: dfName,
            cdgDfUserCode: dfUserCode,
            cdgName: pcdgName,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required('cdgDfCode')}>
                {model.name('cdgDfCode')}
            </Label>

            <SelectDgf
                disabled={disabled || model.disabled('cdgDfCode')}
                readOnly={readOnly || gReadOnly}

                value={{
                    dfCode: cdgDfCode,
                    dfName: cdgDfName,
                    dfUserCode: cdgDfUserCode,
                    pcdgCode: cdgCode,
                    pcdgName: cdgName,
                }}

                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerDgf
