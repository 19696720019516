import {Modal} from 'antd'
import runAsync from '@/script/runAsync.mjs'
import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import usePseudoNode from '../usePseudoNode.jsx'
import meta from './metaFk.mjs'
import useApi from './useApiFk.mjs'

export default (BizNode) => {
    const api = useApi()
    const PseudoNode = usePseudoNode(BizNode, meta)

    return {
        ...PseudoNode,

        beforeDeleteTree(map, node) {
            const text = this.getTextRaw(map, node)

            const deleteFk = async () => {
                const {pkid} = node.data

                return runAsync(
                    async () => {
                        try {
                            await api.delete({pkid})
                        }
                        catch (err) {
                            // 外键不存在不视为错误
                            if ('01040004' !== err.code) {
                                throw err
                            }
                        }
                    },

                    {action: '删除外键'}
                )
            }

            return new Promise(resolve => {
                Modal.confirm({
                    content: `确定要删除外键 ${text} 吗？`,
                    onCancel: () => resolve(true),

                    onOk: async () => {
                        try {
                            await deleteFk()
                            resolve(false)
                        }
                        catch (err) {
                            map.logger.error(
                                `删除外键失败: ${err.message}`,
                                [node]
                            )

                            resolve(true)
                        }
                    },
                })
            })
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_CYAN}
                    letters="F"
                    textColor="#666"
                />
            ]
        },

        getInitData(map, parent) {
            return {
                ...PseudoNode.getInitData.call(this, map, parent),
                [this.textProp]: '',
            }
        },

        getStyle(map, node) {
            return {
                ...PseudoNode.getStyle.call(this, map, node),
                borderColor: Color.CYAN,
            }
        },

        getText(map, node) {
            const text = this.getTextRaw(map, node)

            // 联合外键
            if (/\+/.test(text)) {
                return text
            }
            else {
                return ''
            }
        },

        onDeleteTree(map, node) {
            const text = this.getTextRaw(map, node)

            if (! /\+/.test(text)) {
                return
            }

            // 删除同一个联合外键的其他节点

            const dt = (() => {
                for (const n of map.walkUp(node.parent)) {
                    if ('DT' === n.data.bizNodeType) {
                        return n
                    }
                }
            })()

            if (! dt) {
                return
            }

            const next = (chain) => {
                const {bizNodeType} = chain[0].data
                const yieldNode = 'FK' === bizNodeType
                const yieldChildren = ! yieldNode
                return {yieldChildren, yieldNode}
            }

            for (const n of map.walkDown(dt, {excludeTarget: true, next})) {
                if (n.data.pkid === node.data.pkid) {
                    map.deleteTree(n)
                }
            }
        },

        onPull(map, node) {
            PseudoNode.onPull.call(this, map, node)
            node.isFolded = false
        },
    }
}
