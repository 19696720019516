import useHTTP from '@/hooks/useHTTP.mjs'
import runAsync from '@/script/runAsync.mjs'

export default () => {
    const http = useHTTP()

    return ({prjId, sVer}) => runAsync(
        async () => {
            try {
                const {pkid} = await http.get(
                    '/basmaps/depmap',
                    {prjId, sVer}
                )

                return pkid
            }
            catch (err) {
                if ('01020026' === err.code) {
                    const {pkid} = await http.post('/basmaps', {
                        mapName: '部署地图',
                        mapNo: '',
                        mapTypeCode: 'DEP_MAP',
                        prjId,
                    })

                    return pkid
                }
                else {
                    throw err
                }
            }
        },

        {action: '查询部署地图'}
    )
}
