import useFetchDepMapId from '@/biz/useFetchDepMapId.mjs'
import buildUrl from '@/script/buildUrl.mjs'
import runAsync from '@/script/runAsync.mjs'
import {fromNavigatorMap} from '../../compatibility.mjs'
import {deleteChildren, replaceWithTree} from '../../scripts/map.mjs'
import useApiMap from '../../useApiMap.mjs'
import Color from '../Color.mjs'
import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaCatDep.mjs'

export default (BizNode) => {
    const fetchDepMapId = useFetchDepMapId()
    const apiMap = useApiMap()
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        getStyle(map, node) {
            return {
                ...CategoryNode.getStyle.call(this, map, node),
                backgroundColor: Color.DARK_GREEN,
                textColor: '#fff',
            }
        },

        async grow(map, node, growedNodes) {
            const children = await runAsync(
                async () => {
                    const {prjId, sVer} = map.data
                    const pkid = await fetchDepMapId({prjId, sVer})
                    const {mapModel} = await apiMap.read({pkid})

                    const {root: {children}} = fromNavigatorMap(
                        BizNode, mapModel
                    )

                    return children
                },

                {action: '读取部署地图'}
            )

            replaceWithTree(map, node, {children})
        },

        async shrink(map, node) {
            deleteChildren(map, node)
        },

        onDoubleClick(map, node, event) {
            const {pkid: prjId, prjNo, sVer} = map.data.project
            const url = buildUrl('/ProjectDplMap', {prjId, prjNo, sVer})
            window.open(url)
        },
    }
}
