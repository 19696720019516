const modules = import.meta.glob('./shapes/*.mjs', {eager: true})

const shapes = Object.fromEntries(
    Object.values(modules)
        .map(Object.entries)
        .flat()
)

export const getNodeShape = () => () => {
    return function (node, textRect) {
        const style = this.behaviours.getNodeShapeStyle(node)
        return shapes[style.shape](textRect, style)
    }
}
