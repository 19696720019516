import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/rmucs', data),

        publish: (data) => http.put(
            '/rmucs/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/rmucs/${pkid}/dps`),
        push: (data) => http.post('/rmucs/batchmodify', data),
        read: ({pkid}) => http.get(`/rmucs/${pkid}`),
        readList: (args) => http.get('/rmucs', args),

        revise: ({pkid}) => http.put(
            '/rmucs/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/rmucs/${data.pkid}`, data),
        updateMap: (data) => http.put(`/rmucs/${data.pkid}/map`, data),
    }
}
