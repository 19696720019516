import {Button} from 'antd'
import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import {MapContext, useMapContext} from '@/components/MindMap/index.mjs'
import useBizNode, {BizNodeContext} from '../useBizNode.mjs'

export default function MenuButton({children, items, ...props}) {
    const map = useMapContext()
    const BizNode = useBizNode()

    if (items) {
        if (0 < items.length) {
            const content = (
                <MapContext.Provider value={map}>
                    <BizNodeContext.Provider value={BizNode}>
                        <ContextMenu items={items} />
                    </BizNodeContext.Provider>
                </MapContext.Provider>
            )

            return (
                <PopoverTrigger
                    align="left"
                    content={content}
                    position="bottom"
                    {...props}
                >
                    <Button>{children}</Button>
                </PopoverTrigger>
            )
        }
        else {
            return null
        }
    }
    else {
        return <Button {...props}>{children}</Button>
    }
}
