export const canDiscardProduct = BizNode => () => {
    return function (node) {
        const {bizNodeType, pkid, stsCode} = node.data

        if (! pkid) {
            this.logger.error('只有制品才能废弃', [node])
            return false
        }

        if ('RLS' !== stsCode) {
            this.logger.error('只有发布状态才能废弃', [node])
            return false
        }

        const bn = BizNode[bizNodeType]

        if (! bn.discard) {
            this.logger.error(`${bn.name}不支持废弃`, [node])
            return false
        }

        return (
            ! node.parent ||
            bn.isMounted(this, node)
        )
    }
}

export const discardProduct = BizNode => () => {
    return function (nodes) {
        return Promise.allSettled(nodes.map(
            async (node) => {
                try {
                    const {bizNodeType} = node.data

                    const updates = await BizNode[bizNodeType].discard(
                        this, node
                    )

                    node.data = {...node.data, ...updates}
                    this.logger.info('废弃模型制品成功', [node])
                }
                catch (err) {
                    this.logger.error(
                        `废弃模型制品失败: ${err.message}`,
                        [node]
                    )

                    err.nodes = [node]
                    throw err
                }
            }
        ))
    }
}
