import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const map = useMapContext()
    map.useMetaData()

    if (isProductMap(map)) {
        return null
    }

    const {canPublishMap, publishMap} = map.commands

    return {
        disabled: ! canPublishMap(),
        key: 'publish-map',
        label: '发布',
        onClick: publishMap,
    }
}
