import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectDgf from './SelectDgf.jsx'

const PropertiesControllerRefCdgDf = ({disabled, readOnly,...props}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        isRefDf,
        refCdgCode,
        refCdgDfCode,
        refCdgDfId,
        refCdgDfName,
        refCdgDfUserCode,
        refCdgId,
        refCdgName,
        refCdgUserCode,
    } = values

    if ('1' !== isRefDf) {
        return null
    }

    const handleChange = (cdg) => {
        const {
            dfCode,
            dfId,
            dfName,
            dfUserCode,
            pcdgCode,
            pcdgUserCode,
            pcdgId,
            pcdgName,
            ...value
        } = cdg

        update({
            ...value,
            dfName,
            dfUserCode,
            refCdgCode: pcdgCode,
            refCdgDfCode: dfCode,
            refCdgDfId: dfId,
            refCdgDfName: dfName,
            refCdgDfUserCode: dfUserCode,
            refCdgId: pcdgId,
            refCdgName: pcdgName,
            refCdgUserCode: pcdgUserCode,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required('refCdgDfCode')}>
                {model.name('refCdgDfCode')}
            </Label>

            <SelectDgf
                disabled={disabled || model.disabled('refCdgDfCode')}
                isRefDf="0"
                pcdgId={refCdgId}
                readOnly={readOnly || gReadOnly}

                value={{
                    dfCode: refCdgDfCode,
                    dfName: refCdgDfName,
                    dfUserCode: refCdgDfUserCode,
                    pcdgCode: refCdgCode,
                    pcdgId: refCdgId,
                    pcdgName: refCdgName,
                    pcdgUserCode: refCdgUserCode,
                    pkid: refCdgDfId,
                }}

                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerRefCdgDf
