/* eslint-disable react/jsx-key */

import Color from '../Color.mjs'
import IconLetters from '../icons/IconLetters.jsx'

export default [
    [
        'COMMON',
        '通用',

        <IconLetters
            fill={Color.LIGHT_YELLOW}
            letters="R"
            textColor="#000"
        />
    ],

    [
        'IS',
        '信息结构',

        <IconLetters
            fill={Color.CYAN}
            letters="R"
            textColor="#000"
        />
    ],
]
