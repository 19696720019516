import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'bdNo',
    detailUrl: '/BdDetail',
    idProp: 'bdId',
    mapProp: 'bdMap',
    name: '业务对象',
    textProp: 'bdName',
    type: 'BD',
}
