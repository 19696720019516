import Color from '../Color.mjs'
import useArchCatNode from '../useArchCatNode.jsx'
import meta from './metaCatDb.mjs'

export default (BizNode) => {
    const ArchCatNode = useArchCatNode(BizNode, meta)

    return {
        ...ArchCatNode,
        ...meta,

        canMountType(map, node, type) {
            return /^(CAT|DB|MARK)$/.test(type)
        },

        getStyle(map, node) {
            return {
                ...ArchCatNode.getStyle.call(this, map, node),
                backgroundColor: Color.CYAN,
            }
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DB'],
            ]
        },
    }
}
