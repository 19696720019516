import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/ArchMiddlewareDetail',
    idProp: 'middlewareId',
    mapProp: 'map',
    name: '中间件',
    textProp: 'middlewareName',
    type: 'MID',
}
