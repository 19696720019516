import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import meta from './metaDtf.mjs'
import PropertiesPane from './PropertiesPaneDtf.jsx'
import Model from './ModelDtf.mjs'

export default (BizNode) => {
    const ComponentNode = useComponentNode(BizNode, meta, Model)

    return {
        ...ComponentNode,

        attrNodes: {
            bottom: ['ATTR_DF_DESC'],
        },

        getIcons(map, node) {
            return BizNode.DF.getIcons.call(this, map, node)
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.CYAN})
        },

        getTextSuffix(map, node) {
            const {
                dataTypeCode,
                dataTypeGrpCode,
                dfDtoUserCode,
                dfUserCode,
                pkid,
            } = node.data

            if (! pkid) {
                return ''
            }

            if (dataTypeGrpCode) {
                if (
                    /^(Basic|Other)$/.test(dataTypeGrpCode) &&
                    dataTypeCode
                ) {
                    return ` ${dataTypeCode} ${dfUserCode}`
                }
                else if (
                    'DTO' === dataTypeGrpCode &&
                    dfDtoUserCode
                ) {
                    return ` ${dfDtoUserCode} ${dfUserCode}`
                }
                else if (dataTypeCode || dfDtoUserCode) {
                    const type = dataTypeCode ?? dfDtoUserCode
                    return ` ${dataTypeGrpCode}<${type}> ${dfUserCode}`
                }
            }

            return ` ${dfUserCode}`
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onPush(map, node, type, data) {
            if ('DTO' !== type) {
                return
            }

            const pushData = this._getPushData(map, node)
            data.dtoDfList.push(pushData)
        },
    }
}
