import {useState} from 'react'
import TextArea from '@/components/Form/TextArea.jsx'
import SelectDfDialog from '@/pages/MainPage/dialogs/SelectDfDialog'
import {useMapContext} from '@/components/MindMap/index.mjs'

const defaultValue = {}

const SelectDf = ({
    className,
    style,
    disabled,
    readOnly,
    value = defaultValue,
    onChange,
    ...props
}) => {
    const map = useMapContext()
    const [visible, setVisible] = useState(false)

    const handleClickInput = () => {
        if (! readOnly) {
            setVisible(true)
        }
    }

    const {dfUserCode = '', dfName = '', dtName = ''} = value ?? {}
    const text = [dtName, dfName, dfUserCode].join('\n')

    return (
        <div
            className={className}
            style={style}
            onKeyDown={e => e.stopPropagation()}
        >
            <TextArea
                autoSize
                disabled={disabled}
                readOnly
                value={text}
                onClick={handleClickInput}
            />

            <SelectDfDialog
                style={{display: 'block'}}
                prjId={map.data.prjId}
                setVisible={setVisible}
                sVer={map.data.sVer}
                visible={visible}
                onCall={onChange}
                {...props}
            />
        </div>
    )
}

export default SelectDf
