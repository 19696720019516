import BaseMap from '../BaseMap.jsx'
import hotkeys from './hotkeys.mjs'
import useMapData from './useMapData.mjs'
import useMindMapPlugins from './useMindMapPlugins.mjs'
import usePermission from './usePermission.mjs'

const ProductMap = ({id, permission, type, ...props}) => {
    const initData = useMapData(id, type)

    const plugins = [
        useMindMapPlugins(),
        usePermission(permission)
    ]

    return (
        <BaseMap
            hotkeys={hotkeys}
            initData={initData}
            permission={permission}
            plugins={plugins}
            {...props}
        />
    )
}

export default ProductMap
