import {useMapContext} from '@/components/MindMap/index.mjs'
import useBizNode from '../../useBizNode.mjs'
import MenuButton from '../MenuButton.jsx'

export const useMenuItemsInsertProduct = () => {
    const map = useMapContext()
    map.usePermission()
    const selectedNodes = map.useSelectedNodes()
    const BizNode = useBizNode()
    const nodesCanInsert = map.commands.canInsertProduct(selectedNodes)

    if (0 === nodesCanInsert.length) {
        return []
    }

    const menuItems = nodesCanInsert.map(node => {
        const {bizNodeType} = node.data
        return BizNode[bizNodeType].menuItemsInsertProduct(map, node)
    })

    const itemTypes = menuItems.map(
        items => new Set(
            items.map(([type]) => type)
        )
    )

    let commonTypes = new Set(itemTypes[0])

    for (let i = 1; i < menuItems.length; i += 1) {
        commonTypes = commonTypes.intersection(itemTypes[i])
    }

    return menuItems[0]
        .filter(
            ([type]) => (
                BizNode[type].readList &&
                commonTypes.has(type)
            )
        )
        .map(
            ([type, label = BizNode[type].name]) => ({
                key: type,
                label,

                onClick: () => {
                    BizNode[type].onClickMenuItemInsertProduct(
                        map, nodesCanInsert
                    )
                },
            })
        )
}

export default function MenuButtonInsertProduct(props) {
    const items = useMenuItemsInsertProduct()

    if (0 === items.length) {
        return null
    }

    return (
        <MenuButton
            items={items}
            {...props}
        >
            插入模件链接节点
        </MenuButton>
    )
}
