import {css} from '@emotion/react'
import {Space} from 'antd'
import MenuButtonSaveMap from './MenuButtonSaveMap.jsx'

const cssMenu = css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
})

const Menu = (props) => {
    return (
        <Space
            css={cssMenu}
            {...props}
        >
            <MenuButtonSaveMap />
        </Space>
    )
}

export default Menu
