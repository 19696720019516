import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useModel} from '../../bizNodeModel.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import plFromDb from '../scripts/plFromDb.mjs'
import SelectDf from '../DF/SelectDf.jsx'

const PropertiesControllerDf = ({disabled, readOnly, ...props}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        dtCode,
        dtDfCode,
        dtDfName,
        dtDfUserCode,
        dtName,
        dtUserCode,
        isDtDf,
    } = values

    if ('1' !== isDtDf) {
        return null
    }

    const handleChange = (df) => {
        const {
            cdtDataTypeCode,
            dataTypeCode,
            dataTypeGrpCode,
            ddtDataTypeCode,
            dfCode,
            dfLen,
            dfName,
            dfPrecision,
            dfUserCode,
            dtCode,
            dtName,
            dtUserCode,
        } = df

        update({
            dataTypeGrpCode: 'Basic',

            dataTypeCode: plFromDb({
                cdtDataTypeCode,
                dataTypeCode,
                dataTypeGrpCode,
                ddtDataTypeCode,
                dfLen,
                dfPrecision,
            }),

            dtCode,
            dtDfCode: dfCode,
            dtDfName: dfName,
            dtDfUserCode: dfUserCode,
            dtName,
            dtUserCode,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required('dtDfCode')}>
                {model.name('dtDfCode')}
            </Label>

            <SelectDf
                disabled={disabled || model.disabled('dtDfCode')}
                readOnly={readOnly || gReadOnly}

                value={{
                    dfCode: dtDfCode,
                    dfName: dtDfName,
                    dfUserCode: dtDfUserCode,
                    dtCode,
                    dtName,
                    dtUserCode,
                }}

                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerDf
