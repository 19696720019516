import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaDepMid.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canLinkType(map, node, type) {
            return /^(MID)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.CAT.getInitData(map, node)
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['MID'],
            ]
        },
    }
}
