import {useBdt} from '@/biz/bizTable.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Number from '../../SidePanel/NodePanel/PropertiesControllerNumber.jsx'

export default function PropertiesControllerCdtPrecision(props) {
    const map = useMapContext()
    const {prjId} = map.data
    const {dict: Bdt} = useBdt({prjId})
    const [{bdtCode, cdtLen}] = useProperties()
    const max = cdtLen ? cdtLen - 1 : null
    const bdt = Bdt[bdtCode]
    const hidden = '1' !== bdt?.isPrecision
    const required = '1' === bdt?.isPrecision

    return (
        <Number
            hidden={hidden}
            int
            max={max}
            min={1}
            required={required}
            prop="cdtPrecision"
            {...props}
        />
    )
}
