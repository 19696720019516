import Select from '@/components/Form/Select.jsx'

const options = [
    ['Basic'],
    ['DTO'],
    ['List'],
    ['Other'],
    //['Qry'],
    //['QryList'],
    //['Array'],
    //['Map'],
    //['Set'],
]

const SelectBasicDataType = (props) => {
    return (
        <Select
            style={{display: 'block'}}
            options={options}
            {...props}
        />
    )
}

export default SelectBasicDataType
