import {css} from '@emotion/react'
import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectFm from '../FM/SelectFm.jsx'

const cssPath = css({
    fontSize: 13,
    color: '#666',
    marginTop: 4,
})

const PropertiesControllerFm = ({
    codeProp = 'fmCode',
    disabled,
    idProp = 'fmId',
    nameProp = 'fmName',
    pathProp = 'fmNamePath',
    readOnly,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        [codeProp]: fmCode,
        [idProp]: fmId,
        [nameProp]: fmName,
        [pathProp]: fmNamePath,
    } = values

    const handleChange = fm => {
        const {
            fmCode = '',
            fmId = '',
            fmName = '',
            fmNamePath = '',
        } = fm ?? {}

        update({
            [codeProp]: fmCode,
            [idProp]: fmId,
            [nameProp]: fmName,
            [pathProp]: fmNamePath,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectFm
                isLeaf={false}
                disabled={disabled || model.disabled('codeProp')}
                readOnly={readOnly || gReadOnly}
                value={{fmCode, fmId, fmName, fmNamePath}}
                onChange={handleChange}
            />

            <div css={cssPath}>{fmNamePath}</div>
        </div>
    )
}

export default PropertiesControllerFm
