export const canInsertProduct = () => () => {
    return function (node) {
        return this.actions.canInsertChild(node)
    }
}

export const insertProduct = BizNode => () => {
    return async function (nodes, type) {
        if (0 === nodes.length) {
            return
        }

        if (1 < nodes.length) {
            this.logger.warn('只能对单一节点插入制品，忽略除第一个节点外的其余节点')
        }

        const [node] = nodes
        const trees = await BizNode[type].chooseProduct(this, node)

        if (0 === trees.length) {
            return
        }

        const nodesToSelect = new Set()
        const newNodes = []

        for (const tree of trees) {
            const child = this.importTree(tree)
            this.appendChild(node, child)
            newNodes.push(child)
        }

        await Promise.all(
            newNodes.map(async n => {
                const {bizNodeType} = n.data
                await BizNode[bizNodeType].onInsert(this, n)

                if (! n.isDeleted) {
                    nodesToSelect.add(n)
                }
            })
        )

        this.logger.info('插入模件成功', [node])
        this.selectNodes(nodesToSelect)
    }
}
