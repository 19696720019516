import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: (data) => http.post('/bmsvrs', data),

        publish: (data) => http.put(
            '/bmsvrs/rlsandrevise',
            {...data, stsCode: 'RLS'}
        ),

        pull: ({pkid}) => http.get(`/bmsvrs/${pkid}/dps`),
        push: (data) => http.post('/bmsvrs/batchmodify', data),
        read: ({pkid}) => http.get(`/bmsvrs/${pkid}`),
        readList: (args) => http.get('/bmsvrs', args),

        revise: ({pkid}) => http.put(
            '/bmsvrs/rlsandrevise',
            {pkid, stsCode: 'REVISE'}
        ),

        update: (data) => http.put(`/bmsvrs/${data.pkid}`, data),

        updateMigrate: (data) => http.put(
            `/bmsvrs/${data.pkid}/migrate`,
            data
        ),

        updateMap: (data) => http.put(`/bmsvrs/${data.pkid}/map`, data),
    }
}
