import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/LsDetail',
    mapProp: 'lsMap',
    idProp: 'lsId',
    name: '外联系统',
    textProp: 'lsName',
    type: 'LS',
}
