import {useModel} from '../../bizNodeModel.mjs'
import {useReadOnly} from '@/components/Form/ReadOnly.mjs'
import {useProperties} from '../../SidePanel/NodePanel/Properties.mjs'
import Label from '../../SidePanel/NodePanel/PropertiesControllerLabel.jsx'
import SelectBm from '../BM/SelectBm.jsx'

const PropertiesControllerBm = ({
    codeProp = 'bmCode',
    disabled,
    idProp = 'bmId',
    nameProp = 'bmName',
    readOnly,
    ...props
}) => {
    const [values, update] = useProperties()
    const gReadOnly = useReadOnly()
    const Model = useModel()
    const model = new Model(values)

    const {
        [codeProp]: bmCode,
        [idProp]: bmId,
        [nameProp]: bmName,
    } = values

    const handleChange = bm => {
        const {
            bmCode = '',
            bmId = '',
            bmName = '',
        } = bm ?? {}

        update({
            [codeProp]: bmCode,
            [idProp]: bmId,
            [nameProp]: bmName,
        })
    }

    return (
        <div {...props}>
            <Label required={model.required(codeProp)}>
                {model.name(codeProp)}
            </Label>

            <SelectBm
                disabled={disabled || model.disabled('codeProp')}
                readOnly={readOnly || gReadOnly}
                value={{bmCode, bmId, bmName}}
                onChange={handleChange}
            />
        </div>
    )
}

export default PropertiesControllerBm
