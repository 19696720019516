export const onDoubleClickNode = BizNode => defaultBehaviours => {
    return async function (node, event) {
        event.stopPropagation()
        const {bizNodeType} = node.data
        await BizNode[bizNodeType].onDoubleClick(this, node, event)

        if (! event.defaultPrevented) {
            defaultBehaviours.onDoubleClickNode(node, event)
        }
    }
}
