import Color from '../Color.mjs'
import {deleteChildren} from '../../scripts/map.mjs'
import useProductNode from '../useProductNode.jsx'
import meta from './metaCdt.mjs'
import Model from './ModelCdt.mjs'
import PropertiesPane from './PropertiesPaneCdt.jsx'
import QueryForm from './QueryFormSelectCdt.jsx'
import Table from './TableSelectCdt.jsx'
import useApi from './useApiCdt.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return 'CDTSEQVAL' === type
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getDefaultChildData(map, node) {
            return BizNode.CDTSEQVAL.getInitData(map, node)
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {children: []})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.CYAN}),
                shape: 'EllipseRectangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['dmCdtSeqVals'])
        },

        menuInsertConcept() {
            return null
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },
    }
}
