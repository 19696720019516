import {publish} from '@/script/event.mjs'

export default class Task {
    constructor(doc) {
        this.#doc = doc
    }

    async execute(fn) {
        if (this.#isRunning) {
            try {
                await fn()
                publish(this.#doc, 'task_execute')
            }
            catch (err) {
                this.#isRunning = false
                this.#doc.transaction.rollback()
                throw err
            }
        }
        else {
            this.startTask()

            try {
                await fn()
                publish(this.#doc, 'task_execute')
            }
            catch (err) {
                this.#doc.transaction.rollback()
                throw err
            }
            finally {
                this.finishTask()
            }
        }
    }

    finishTask() {
        if (this.#isRunning) {
            this.#isRunning = false
            const commit = this.#doc.transaction.commit()
            publish(this.#doc, 'before_task_finish', commit)
            publish(this.#doc, 'task_finish', commit)
        }
        else {
            throw new Error('Task is not running.')
        }
    }

    startTask() {
        if (this.#isRunning) {
            throw new Error('Task is already running.')
        }
        else {
            publish(this.#doc, 'before_task_start')
            publish(this.#doc, 'task_start')
            this.#isRunning = true
        }
    }

    #doc
    #isRunning = false
}
