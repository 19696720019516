import {css} from '@emotion/react'
import MenuButtonNextDiff from './MenuButtonNextDiff.jsx'
import MenuButtonPrevDiff from './MenuButtonPrevDiff.jsx'
import MenuButtonCompareProduct from './MenuButtonCompareProduct.jsx'

const cssMenu = css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: 8,
})

export default function Menu(props) {
    return (
        <div
            css={cssMenu}
            {...props}
        >
            <MenuButtonPrevDiff />
            <MenuButtonNextDiff />
            <MenuButtonCompareProduct />
        </div>
    )
}
