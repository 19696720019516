import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'fcUserCode',
    detailUrl: '/FcDetail',
    idProp: 'fcId',
    mapProp: 'fcMap',
    name: '程序能力',
    textProp: 'fcName',
    type: 'FC',
}
