import buildUrl from '@/script/buildUrl.mjs'
import Tree from '@/script/Tree.mjs'
import findOwnerProduct from './scripts/findOwnerProduct.mjs'
import getMapPath from './scripts/getMapPath.mjs'
import {exportTree, replaceWithTree} from '../scripts/map.mjs'
import useDesignNode from './useDesignNode.jsx'

/**
 * 部件节点类型的基类
 *
 * 部件节点从属于制品节点
 */
export default (
    BizNode,

    {
        ownerIdProp,
        ownerTextProp,
        ownerType,
        ...config
    },

    Model,
    api
) => {
    const DesignNode = useDesignNode(BizNode, config, Model, api)

    return {
        ...DesignNode,
        isComponent: true,

        canGrow(map, node) {
            if (this.isMounted(map, node)) {
                map.logger.error('定义节点不能加载', [node])
                return false
            }

            if (! node.data[this.ownerIdProp]) {
                map.logger.error(
                    `缺少数据，不能加载: ${this.ownerIdProp}`,
                    [node]
                )

                return false
            }

            return DesignNode.canGrow.call(this, map, node)
        },

        canShrink(map, node) {
            if (this.isMounted(map, node)) {
                map.logger.error('定义节点不能卸载', [node])
                return false
            }

            if (! node.data[this.ownerIdProp]) {
                map.logger.error(
                    `缺少数据，不能卸载: ${this.ownerIdProp}`,
                    [node]
                )

                return false
            }

            return DesignNode.canGrow.call(this, map, node)
        },

        canWriteNode(map, node) {
            if (! DesignNode.canWriteNode.call(this, map, node)) {
                return false
            }

            const {
                bizNodeType,
                pkid,
                [this.ownerIdProp]: ownerId,
            } = node.data

            if (! BizNode[bizNodeType].isMounted(map, node)) {
                return false
            }

            if (! (pkid && ownerId)) {
                return true
            }

            const owner = findOwnerProduct(map, BizNode, node)

            if (! owner) {
                return false
            }

            return true
        },

        canWriteTree(map, node) {
            if (! DesignNode.canWriteTree.call(this, map, node)) {
                return false
            }

            const {
                bizNodeType,
                pkid,
                [this.ownerIdProp]: ownerId,
            } = node.data

            if (! BizNode[bizNodeType].isMounted(map, node)) {
                return false
            }

            if (! (pkid && ownerId)) {
                return true
            }

            const owner = findOwnerProduct(map, BizNode, node)

            if (! owner) {
                return false
            }

            return true
        },

        getRev(map, node) {
            if (this.isMounted(map, node)) {
                return null
            }
            else {
                return DesignNode.getRev.call(this, map, node)
            }
        },

        getTextPrefix(map, node) {
            const prefix = DesignNode.getTextPrefix.call(this, map, node)

            const {
                [this.ownerIdProp]: ownerId,
                [this.ownerTextProp]: ownerText,
                pkid,
            } = node.data

            if (! (pkid && ownerId && ownerText)) {
                return prefix
            }

            const owner = findOwnerProduct(map, BizNode, node)

            if (owner) {
                return prefix
            }
            else {
                return `【${ownerText}】`
            }
        },

        async getUrl(map, node, childPath = []) {
            if (! node.data.pkid) {
                return DesignNode.getUrl.call(this, map, node, childPath)
            }

            const owner = findOwnerProduct(map, BizNode, node)

            if (owner) {
                return DesignNode.getUrl.call(this, map, node, childPath)
            }

            const mapPath = await getMapPath(map, BizNode, node)

            if (! mapPath) {
                return DesignNode.getUrl.call(this, map, node)
            }

            const {
                [this.ownerIdProp]: ownerId = '',
                [this.ownerTextProp]: ownerText = '',
            } = node.data

            return buildUrl(
                BizNode[this.ownerType].detailUrl,

                {
                    mapPath,
                    [this.ownerIdProp]: ownerId,
                    [this.ownerTextProp]: ownerText,
                },
            )
        },

        async grow(map, node, growedNodes) {
            if (! this.canGrow(map, node)) {
                return
            }

            if (growedNodes) {
                const {pkid, [this.ownerIdProp]: ownerId} = node.data

                if (growedNodes.has(ownerId) && node.firstChild) {
                    await this._growChildren(map, node, growedNodes)
                    return
                }

                if (growedNodes.has(pkid)) {
                    map.logger.warn(
                        '为避免无限加载，跳过已加载过的节点',
                        [node]
                    )

                    return
                }
            }

            const rev = this.getRev(map, node)
            const tree = await this.readTree(map, node, rev)

            if (tree) {
                const {lastRev, rev, sVer} = node.data
                replaceWithTree(map, node, tree)
                node.isFolded = 0 < growedNodes?.size

                if (0 < rev) {
                    node.data = {...node.data, lastRev, rev, sVer}
                }

                for (const n of map.walkDown(node, {excludeTarget: true})) {
                    const {bizNodeType} = n
                    BizNode[bizNodeType].onPull(map, n)
                }

                await this._growChildren(map, node, growedNodes)
            }
            else {
                const msg = `加载失败，可能是因为缺少数据 ${this.ownerIdProp}，或部件已被其制品删除`

                if (0 < growedNodes?.size) {
                    map.logger.warn(msg, [node])
                }
                else {
                    throw new Error(msg)
                }
            }
        },

        async jumpToDiff(map, node, leftRev, rightRev) {
            const {
                bizNodeType,
                pkid,
                [this.ownerTextProp]: ownerText,
            } = node.data

            const {name} = BizNode[this.ownerType]

            const url = buildUrl(
                '/DiffMap',

                {
                    id: pkid,
                    leftRev,
                    rightRev,
                    title: `${name}【${ownerText}】`,
                    type: bizNodeType,
                }
            )

            window.open(url)
        },

        menuInsertConcept(map, nodes) {
            return this._menuInsertConcept(map, nodes)
        },

        async onAttachTo(map, node) {
            // 必要时转为概念
            to_concept: {
                const {bizNodeType} = node.data

                if (
                    ! node.data.pkid ||
                    BizNode[bizNodeType].isLinked(map, node)
                ) {
                    break to_concept
                }

                const owner = findOwnerProduct(map, BizNode, node)

                if (! owner) {
                    this._toConcept(map, node)
                    break to_concept
                }

                const next = (chain) => {
                    const [node] = chain
                    const {bizNodeType: t} = node.data
                    const isLinked = BizNode[t].isLinked(map, node)

                    return {
                        yieldChildren: ! isLinked,
                        yieldNode: ! isLinked,
                    }
                }

                // 查找制品下的其他部件
                for (
                    const n of
                    map.walkDown(owner, {excludeTarget: true, next})
                ) {
                    // 相同业务 ID 的部件已存在
                    if (
                        n !== node &&
                        n.data.pkid === node.data.pkid &&
                        // PK 和 DF 使用相同的 pkid
                        n.data.bizNodeType === bizNodeType
                    ) {
                        this._toConcept(map, node)
                        break
                    }
                }
            }

            await DesignNode.onAttachTo.call(this, map, node)
        },

        async onDoubleClick(map, node, event) {
            const {
                lastRev: _1,
                pkid,
                rev: _2,
                [this.ownerIdProp]: ownerId,
            } = node.data

            if (! pkid) {
                DesignNode.onDoubleClick.call(this, map, node, event)
                return
            }

            const jumpToProduct = async () => {
                event.preventDefault()

                if (! ownerId) {
                    return
                }

                const rev = this.getRev(map, node)

                if (
                    this.isOutdated(map, node) &&
                    0 < rev
                ) {
                    this.jumpToDiff(map, node, rev)
                }
                else {
                    const q = JSON.stringify({
                        isMounted: true,
                        pkid,
                    })

                    const url = buildUrl(
                        BizNode[this.ownerType].detailUrl,

                        {
                            q,
                            [this.ownerIdProp]: ownerId
                        },
                    )

                    window.open(url)
                }
            }

            if (/^(ANALYSE|IS)_MAP$/.test(map.data.mapTypeCode)) {
                jumpToProduct()
            }
            else {
                const owner = findOwnerProduct(map, BizNode, node)

                if (owner && ! owner.parent) {
                    const next = (chain) => {
                        const node = chain[0]
                        const {bizNodeType} = node.data
                        const bn = BizNode[bizNodeType]
                        const yieldChildren = bn.isMounted(map, node)
                        const yieldNode = bn.isMounted(map, node)
                        return {yieldChildren, yieldNode}
                    }

                    for (
                        const n of
                        map.walkDown(owner, {excludeTarget: true, next})
                    ) {
                        if (n.data.pkid === pkid) {
                            map.execute(() => map.selectNodes([n]))
                            break
                        }
                    }
                }
                else {
                    jumpToProduct()
                }
            }
        },

        onPull(map, node) {
            DesignNode.onPull.call(this, map, node)

            if (! this.attrNodes) {
                return
            }

            const owner = findOwnerProduct(map, BizNode, node)

            if (owner && ! owner.parent) {
                this._initAttrNodes(map, node)
            }
        },

        ownerIdProp,
        ownerTextProp,
        ownerType,

        async readTree(map, node, rev) {
            const ownerTree = await this._readOwnerTree(map, node, rev)

            if (! ownerTree) {
                return null
            }

            const next = (chain) => {
                const node = chain[0]
                const {bizNodeType} = node.data
                const bn = BizNode[bizNodeType]
                const yieldChildren = bn.isMounted(ownerTree, node)
                const yieldNode = bn.isMounted(ownerTree, node)
                return {yieldChildren, yieldNode}
            }

            const {pkid} = node.data

            for (
                const n of
                ownerTree.walkDown(
                    ownerTree.root,
                    {excludeTarget: true, next}
                )
            ) {
                if (n.data.pkid === pkid) {
                    const {
                        prjId,
                        prjNo,
                        rev: rev2,
                        sVer,
                    } = ownerTree.root.data

                    n.data = {
                        ...n.data,
                        prjId,
                        prjNo,
                        rev: (! rev || 0 === rev) ? rev2 : rev,
                        sVer,
                    }

                    return exportTree(ownerTree, n)
                }
            }

            return null
        },

        async upgrade(map, node) {
            const {bizNodeType, pkid} = node.data

            const {
                ownerIdProp,
                ownerTextProp,
                ownerType,
            } = BizNode[bizNodeType]

            const {[ownerIdProp]: ownerId} = node.data
            const {textProp} = BizNode[ownerType]

            const tree = new Tree(
                await BizNode[ownerType].readTree(map, {pkid: ownerId})
            )

            const {
                stsCode,
                sVer,
                [textProp]: ownerText,
            } = tree.root.data

            if (! /^(RLS|REVISE)$/.test(stsCode)) {
                throw new Error('制品不是发布/修订状态，不能升级')
            }

            const next = (chain) => {
                const node = chain[0]
                const {bizNodeType} = node.data
                const bn = BizNode[bizNodeType]
                const yieldChildren = bn.isMounted(tree, node)
                const yieldNode = bn.isMounted(tree, node)
                return {yieldChildren, yieldNode}
            }

            for (
                const n of
                tree.walkDown(tree.root, {excludeTarget: true, next})
            ) {
                if (pkid === n.data.pkid) {
                    node.data = {
                        ...n.data,
                        sVer,
                        [ownerTextProp]: ownerText,
                    }

                    return
                }
            }

            throw new Error('未找到部件')
        },

        async _readOwnerTree(map, node, rev) {
            const {[this.ownerIdProp]: ownerId} = node.data

            if (! ownerId) {
                return null
            }

            const lastestTreeData = await BizNode[this.ownerType].readTree(
                map, {pkid: ownerId}
            )

            const {stsCode} = lastestTreeData.data

            const treeData = 'RLS' === stsCode ?
                await BizNode[this.ownerType].readTree(
                    map, {pkid: ownerId, rev}
                )
                :
                lastestTreeData

            return new Tree(treeData)
        },
    }
}
