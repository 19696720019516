import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        create: async ({
            mapName,
            mapNo,
            mapTypeCode,
            prjId,
            rNodeDataId,
        }) => {
            const {
                mapStsCode,
                mapStsName,
                mapVer,
                pkid,
            } = await http.post('/basmaps', {
                mapName,
                mapNo,
                mapTypeCode,
                prjId,
                rNodeDataId,
            })

            return {
                pkid,
                mapStsCode,
                mapStsName,
                mapVer,
            }
        },

        publish: (args) => http.put(
            '/basmaps/rlsandrevise',
            {...args, stsCode: 'RLS'}
        ),

        read: async ({pkid}) => {
            const {
                mapModel,
                ...data
            } = await http.get(`/basmaps/${pkid}`)

            return {
                ...data,
                mapModel: JSON.parse(mapModel),
            }
        },

        revise: (args) => http.put(
            '/basmaps/rlsandrevise',
            {...args, stsCode: 'REVISE'}
        ),

        save: async ({mapModel, pkid, ...data}) => {
            const {
                mapModel: _,
                ...newData
            } = await http.put(
                `/basmaps/${pkid}`,

                {
                    ...data,
                    mapModel: JSON.stringify(mapModel),
                    pkid,
                },
            )

            return newData
        },
    }
}
