import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    bmCode: {
        name: () => '业务域',
    },

    bfDesc: {
        name: () => '描述',
    },

    bfMemo: {
        name: () => '备注',
    },

    bfName: {
        name: () => '流程名称',
        required: () => true,
    },

    bfTypeCode: {
        disabled: ({pkid}) => pkid,
        name: () => '流程类型',
        required: () => true,
    },

    bfNo: {
        name: () => '流程代码',
        required: () => true,
    },
})
