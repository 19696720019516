export function canDeleteNode(node) {
    if (this.root === node) {
        this.logger.error('不能删除根节点', [node])
        return false
    }
    else {
        return true
    }
}

export function deleteNode(nodes) {
    const nodesToDelete = new Set(nodes)
    const nodesToSelect = new Set()

    for (const node of nodes) {
        (() => {
            {
                const isNodeHidden = n => this.behaviours.isNodeHidden(n)

                const children = [...this.children(node)].filter(
                    child => ! (
                        nodesToDelete.has(child) ||
                        isNodeHidden(child)
                    )
                )

                if (0 < children.length) {
                    for (const child of children) {
                        nodesToSelect.add(child)
                    }

                    return
                }
            }

            if (! node.parent) {
                return
            }

            {
                const isNodeHidden = n => this.behaviours.isNodeHidden(n)
                let cursor = node

                while (cursor.nextSibling) {
                    cursor = cursor.nextSibling

                    if (! (
                        nodesToDelete.has(cursor) ||
                        isNodeHidden(cursor)
                    )) {
                        nodesToSelect.add(cursor)
                        return
                    }
                }

                cursor = node.parent.firstChild

                while (cursor !== node) {
                    if (! (
                        nodesToDelete.has(cursor) ||
                        isNodeHidden(cursor)
                    )) {
                        nodesToSelect.add(cursor)
                        return
                    }

                    cursor = cursor.nextSibling
                }
            }

            nodesToSelect.add(node.parent)
        })()

        this.deleteNode(node)
        this.logger.info('已删除节点', [node])
    }

    this.selectNodes(nodesToSelect)
}
