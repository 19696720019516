import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaBdRule.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(BDRULE|CAT)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.BDRULE.getInitData(map, node)
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '3' === bdType
        },
    }
}
