import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'

const TableSelectBfdataflow = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '业务流程名称',
            dataIndex: 'bfName',
            component: <Table.ViewText />,
        },

        {
            title: '业务流程代码',
            dataIndex: 'bfNo',
            component: <Table.ViewText />,
        },

        {
            title: '能力标题',
            dataIndex: 'algTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/bfdataflows"
            isComponent
            query={query}
            {...props}
        />
    )
}

export default TableSelectBfdataflow
