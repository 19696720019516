import InputText from '@/components/Form/InputText.jsx'
import Input from './PropertiesControllerInput.jsx'

const PropertiesControllerText = ({
    className,
    style,
    disabled,
    hidden,
    labelSuffix,
    prop,
    readOnly,
    required,
    title,
    value,
    ...props
}) => {
    return (
        <Input
            className={className}
            style={style}
            disabled={disabled}
            hidden={hidden}
            labelSuffix={labelSuffix}
            prop={prop}
            readOnly={readOnly}
            required={required}
            title={title}
            value={value}
        >
            <InputText
                changeDelay={null}
                {...props}
            />
        </Input>
    )
}

export default PropertiesControllerText
