import {css} from '@emotion/react'

const cssLabelWrapper = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
})

const cssLabel = css({
    '&[data-required="1"]::before': {
        content: '"*"',
        color: '#ff4d4f',
        marginRight: 4,
    }
})

const PropertiesControllerLabel = ({
    children,
    required,
    suffix,
    ...props
}) => {
    return (
        <div css={cssLabelWrapper}>
            <div
                css={cssLabel}
                data-required={required ? '1' : '0'}
                {...props}
            >
                {children}
            </div>

            {suffix}
        </div>
    )
}

export default PropertiesControllerLabel
