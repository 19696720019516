import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {fromProductMap} from '../../compatibility.mjs'
import SelectDptDialog from '@/pages/MainPage/dialogs/SelectDptDialog.jsx'
import useBizNode from '../../useBizNode.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const openModal = useOpenModal()
    const map = useMapContext()
    const permission = map.usePermission()
    const BizNode = useBizNode()

    if (
        ! isProductMap(map) ||
        ! permission.has('write')
    ) {
        return null
    }

    const onClick = async () => {
        const json = await openModal(
            <SelectDptDialog
                dpType={map.root.data.bizNodeType}
                sfTypeCode={map.root.data.sfTypeCode}
                setVisible={() => {}}
            />
        )

        if (! json) {
            return
        }

        const template = fromProductMap(BizNode, JSON.parse(json))

        await map.cmd(() => {
            for (const child of [...map.children(map.root)]) {
                map.deleteTree(child)
            }

            for (const child of template.root.children) {
                const childNode = map.importTree(child)
                map.appendChild(map.root, childNode)
            }
        })
    }

    return {
        key: 'import-template',
        label: '导入模板',
        onClick,
    }
}
