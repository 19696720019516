import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaFcParam.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(FC_RE[PQ])$/.test(type)
        },
    }
}
