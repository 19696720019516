import {FontColorsOutlined, MenuOutlined} from '@ant-design/icons'
import SidePanel from '@/components/TreeDoc/SidePanel.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import MapPane from './MapPane.jsx'

const predefinedPaneConfigs = {
    properties: {
        hotkey: 'Ctrl+Shift+P',
        icon: <MenuOutlined />,
        name: '属性',
    },

    style: {
        hotkey: 'Ctrl+Shift+Y',
        icon: <FontColorsOutlined />,
        name: '样式',
    },
}

export default function NodePanel(props) {
    const map = useMapContext()
    const selectedNodes = [...map.useSelectedNodes()]

    const panes = (() => {
        // 没有选中任何节点
        if (0 === selectedNodes.length) {
            return [
                {
                    hotkey: 'Ctrl+Shift+P',
                    icon: <MenuOutlined />,
                    key: 'properties',
                    name: '属性',
                    pane: <MapPane />,
                },

                // [
                //     'MapStyle',
                //     '样式',
                //
                //     <MapStylePanel
                //         update={map.updateCanvas}
                //         values={map.canvas}
                //     />
                // ]
            ]
        }
        // 只选中一个节点
        else if (1 === selectedNodes.length) {
            const node = selectedNodes[0]
            const {bizNodeType} = node.data
            const {nodePanes} = map.BizNode[bizNodeType]

            return Object.entries(nodePanes)
                .map(([key, config]) => {
                    const {
                        hotkey: defaultHotkey,
                        icon: defaultIcon,
                        name: defaultName,
                    } = predefinedPaneConfigs[key] ?? {}

                    const {
                        components: [Pane],
                        hotkey = defaultHotkey,
                        icon = defaultIcon,
                        name = defaultName,
                    } = config

                    if (Pane) {
                        return {
                            hotkey,
                            icon,
                            key,
                            name,
                            pane: <Pane nodes={selectedNodes} />,
                        }
                    }
                    else {
                        return null
                    }
                })
                .filter(a => a)
        }
        // 选中多个节点
        else {
            const type = (() => {
                let type = null

                for (const node of selectedNodes) {
                    const {bizNodeType} = node.data

                    if (! type) {
                        type = bizNodeType
                    }

                    if (type !== bizNodeType) {
                        return null
                    }
                }

                return type
            })()

            if (! type) {
                return []
            }

            const {nodePanes} = map.BizNode[type]

            return Object.entries(nodePanes)
                .map(([key, config]) => {
                    const {
                        hotkey: defaultHotkey,
                        icon: defaultIcon,
                        name: defaultName,
                    } = predefinedPaneConfigs[key] ?? {}

                    const {
                        components: [, Pane],
                        hotkey = defaultHotkey,
                        icon = defaultIcon,
                        name = defaultName,
                    } = config

                    if (Pane) {
                        return {
                            hotkey,
                            icon,
                            key,
                            name,
                            pane: <Pane nodes={selectedNodes} />,
                        }
                    }
                    else {
                        return null
                    }
                })
                .filter(a => a)
        }
    })()

    return (
        <SidePanel
            direction="rtl"
            panes={panes}
            {...props}
        />
    )
}
