import useBizNode from '../useBizNode.mjs'

export default moduleMap => () => {
    const BizNode = useBizNode()

    const extEntries = Object.entries(moduleMap).map(
        ([key, modules]) => [
            key,

            Object.fromEntries(
                Object.values(modules)
                    .map(module => Object.entries(module).map(
                        ([name, useExt]) => [name, useExt(BizNode)]
                    ))
                    .flat()
            )
        ]
    )

    const extensions = (map) => Object.fromEntries(
        extEntries.map(([key, ext]) => {
            const defaults = map[key]

            return [
                key,

                {
                    ...defaults,

                    ...Object.fromEntries(Object.entries(ext).map(
                        ([name, init]) => [name, init(defaults).bind(map)]
                    ))
                }
            ]
        })
    )

    return {extensions}
}
