import Color from '../Color.mjs'
import useAlgNode from '../_ALG/useAlgNode.jsx'
import meta from './metaUialg.mjs'
import PropertiesPane from './PropertiesPaneUialg.jsx'

export default (BizNode) => {
    const AlgNode = useAlgNode(BizNode, meta)

    return {
        ...AlgNode,

        canMountType(map, node, type) {
            return /^(ALG_(API|CORE|RULE))$/.test(type)
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.LIGHT_BLUE}),
                shape: 'HorizontalHexagon',
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onCreate(map, node) {
            const childTypes = [
                'ALG_RULE',
                'ALG_CORE',
                'ALG_API',
            ]

            for (const type of childTypes) {
                const data = BizNode[type].getInitData(map, node)
                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            AlgNode.onCreate.call(this, map, node)
        },

        onPush(map, node, type, data) {
            const key = {
                UI: 'cAlgList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node, {})
            data[key].push(pushData)
        },
    }
}
