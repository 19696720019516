import useArCatNode from '../_AR_CAT/useArCatNode.jsx'

const textProp = 'text'

export default (BizNode, config) => {
    const ArCatNode = useArCatNode(BizNode, {textProp, ...config})

    return {
        ...ArCatNode,

        canLinkType(map, node, type) {
            return BizNode.AR.canLinkType.call(this, map, node, type)
        },

        canMountType(map, node, type) {
            return /^(AR|CAT|DOC|PIC|URL)$/.test(type)
        },

        menuItemsInsertProduct(map, node) {
            return BizNode.AR.menuItemsInsertProduct.call(this, map, node)
        },
    }
}
