import {Button} from 'antd'
import http from '@/biz/http.mjs'
import useAlert from '@/biz/useAlert.jsx'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonExportModel({type, ...props}) {
    const alert = useAlert()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (1 !== selectedNodes.size) {
        return null
    }

    const name = {
        bm: '业务',
        fm: '功能',
    }[type]

    const handleClick = async () => {
        const [node] = [...selectedNodes]
        const codeProp = `${type}Code`
        const {[codeProp]: code, prjId, sVer} = node.data

        const url = {
            bm: '/prd/export/sa/bm',
            fm: '/prd/export/sa/sf',
        }[type]

        await runAsync(
            () => http.post(url, {[codeProp]: code, prjId, sVer}),
            {action: `导出${name}模型`}
        )

        alert.generatingFile()
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导出{name}模型
        </Button>
    )
}
