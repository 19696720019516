import {ComponentPropertiesPane} from '../components/NodePropertiesPane.jsx'
import Check from '../../SidePanel/NodePanel/PropertiesControllerCheck.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ButtonMap from '../components/PropertiesPaneButtonMap.jsx'
import ButtonCode from './PropertiesPaneButtonCode.jsx'
import ButtonExportXml from './PropertiesPaneButtonExportXml.jsx'
import Model from './ModelVm.mjs'

const PropertiesPaneBm = (props) => (
    <ComponentPropertiesPane
        buttons={
            <>
                <ButtonMap />
                <ButtonCode />
                <ButtonExportXml nodes={props.nodes} />
            </>
        }

        controllers={
            <>
                <Text prop="vmUserCode" />
                <Text prop="vmName" />
                <Text prop="vmPack" />
                <Check prop="isClass" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneBm
