import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaAlgCore.mjs'

export default (BizNode) => {
    const CategroyNode = useCategoryNode(BizNode, meta)

    return {
        ...CategroyNode,

        canMountType(map, node, type) {
            return /^(ALG_(INPUT|OUTPUT|SUMMARY))$/.test(type)
        },

        isHidden(map, node) {
            return /^(SF)_LVL$/.test(map.data.project?.desgAcc)
        },

        onCreate(map, node) {
            const childTypes = [
                'ALG_INPUT',
                'ALG_OUTPUT',
                'ALG_SUMMARY',
            ]

            for (const type of childTypes) {
                const data = BizNode[type].getInitData(map, node)
                const child = map.importTree({data})
                map.appendChild(node, child)
            }

            CategroyNode.onCreate.call(this, map, node)
        },
    }
}
