import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdRelation.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        getInitData(map, parent) {
            return {
                ...ArCatNode.getInitData.call(this, map, parent),
                algType: 'RELATION',
            }
        },

        isHidden(map, node) {
            const {bdType} = node.parent?.data ?? {}
            return '2' === bdType
        },

        onPush(map, node, type, data) {
            if ('BD' === type) {
                data.algList.push(this._getPushData(map, node))
            }
        },
    }
}
