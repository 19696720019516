import {memo} from 'react'
import {css} from '@emotion/react'
import {useMapContext} from '@/components/MindMap/index.mjs'

import NodeDeliveryVersionText from './NodeDeliveryVersionText.jsx'
import NodeDesc from './NodeDesc.jsx'
import NodeIcons from './NodeIcons.jsx'
import NodeProjectText from './NodeProjectText.jsx'
import NodeTextCore from './NodeTextCore.jsx'
import NodeVersionText from './NodeVersionText.jsx'

const cssWrapper = css({
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre',
})

const cssBizText = css({
    minHeight: '1em',
})

const NodeText = ({node, ...props}) => {
    const map = useMapContext()
    map.useNodeChange(node, ['data'])
    const text = map.behaviours.getNodeText(node)
    const prefix = map.behaviours.getNodeTextPrefix(node)
    const suffix = map.behaviours.getNodeTextSuffix(node)
    const nodeIconsMarginRight = text || prefix || suffix ? '.5em' : 0

    return (
        <div
            css={cssWrapper}
            {...props}
        >
            <NodeIcons
                style={{marginRight: nodeIconsMarginRight}}
                node={node}
            />

            <div css={cssBizText}>
                <NodeDesc node={node} />
                <NodeTextCore node={node} />
            </div>

            <NodeVersionText node={node} />
            <NodeDeliveryVersionText node={node} />
            <NodeProjectText node={node} />
        </div>
    )
}

const MemorizedNodeText = memo(NodeText, () => true)
MemorizedNodeText.displayName = 'NodeText'

export default MemorizedNodeText
