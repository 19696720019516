import {css} from '@emotion/react'
import RadioGroup from '@/components/Form/RadioGroup.jsx'
import Input from './PropertiesControllerInput.jsx'

const cssRadioGroup = css({
    '&>label': {
        verticalAlign: 'top',
    }
})

const PropertiesControllerRadioGroup = ({
    className,
    style,
    disabled,
    hidden,
    labelSuffix,
    prop,
    required,
    title,
    value,
    ...props
}) => {
    return (
        <Input
            className={className}
            style={style}
            disabled={disabled}
            hidden={hidden}
            labelSuffix={labelSuffix}
            prop={prop}
            required={required}
            title={title}
            value={value}
        >
            <RadioGroup
                css={cssRadioGroup}
                {...props}
            />
        </Input>
    )
}

export default PropertiesControllerRadioGroup
