import useAbstractNode from '../useAbstractNode.jsx'
import meta from './metaPhys_.mjs'
import QueryForm from '../PHYS/QueryFormSelectPhys.jsx'
import Table from '../PHYS/TableSelectPhys.jsx'
import useApi from './useApiPhys_.mjs'

export default (BizNode) => {
    const api = useApi()
    const AbstractNode = useAbstractNode(BizNode, meta, api)

    return {
        ...AbstractNode,

        async chooseProduct(map, node) {
            const getQuery = (map, node, query) => {
                const {prjId} = map.data
                return {prjId, ...query}
            }

            const items = await this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )

            return items.map(e => ({
                ...e,
                data: {...e.data, bizNodeType: 'PHYS'},
            }))
        },
    }
}
