import Table from '@/components/Table/Table.jsx'
import TableSelectDesignItems from '../components/TableSelectDesignItems.jsx'

const TableSelectCdg = ({fetch, fetchArgs, query, ...props}) => {
    const columns = [
        {
            title: '数据域',
            dataIndex: 'dmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '字段组代码',
            dataIndex: 'pcdgUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '字段组名称',
            dataIndex: 'pcdgName',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableSelectDesignItems
            columns={columns}
            fetchArgs={fetchArgs}
            fetch={fetch}
            fetchId="/cdgs"
            query={query}
            {...props}
        />
    )
}

export default TableSelectCdg
