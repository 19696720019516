import {useEffect, useState} from 'react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getExternalNodes = (map) => {
    const {prjId} = map.data

    const next = (chain) => {
        const [node] = chain
        const {prjId: nPrjId} = node.data
        const isExternal = Boolean(nPrjId) && nPrjId !== prjId
        const isHidden = map.behaviours.isNodeHidden(node)

        return {
            yieldChildren: ! isHidden,
            yieldNode: ! isHidden && isExternal,
        }
    }

    return [...map.walkDown(map.root, {excludeTarget: true, next})]
}

export default () => {
    const map = useMapContext()
    const [nodes, setNodes] = useState(() => getExternalNodes(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                setNodes(getExternalNodes(map))
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return nodes
}
