import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/NodePanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ButtonSaveProduct from '../components/PropertiesPaneButtonSaveProduct.jsx'
import Model from './ModelLs.mjs'

const PropertiesPaneLs = (props) => (
    <ProductPropertiesPane
        buttons={<ButtonSaveProduct />}

        controllers={
            <>
                <Text prop="lsName" />
                <Text prop="infDomainName" />
                <Text prop="infIp" />
                <LongText prop="memo" />
            </>
        }

        Model={Model}
        {...props}
    />
)

export default PropertiesPaneLs
