import Color from '../Color.mjs'
import useComponentNode from '../useComponentNode.jsx'
import usePropertiesModal from '../hooks/usePropertiesModal.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import IconStar from '../icons/IconStar.jsx'
import * as defaultMeta from './metaProgNode.mjs'
import Model from './ModelProg.mjs'
import PropertiesPane from './PropertiesPaneProg.jsx'
import ModalProperties from './ModalPropertiesProg.jsx'

export default (BizNode, meta) => {
    const openPropertiesModal = usePropertiesModal(ModalProperties)

    const ComponentNode = useComponentNode(
        BizNode,
        {...defaultMeta, ...meta},
        Model
    )

    return {
        ...ComponentNode,

        getInitData(map, parent) {
            return {
                ...ComponentNode.getInitData.call(this, map, parent),
                progTypeCode: 'FC',
                [this.textProp]: '',
            }
        },

        getIcons(map, node) {
            const icons = []
            const {fcCode, progTypeCode} = node.data

            if ('FC' === progTypeCode) {
                icons.push(
                    <IconLetters
                        fill={Color.GREEN_YELLOW}
                        letters="A"
                        textColor="#666"
                    />
                )

                if (fcCode) {
                    icons.push(<IconStar fill={Color.RED} />)
                }
                else {
                    icons.push(<IconStar fill={Color.GREY} />)
                }
            }

            return icons
        },

        getPushData(map, node) {
            const d = {
                ...this._getPushData(map, node),
                fcCode: '',
                ssCode: '',
            }

            for (const child of map.children(node)) {
                const {bizNodeType, fcCode, ssCode} = child.data

                if ('FC' === bizNodeType) {
                    Object.assign(d, {fcCode, ssCode})
                    break
                }
            }

            return d
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.GREEN_YELLOW
                }),

                shape: 'Parallelogram'
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onDoubleClick(map, node, event) {
            event.preventDefault()
            openPropertiesModal(map, BizNode, node, Model)
        },
    }
}
