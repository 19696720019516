import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    memo: {
        name: () => '备注',
    },

    eventTitle: {
        name: () => '事件标题',
        required: () => true,
    },

    eventUserCode: {
        name: () => '事件代码',
    },
})
