import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    detailUrl: '/DepVsDetail',
    idProp: 'vsId',
    mapProp: 'map',
    name: '虚拟机',
    textProp: 'vsName',
    type: 'VR',
}
