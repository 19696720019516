export const canShrinkNode = BizNode => () => {
    return function (node) {
        const {bizNodeType} = node.data
        return BizNode[bizNodeType].canShrink(this, node)
    }
}

export const shrinkNode = BizNode => () => {
    return function (nodes) {
        for (const node of nodes) {
            const {bizNodeType} = node.data
            BizNode[bizNodeType].shrink(this, node)
            this.logger.info('已卸载节点', [node])
        }
    }
}
