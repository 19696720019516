import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaLsiOutput.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        onPush(map, node, type, data) {
            if ('LSI' === type) {
                data.outputAlgList.push(this._getPushData(map, node))
            }
        },
    }
}
