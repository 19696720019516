import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'dtoUserCode',
    detailUrl: '/DtoDetail',
    idProp: 'dtoId',
    mapProp: 'dtoMap',
    name: 'DTO',
    textProp: 'dtoName',
    type: 'DTO',
}
