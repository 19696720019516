import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import meta from './metaKm.mjs'
import Model from './ModelKm.mjs'
import useApi from './useApiKm.mjs'
import PropertiesPane from './PropertiesPaneKm.jsx'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(AR|CAT|DOC|PIC|URL)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.AR.getInitData(map, node)
        },

        getStyle(map, node) {
            return {
                ...ProductNode.getStyle.call(this, map, node),
                backgroundColor: Color.LIGHT_GREY,
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        onAttached(map, node, child) {
            BizNode.AR.onAttached(map, node, child)
        },
    }
}
