import useApiMap from '../../../useApiMap.mjs'

export const canReviseMap = () => () => {
    return function () {
        if ('RLS' !== this.data.mapStsCode) {
            this.logger.error('只有发布状态的地图才能修订')
            return false
        }

        if (! this.permission.has('revise')) {
            this.logger.error('没有修订权限')
            return false
        }

        return true
    }
}

export const reviseMap = () => {
    const apiMap = useApiMap()

    return () => function () {
        return this.runAction(
            async () => {
                const {pkid} = this.data
                const updates = await apiMap.revise({pkid})
                this.data = {...this.data, ...updates}
            },

            {
                action: '修订地图',
                success: {show: true},
            },
        )
    }
}
