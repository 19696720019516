import useHTTP from '@/hooks/useHTTP.mjs'

export default () => {
    const http = useHTTP()

    return {
        readList: (args) => http.get(
            '/dmbds/algs',

            {
                algTypes: ['BASE', 'DEVELOP'],
                ...args
            },
        ),
    }
}
