import superMeta from '../_ALG/metaAlgNode.mjs'

export default {
    ...superMeta,
    algType: 'DEVELOP',
    name: '业务事务能力',
    ownerIdProp: 'bdId',
    ownerTextProp: 'bdName',
    ownerType: 'BD',
    type: 'BDALG',
}
