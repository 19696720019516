import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaLsiInput.mjs'

export default (BizNode) => {
    const ArCatNode = useArCatNode(BizNode, meta)

    return {
        ...ArCatNode,

        onPull(map, node) {
            ArCatNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if ('LSI' === type) {
                data.inputAlgList.push(this._getPushData(map, node))
            }
        },
    }
}
