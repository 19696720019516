import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'dvUserCode',
    detailUrl: '/DvDetail',
    idProp: 'dvId',
    mapProp: 'dvMap',
    name: '数据视图',
    textProp: 'dvName',
    type: 'DV',
}
