import {useEffect, useState} from 'react'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {subscribe, unsubscribe} from '@/script/event.mjs'

const getItems = (map) => {
    const next = (chain) => {
        const n = map.nodeProxy(chain[0])
        const canUpgrade = Boolean(n.canUpgrade?.())
        const isHidden = map.behaviours.isNodeHidden(chain[0])

        return {
            yieldChildren: ! isHidden,
            yieldNode: canUpgrade && ! isHidden,
        }
    }

    return [...map.walkDown(map.root, {excludeTarget: true, next})]
        .map(node => {
            const {lastRev, rev, sVer} = node.data
            const text = map.behaviours.getNodeText(node)
            const prefix = map.behaviours.getNodeTextPrefix(node)
            const suffix = map.behaviours.getNodeTextSuffix(node)
            const title = [prefix, text, suffix].join('') || '(空白)'

            return {
                key: node.id,
                latest: `${sVer}.${lastRev}`,
                title,
                version: `${sVer}.${rev}`,
            }
        })
}

export default () => {
    const map = useMapContext()
    const [items, setItems] = useState(() => getItems(map))

    useEffect(
        () => {
            const handleModelChange = () => {
                map.logger.withOff(
                    () => setItems(getItems(map))
                )
            }

            subscribe(map, 'model_change', handleModelChange)

            return () => {
                unsubscribe(map, 'model_change', handleModelChange)
            }
        },

        []
    )

    return items
}
