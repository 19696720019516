import {useMapContext} from '@/components/MindMap/index.mjs'
import useBizNode from '../useBizNode.mjs'

export default function SelectedNodes(props) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const BizNode = useBizNode()

    const text = (() => {
        if (0 < selectedNodes.size) {
            const [nodeCount, type] = (() => {
                let nodeCount = 0
                let type = null

                for (const node of selectedNodes) {
                    const {bizNodeType} = node.data
                    nodeCount += 1

                    if (null === type) {
                        type = bizNodeType
                    }
                    else if (type !== bizNodeType) {
                        type = ''
                    }
                }

                return [
                    nodeCount,
                    type ? BizNode[type].name : '节点',
                ]
            })()

            const tree = map.treelize(selectedNodes)

            const descendantCount = tree.reduce(
                (count, [node]) => count + node.descendantCount,
                0
            )

            return `已选择 ${nodeCount} 个${type}，共 ${descendantCount} 个子节点`
        }
        else {
            const nodeCount = map.root.descendantCount + 1
            return `共 ${nodeCount} 个节点`
        }
    })()

    return (
        <div {...props}>{text}</div>
    )
}
