import {css} from '@emotion/react'
import useSWR from 'swr'
import {Button} from 'antd'
import Table from '@/components/Table/Table.jsx'

const ButtonSelect = (props) => {
    return (
        <Button
            type="link"
            {...props}
        >
            选择
        </Button>
    )
}

const cssOperates = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
})

const Operates = ({item, onSelect}) => {
    const handleClickSelect = e => {
        e.stopPropagation()
        onSelect(item)
    }

    return (
        <div css={cssOperates}>
            <ButtonSelect onClick={handleClickSelect} />
        </div>
    )
}

const TableSelectDesignItems = ({
    columns,
    fetch,
    fetchId,
    isComponent,
    multiple,
    query,
    onChange,
    onSelect,
    ...props
}) => {
    const {data, isLoading} = useSWR([fetchId, query], () => fetch(query))

    const rowSelection = {
        //getCheckboxProps: e => ({disabled: 'RLS' !== e.stsCode}),
        onChange: (_, selectedRows) => onChange(selectedRows),
        type: multiple ? 'checkbox' : 'radio',
    }

    const enhancedColumns = [...columns]

    if (! isComponent) {
        enhancedColumns.push(
            {
                title: '版本号',
                dataIndex: 'rev',
                component: <Table.ViewText />,
            },

            {
                title: '状态',
                dataIndex: 'stsName',
                component: <Table.ViewText />,
            },
        )
    }

    enhancedColumns.push(
        {
            title: '操作',
            dataIndex: '_op',

            component: (_, row) => (
                <Operates
                    item={row}
                    onSelect={onSelect}
                />
            ),
        },
    )

    return (
        <Table
            columns={enhancedColumns}
            dataSource={data}
            loading={isLoading}
            rowKey="pkid"
            rowSelection={rowSelection}
            {...props}
        />
    )
}

export default TableSelectDesignItems
