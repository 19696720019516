import {css} from '@emotion/react'
import {Space} from 'antd'
import MenuButtonEdit from '../../ToolBar/Edit/MenuButtonEdit.jsx'
import MenuButtonInsertNode from '../../ToolBar/menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProduct from '../../ToolBar/menuButtons/MenuButtonInsertProduct.jsx'
import MenuButtonSaveMap from './MenuButtonSaveMap.jsx'

const cssMenu = css({
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
})

const Menu = (props) => {
    return (
        <Space
            css={cssMenu}
            {...props}
        >
            <MenuButtonSaveMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertProduct />
        </Space>
    )
}

export default Menu
