import {useMapContext} from '@/components/MindMap/index.mjs'
import isProductMap from '../isProductMap.mjs'

export default () => {
    const map = useMapContext()
    map.useMetaData()

    if (isProductMap(map)) {
        return null
    }

    const {canSaveMap, saveMap} = map.commands

    return {
        disabled: ! canSaveMap(),
        key: 'save-map',
        label: '保存',
        suffix: 'Ctrl+S',
        onClick: saveMap,
    }
}
