import IconLetters from '../icons/IconLetters.jsx'
import Color from '../Color.mjs'
import {replaceWithTree} from '../../scripts/map.mjs'
import useProductNode from '../useProductNode.jsx'
import meta from './metaBd.mjs'
import Model from './ModelBd.mjs'
import PropertiesPane from './PropertiesPaneBd.jsx'
import QueryForm from './QueryFormSelectBd.jsx'
import Table from './TableSelectBd.jsx'
import useApi from './useApiBd.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canLinkType(map, node, type) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return /^(UI)$/.test(type)
            }
            else {
                false
            }
        },

        canMountType(map, node, type) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return /^(CAT|MARK)$/.test(type)
            }
            else {
                return /^(BD_(BASE|DEVELOP|EVENT|LOGIC|RELATION|RULE|STRUCT|SU|SUMMARY|TERM))$/.test(type)
            }
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {bms = [], ...query}) => ({
                ...query,
                bmIds: bms.map(({bmId}) => bmId),
            })

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getIcons(map, node) {
            const {bdType} = node.data

            if ('2' === bdType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="A"
                        textColor="#000"
                    />
                ]
            }
            else if ('3' === bdType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="D"
                        textColor="#000"
                    />
                ]
            }
            else {
                return []
            }
        },

        getInitData(map, parent) {
            const d = {
                ...ProductNode.getInitData.call(this, map, parent),
                bdNo: 'BO',
                bdType: '2',
            }

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    bmCode,
                    bmId,
                    bmName,
                } = ancestor.data

                if ('CAT_ROOT_BM' === bizNodeType) {
                    Object.assign(d, {
                        bmCode,
                        bmId,
                        bmName,
                    })

                    break
                }
            }

            return d
        },

        getDefaultChildData(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return BizNode.UI.getInitData(map, node)
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                eventList: [],
                ruleList: [],
                sfList: [],
                termList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'SingleBreakangle',
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(
                data,
                ['algList', 'eventList', 'ruleList', 'termList']
            )
        },

        menuItemsInsertProduct(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return [
                    ['UI'],
                ]
            }
            else {
                return []
            }
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                BF: 'bdList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this.getPushData(map, node)
            data[key].push(pushData)
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        onSetData(map, node, oldData) {
            ProductNode.onSetData.call(this, map, node, oldData)

            if (node.data.bdType !== oldData.bdType) {
                // 强制子节点重新渲染

                node.change('children')

                for (const n of map.children(node)) {
                    n.change('data')
                    n.change('parent')
                }
            }

            //const childTypes = {
                //1: [
                    //'BD_TERM',
                    //'BD_SUMMARY',
                    //'BD_RULE',
                    //'BD_LOGIC',
                    //'BD_STRUCT',
                    //'BD_RELATION',
                    //'BD_DEVELOP',
                    //'BD_BASE',
                    //'BD_SU',
                //],

                //2: [
                    //'BD_TERM',
                    //'BD_SUMMARY',
                    //'BD_RULE',
                    //'BD_LOGIC',
                    //'BD_SU',
                //],

                //3: [
                    //'BD_STRUCT',
                    //'BD_RELATION',
                    //'BD_DEVELOP',
                    //'BD_BASE',
                    //'BD_SU',
                //],
            //}

            //const oldChildren = [...map.children(node)]

            //const childMap = new Map(
                //oldChildren.map(n => [n.data.bizNodeType, n])
            //)

            //const newChildren = childTypes[node.data.bdType].map(
                //type => {
                    //if (childMap.has(type)) {
                        //return childMap.get(type)
                    //}
                    //else {
                        //const data = BizNode[type].getInitData(map, node)
                        //return map.importTree({data})
                    //}
                //}
            //)

            //for (const n of oldChildren) {
                //map.unlinkTree(n)
            //}

            //for (const n of newChildren) {
                //map.appendChild(node, n)
            //}

            //for (const n of oldChildren) {
                //if (! n.parent) {
                    //map.deleteTree(n)
                //}
            //}
        },

        async grow(map, node, growedNodes) {
            if ('IS_MAP' === map.data.mapTypeCode) {
                if (! this.canGrow(map, node)) {
                    return
                }

                const {deliverRev, deliverVer, lastRev, pkid} = node.data

                if (growedNodes?.has(pkid)) {
                    map.logger.warn('为避免无限加载，跳过已加载过的节点', [node])
                    return
                }

                const newTree = await (async () => {
                    const args = {briefType: 'IS', pkid}
                    const rev = this.getRev(map, node)

                    if (0 < rev) {
                        const tree = await this.readTree(map, {...args, rev})

                        Object.assign(
                            tree.data,
                            {deliverRev, deliverVer, lastRev, rev}
                        )

                        return tree
                    }
                    else {
                        return this.readTree(map, args)
                    }
                })()

                replaceWithTree(map, node, newTree)
                node.isFolded = 0 < growedNodes?.size

                for (const n of map.walkDown(node, {excludeTarget: true})) {
                    const {bizNodeType} = n
                    BizNode[bizNodeType].onPull(map, n)
                }

                await this._growChildren(map, node, growedNodes)
            }
            else {
                return ProductNode.grow.call(this, map, node, growedNodes)
            }
        },
    }
}
