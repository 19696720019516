import superMeta from '../metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'uiUserCode',
    detailUrl: '/UiDetail',
    idProp: 'uiId',
    mapProp: 'uiMap',
    name: '用户界面',
    textProp: 'uiName',
    type: 'UI',
}
