import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {MapContext} from '@/components/MindMap/index.mjs'
import {deleteChildren, exportTree} from '../scripts/map.mjs'
import Color from './Color.mjs'
import {ValidationError} from './Error.mjs'
import useBaseNode from './useBaseNode.jsx'
import ModalSelectDesignItems from './components/ModalSelectDesignItems.jsx'

/**
 * 设计节点类型的基类
 */

const MAX_GROW_DEPTH = 3

export default (
    BizNode,
    {catProp = 'catNode', codeProp = '', ...baseConfig},
    Model,
    api,
) => {
    const openModal = useOpenModal()
    const BaseNode = useBaseNode(BizNode, baseConfig)

    const DesignNode = {
        ...BaseNode,
        attrNodes: null,
        catProp,
        codeProp,

        canGrow(map, node) {
            if (node === map.root) {
                map.logger.error('根节点不能加载', [node])
                return false
            }

            if (! node.data.pkid) {
                map.logger.error('概念节点不能加载', [node])
                return false
            }

            return BaseNode.canGrow.call(this, map, node)
        },

        canLinkTreeTo(map, node, tree) {
            // 概念节点不能链接
            return Boolean(tree.data.pkid)
        },

        canShrink(map, node) {
            if (node === map.root) {
                map.logger.error('根节点不能卸载', [node])
                return false
            }

            if (! node.data.pkid) {
                map.logger.error('概念节点不能卸载', [node])
                return false
            }

            if (! node.firstChild) {
                map.logger.error('没有子节点的节点无需卸载', [node])
                return false
            }

            return true
        },

        /**
         * 能否升级
         */
        canUpgrade(map, node) {
            if (! this.isOutdated(map, node)) {
                map.logger.error('模件没有过时，无需升级', [node])
                return false
            }

            return true
        },

        castTo(map, node, bizNodeType) {
            BaseNode.castTo.call(this, map, node, bizNodeType)

            const {
                pkid: _,
                [this.codeProp]: code,
                ...data
            } = node.data

            const {codeProp} = BizNode[bizNodeType]
            node.data = {...data, [codeProp]: code}
        },

        exportTree(map, node, options) {
            if (
                options?.excludeGrowed &&
                this.isLinked(map, node)
            ) {
                return {
                    ...exportTree(map, node),
                    children: [],
                }
            }
            else {
                return BaseNode.exportTree.call(this, map, node, options)
            }
        },

        getDesc(map, node) {
            return this.name
        },

        getPushData(map, node) {
            return this._getPushData(map, node)
        },

        getRev(map, node) {
            if (node.parent) {
                return node.data.rev ?? null
            }
            else {
                return null
            }
        },

        getText(map, node) {
            if (this.codeProp && ! node.data.pkid) {
                const parts = []
                const text = node.data[this.textProp]

                if (text) {
                    parts.push(text)
                }

                const code = node.data[this.codeProp]

                if (code) {
                    parts.push(code)
                }

                return parts.join(' #')
            }
            else {
                return BaseNode.getText.call(this, map, node)
            }
        },

        getVersion(map, node) {
            const rev = this.getRev(map, node)
            const {sVer} = node.data
            return (null !== rev && sVer) ? `${sVer}.${rev}` : ''
        },

        /**
         * 是否已不是最新版本
         */
        isOutdated(map, node) {
            const rev = this.getRev(map, node)

            if (null === rev) {
                map.logger.error('模件没有版本化', [node])
                return false
            }

            const {delFlag, lastRev} = node.data

            if ('1' === delFlag) {
                map.logger.error('模件已被删除', [node])
                return false
            }

            if (! lastRev) {
                map.logger.error('模件没有最新版本信息', [node])
                return false
            }

            return rev < lastRev
        },

        mapReplaceTextData(map, node, replace) {
            const data = BaseNode.mapReplaceTextData.call(
                this, map, node, replace
            )

            if (this.codeProp) {
                const {
                    pkid,
                    [this.codeProp]: code = '',
                } = node.data

                if (pkid) {
                    data[this.codeProp] = replace(code)
                }
            }

            return data
        },

        mapSetTextData(map, node, text) {
            if (node.data.pkid || ! this.codeProp) {
                return BaseNode.mapSetTextData.call(this, map, node, text)
            }

            const words = text.split(' ')

            const code = (() => {
                const lastWord = words.at(-1)

                if (lastWord?.startsWith('#')) {
                    return lastWord.replace(/^#/, '')
                }
            })()

            if (code) {
                const text = words.slice(0, -1).join(' ')

                return {
                    [this.codeProp]: code,
                    [this.textProp]: text,
                }
            }
            else {
                return {
                    [this.codeProp]: '',
                    [this.textProp]: text,
                }
            }
        },

        Model,

        onClickMenuItemInsertProduct(map, nodes) {
            map.commands.insertProduct(nodes, this.type)
        },

        async onInsert(map, node) {
        },

        onPull(map, node) {
            BaseNode.onPull.call(this, map, node)
            node.isFolded = Boolean(node.parent)
        },

        onSetData(map, node, oldData) {
            if (! this.attrNodes) {
                return
            }

            const collectAttrs = (types) => {
                const attrs = {}
                const changedAttrs = []

                for (const type of types) {
                    const {prop} = BizNode[type]
                    const isChanged = node.data[prop] !== oldData[prop]

                    const attr = {
                        isChanged,
                        type,
                        value: node.data[prop],
                    }

                    attrs[type] = attr

                    if (isChanged) {
                        changedAttrs.push(attr)
                    }
                }

                return [attrs, changedAttrs]
            }

            const {top = [], bottom = []} = this.attrNodes
            const [topAttrs, changedTopAttrs] = collectAttrs(top)
            const [bottomAttrs, changedBottomAttrs] = collectAttrs(bottom)

            if (
                0 === changedTopAttrs.length &&
                0 === changedBottomAttrs.length
            ) {
                return
            }

            for (const child of map.children(node)) {
                const {bizNodeType} = child.data
                const attr = topAttrs[bizNodeType] ?? bottomAttrs[bizNodeType]

                if (attr) {
                    attr.node = child
                }
            }

            const processAttrNodes = (changedAttrs, insert) => {
                for (const attr of changedAttrs) {
                    if (attr.value) {
                        const bn = BizNode[attr.type]

                        if (attr.node) {
                            attr.node.data = {
                                ...attr.node.data,
                                [bn.textProp]: attr.value,
                            }
                        }
                        else {
                            const data = bn.getInitData(map, node)

                            if (data) {
                                const attrNode = map.importTree({data})
                                insert(node, attrNode)
                            }
                        }
                    }
                    else {
                        if (attr.node) {
                            map.deleteTree(attr.node)
                        }
                    }
                }
            }

            // TODO 多个属性节点排序
            processAttrNodes(changedTopAttrs, map.prependChild)
            processAttrNodes(changedBottomAttrs, map.appendChild)
        },

        shrink(map, node) {
            if (this.canShrink(map, node)) {
                deleteChildren(map, node)
            }
        },

        async onAttachTo(map, node) {
            if (this.isLinked(map, node)) {
                deleteChildren(map, node)
            }

            await BaseNode.onAttachTo.call(this, map, node)
        },

        async _chooseProduct(
            map,
            node,
            QueryForm,
            Table,

            {
                getQuery = (map, node, formValues) => formValues,
                ...modalProps
            } = {},
        ) {
            const fetch = this.readList.bind(this, map)

            const items = await openModal(
                <MapContext.Provider value={map}>
                    <ModalSelectDesignItems
                        fetch={fetch}
                        getQuery={values => getQuery(map, node, values)}
                        QueryForm={QueryForm}
                        Table={Table}
                        title={`选择${this.name}`}
                        width="80%"
                        {...modalProps}
                    />
                </MapContext.Provider>
            )

            if (items) {
                return items.map(data => ({data}))
            }
            else {
                return []
            }
        },

        _getPushData(map, node, slots) {
            this._validate(node)

            if (slots) {
                BaseNode.onPush.call(this, map, node, this.type, slots)
            }

            const {data, id} = node

            const cat = (() => {
                const path = []

                for (const n of map.walkUp(node.parent)) {
                    const {bizNodeType} = n.data

                    if ('CAT' !== bizNodeType) {
                        break
                    }

                    const text = BizNode.CAT.getTextRaw(map, n)
                    path.push(text)
                }

                if (0 < path.length) {
                    return path.reverse().join('@@')
                }
                else {
                    return ''
                }
            })()

            const pushData = {
                ...data,
                ...slots,
                id,
                [this.catProp]: cat,
            }

            return pushData
        },

        _getStyle(map, node, styleDone) {
            const style = BaseNode.getStyle.call(this, map, node)
            const {delFlag, pkid, stsCode} = node.data

            if (pkid) {
                Object.assign(style, styleDone)
            }
            else {
                Object.assign(style, {
                    backgroundColor: Color.GREY,
                    textColor: '#000',
                })
            }

            if (
                '1' === delFlag ||
                'DISCARD' === stsCode
            ) {
                Object.assign(style, {
                    //fontWeight: 'bold',
                    textDecoration: 'line-through red 0.2em',
                })
            }

            return style
        },

        _initAttrNodes(map, node) {
            const {top = [], bottom = []} = this.attrNodes

            for (const type of top.toReversed()) {
                const data = BizNode[type].getInitData(map, node)

                if (data) {
                    const child = map.importTree({data})
                    map.prependChild(node, child)
                }
            }

            for (const type of bottom) {
                const data = BizNode[type].getInitData(map, node)

                if (data) {
                    const child = map.importTree({data})
                    map.appendChild(node, child)
                }
            }
        },

        _menuInsertConcept(
            map,
            nodes,

            {
                key = this.type,
                label = this.name,

                onClick = () => {
                    map.commands.insertBizNode(map.selectedNodes, this.type)
                },

                rank = Infinity,
            } = {}
        ) {
            for (const node of nodes) {
                const {bizNodeType} = node.data
                const bn = BizNode[bizNodeType]

                if (! bn.canMountType(map, node, this.type)) {
                    return null
                }
            }

            return [rank, {key, label, onClick}]
        },

        async _growChildren(map, node, growedNodes) {
            if (! growedNodes) {
                return
            }

            if (growedNodes.size < MAX_GROW_DEPTH) {
                const {pkid} = node.data

                await BaseNode.grow.call(
                    this,
                    map,
                    node,
                    new Set([...growedNodes, pkid]),
                )
            }
            else {
                map.logger.warn('已达最大加载层数，停止加载', [node])
            }
        },

        _toConcept(map, node) {
            const {dpSn, lastRev, pkid, rev, ...data} = node.data
            node.data = data
        },

        _validate(node) {
            if (Model) {
                const model = new Model(node.data)
                const errMsg = model.validate()

                if (errMsg) {
                    throw new ValidationError(errMsg, node)
                }
            }
        },
    }

    if (api?.readList) {
        DesignNode.readList = async function (map, args) {
            const items = await api.readList({
                stsCodes: 'RLS,REVISE',
                ...args,
            })

            return items.map(
                ({
                    [this.mapProp]: _,
                    ...node
                }) => ({
                    ...node,
                    bizNodeType: this.type,
                })
            )
        }
    }

    return DesignNode
}
