import useCategoryNode from '../useCategoryNode.jsx'
import meta from './metaSvrTerm.mjs'

export default (BizNode) => {
    const CategoryNode = useCategoryNode(BizNode, meta)

    return {
        ...CategoryNode,

        canMountType(map, node, type) {
            return /^(SVRTERM|CAT)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.SVRTERM.getInitData(map, node)
        },
    }
}
