import buildUrl from '@/script/buildUrl.mjs'
import {fromNavigatorMap} from '../compatibility.mjs'
import useApiMap from '../useApiMap.mjs'
import Color from './Color.mjs'
import IconFlag from './icons/IconFlag.jsx'
import {exportTree, replaceWithTree} from '../scripts/map.mjs'
import useComponentNode from './useComponentNode.jsx'
import useDesignNode from './useDesignNode.jsx'

export default (
    BizNode,

    {
        mapIdProp,
        mapName,
        mapStsCodeProp,
        mapTypeCode,
        ...config
    },

    Model,
    api,
) => {
    const apiMap = useApiMap()
    const ComponentNode = useComponentNode(BizNode, config, Model, api)
    const DesignNode = useDesignNode(BizNode, config, Model, api)

    return {
        ...ComponentNode,

        canDeleteNode(map, node) {
            return this.canWriteTree(map, node)
        },

        canGrow(map, node) {
            if (! node.data[this.mapIdProp]) {
                map.logger.error('未创建地图，不能加载', [node])
                return false
            }

            return DesignNode.canGrow.call(this, map, node)
        },

        canShrink(map, node) {
            if (! node.data[this.mapIdProp]) {
                map.logger.error('未创建地图，不能卸载', [node])
                return false
            }

            return DesignNode.canShrink.call(this, map, node)
        },

        async createMap(map, node) {
            const {prjId, sVer} = map.data

            const {
                pkid,
                [this.codeProp]: code,
                [this.textProp]: mapName,
            } = node.data

            const {
                mapStsCode,
                pkid: mapId,
            } = await apiMap.create({
                mapName,
                mapNo: `${code}_MAP`,
                mapTypeCode: this.mapTypeCode,
                prjId,
                rNodeDataId: pkid,
                sVer,
            })

            return {
                [this.mapIdProp]: mapId,
                [this.mapStsCodeProp]: mapStsCode,
            }
        },

        exportTree(map, node, options) {
            if (
                options?.excludeGrowed &&
                this.isLinked(map, node)
            ) {
                const children = [...map.children(node)]
                    .filter(n => this.canMountTree(map, node, n))
                    .map((n) => {
                        const {bizNodeType} = n.data

                        return BizNode[bizNodeType].exportTree(
                            map, n, options
                        )
                    })
                    .filter(a => a)

                return {
                    ...exportTree(map, node),
                    children,
                }
            }
            else {
                return ComponentNode.exportTree.call(this, map, node, options)
            }
        },

        getIcons(map, node) {
            const {
                pkid,
                [this.mapIdProp]: mapId,
                [this.mapStsCodeProp]: mapStsCode,
            } = node.data

            if (pkid) {
                if (mapId) {
                    if ('RLS' === mapStsCode) {
                        return [
                            <IconFlag
                                key="status"
                                color={Color.RED}
                            />
                        ]
                    }
                    else {
                        return [
                            <IconFlag
                                key="status"
                                color={Color.ORANGE}
                            />
                        ]
                    }
                }
                else {
                    return []
                }
            }
            else {
                return [
                    <IconFlag
                        key="status"
                        color={Color.BLUE}
                    />
                ]
            }
        },

        async grow(map, node, growedNodes) {
            if (! this.canGrow(map, node)) {
                return
            }

            const {[this.mapIdProp]: mapId, pkid} = node.data

            if (growedNodes?.has(pkid)) {
                map.logger.warn('为避免无限加载，跳过已加载过的节点', [node])
                return
            }

            const mapData = await apiMap.read({pkid: mapId})
            const oldTree = exportTree(map, node)

            const oldChildren = oldTree.children.filter(
                tree => this.canMountTree(map, node, tree)
            )

            const {root: {children: newChildren}} = fromNavigatorMap(
                BizNode, mapData.mapModel
            )

            const tree = {
                ...oldTree,
                children: [...oldChildren, ...newChildren],
            }

            replaceWithTree(map, node, tree)
            node.isFolded = 0 < growedNodes?.size

            for (const n of map.walkDown(node, {excludeTarget: true})) {
                n.isFolded = false
            }

            await this._growChildren(map, node, growedNodes)
        },

        mapIdProp,
        mapName,
        mapStsCodeProp,
        mapTypeCode,

        onDoubleClick(map, node, event) {
            const {[this.mapIdProp]: mapId} = node.data

            if (mapId) {
                event.preventDefault()
                this.viewMap(map, node)
            }
        },

        shrink(map, node) {
            for (const childNode of [...map.children(node)]) {
                if (! this.canMountTree(map, node, childNode)) {
                    map.deleteTree(childNode)
                }
            }
        },

        viewMap(map, node) {
            const {[this.mapIdProp]: mapId} = node.data

            if (mapId) {
                const url = buildUrl('/SoftwareMap', {id: mapId})
                window.open(url)
            }
        },

        _toConcept(map, node) {
            ComponentNode._toConcept.call(this, map, node)

            const {
                [mapIdProp]: _,
                ...data
            } = node.data

            node.data = data
        },
    }
}
