import Color from '../Color.mjs'
import useExclusiveNode from '../useExclusiveNode.jsx'
import IconAvatar from '../icons/IconAvatar.jsx'
import meta from './metaRole.mjs'

export default (BizNode) => {
    const ExclusiveNode = useExclusiveNode(BizNode, meta)

    return {
        ...ExclusiveNode,

        canMountType(map, node, type) {
            return /^(CAT|COPR)$/.test(type)
        },

        getDefaultChildData(map, node) {
            return BizNode.COPR.getInitData(map, node)
        },

        getIcons() {
            return [
                <IconAvatar
                    key="type"
                    color={Color.BLUE}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...ExclusiveNode.getStyle.call(this, map, node),
                borderColor: Color.GOLD,
            }
        },

        onAttached(map, node, child) {
            const {bizNodeType} = child.data

            if ('FOPR' === bizNodeType) {
                BizNode.COPR.castFrom(map, child)
            }

            ExclusiveNode.onAttached.call(this, map, node, child)
        },
    }
}
