import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaMid.mjs'
import Model from './ModelMid.mjs'
import PropertiesPane from './PropertiesPaneMid.jsx'
import QueryForm from './QueryFormSelectMid.jsx'
import Table from './TableSelectMid.jsx'
import useApi from './useApiMid.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        canMountType(map, node, type) {
            return /^(MID_(MIDINS))$/.test(type)
        },

        async chooseProduct(map, node) {
            return this._chooseProduct(map, node, QueryForm, Table)
        },

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill={Color.DARK_GOLD}
                    letters="M"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    borderColor: Color.GREEN_YELLOW,
                }),
            }
        },

        async grow(map, node, growedNodes) {
            await ProductNode.grow.call(this, map, node, growedNodes)

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if (BizNode[bizNodeType].isComment) {
                    continue
                }
                else if ('DEP_MID' === bizNodeType) {
                    for (const child of map.children(node)) {
                        const {bizNodeType} = child.data

                        if ('MID_MIDINS' === bizNodeType) {
                            map.deleteNode(child)
                        }
                        else {
                            map.deleteTree(child)
                        }
                    }
                }
                else {
                    break
                }
            }
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {archMiddlewareInss: []})
        },

        mapPushResult(data) {
            return this._mapPushResult(data, ['archMiddlewareInss'])
        },

    }
}
