import superMeta from '../metaComponentNode.mjs'

export default {
    ...superMeta,
    mapProp: 'map',
    name: '中间件实例',
    ownerIdProp: 'middlewareId',
    ownerTextProp: 'middlewareName',
    ownerType: 'MID',
    textProp: 'middlewareInsName',
    type: 'MIDINS',
}

