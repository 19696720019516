export default {
    /**
     * 节点分类
     */
    classes: [],

    /**
     * 节点数据里存储子地图信息的属性名
     */
    mapProp: '',

    /**
     * 节点数据里存储节点名称的属性名
     */
    textProp: 'text',

    /**
     * 业务类型的编码值
     */
    type: '',

    /**
     * 业务类型的中文名称
     */
    typeName: '',
}
