import useAttributeNode from '../useAttributeNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'

const prop = 'dfDesc'
const type = 'ATTR_DF_DESC'

export default (BizNode) => {
    const AttributeNode = useAttributeNode(BizNode, {prop, type})

    return {
        ...AttributeNode,

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    fill="#f88634"
                    letters="!"
                    textColor="#fff"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                shape: 'Rectangle',
            }
        },
    }
}
