import Tree from '@/script/Tree.mjs'
import Color from '../Color.mjs'
import useProductNode from '../useProductNode.jsx'
import meta from './metaSf.mjs'
import IconInf from './icon-inf.svg?react'
import IconTime from './icon-time.svg?react'
import Model from './ModelSf.mjs'
import PropertiesPane from './PropertiesPaneSf.jsx'
import QueryForm from './QueryFormSelectSf.jsx'
import Table from './TableSelectSf.jsx'
import useApi from './useApiSf.mjs'

export default (BizNode) => {
    const api = useApi()
    const ProductNode = useProductNode(BizNode, meta, Model, api)

    return {
        ...ProductNode,

        attrNodes: {
            top: ['ATTR_SF_KEY'],
        },

        canMountType(map, node, type) {
            return /^(SF_(ALG|DES|PROG|RULE))$/.test(type)
        },

        async chooseProduct(map, node) {
            const getQuery = (map, node, {fms = [], ...query}) => {
                const fmIds = fms.map(({fmId}) => fmId)

                for (const ancestor of map.walkUp(node)) {
                    const {bizNodeType} = ancestor.data

                    if ('LS' === bizNodeType) {
                        return {fmIds, sfTypeCode: 'INF', ...query}
                    }
                }

                return {fmIds, ...query}
            }

            return this._chooseProduct(
                map, node, QueryForm, Table, {getQuery}
            )
        },

        getInitData(map, parent) {
            const d = ProductNode.getInitData.call(this, map, parent)

            for (const ancestor of map.walkUp(parent)) {
                const {
                    bizNodeType,
                    fmCode,
                    fmId,
                    fmName,
                } = ancestor.data

                if ('CAT_ROOT_FM' === bizNodeType) {
                    Object.assign(d, {
                        fmCode,
                        fmId,
                        fmName,
                    })

                    break
                }
                else if ('LS' === bizNodeType) {
                    Object.assign(d, {
                        sfTypeCode: 'INF',
                    })

                    break
                }
            }

            return d
        },

        getIcons(map, node) {
            const {sfTypeCode} = node.data

            if ('INF' === sfTypeCode) {
                return [<IconInf key="type" />]
            }
            else if ('TIME' === sfTypeCode) {
                return [<IconTime key="type" />]
            }
            else {
                return []
            }
        },

        getPushData(map, node) {
            return this._getPushData(map, node, {
                algList: [],
                mainOprList: [],
                otherOprList: [],
                progList: [],
            })
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.GOLD}),
                shape: 'BreakangleRectangle',
            }
        },

        async grow(map, node, growedNodes) {
            await ProductNode.grow.call(this, map, node, growedNodes)

            for (const n of map.walkUp(node.parent)) {
                const {bizNodeType} = n.data

                if (/^(BD_SUMMARY|BF_DATAFLOW)$/.test(bizNodeType)) {
                    node.isFolded = false

                    for (const child of map.children(node)) {
                        child.isFolded = 'SF_DES' !== child.data.bizNodeType
                    }
                }
            }
        },

        mapPushResult(data) {
            return this._mapPushResult(
                data,

                [
                    'mainOprList',
                    'otherOprList'
                ]
            )
        },

        nodePanes: {
            properties: {
                components: [PropertiesPane],
            },
        },

        async onAttachTo(map, node) {
            await ProductNode.onAttachTo.call(this, map, node)
            await this.onInsert(map, node)
        },

        async onInsert(map, node) {
            for (const n of map.walkUpNoComment(node.parent)) {
                const {bizNodeType} = n.data

                if ('AR' === bizNodeType) {
                    const {pkid} = node.data
                    const treeData = await this.readTree(map, {pkid})
                    const tree = new Tree(treeData)

                    const next = (chain) => {
                        const {bizNodeType: t} = chain[0].data
                        const yieldNode = 'AR' === t

                        const yieldChildren = (
                            'AR' === t ||
                            BizNode[t].isCategory
                        )

                        return {yieldChildren, yieldNode}
                    }

                    const addAr = node.nextSibling ?
                        ar => map.insertSiblingBefore(node.nextSibling, ar) :
                        ar => map.appendChild(node.parent, ar)

                    for (
                        const n of
                        tree.walkDown(tree.root, {excludeTarget: true, next})
                    ) {
                        const {
                            arTypeCode,
                            [BizNode.AR.textProp]: t,
                        } = n.data

                        if ('VAR' !== arTypeCode) {
                            continue
                        }

                        const text = t.replace(
                            /^(?:<IP>)? *(\$*.*)$/,
                            '<IP> $1'
                        )

                        const data = {
                            ...n.data,
                            arTypeCode: 'SET_IP',
                            [BizNode.AR.textProp]: text,
                        }

                        const ar = map.importTree({data})
                        addAr(ar)
                    }
                }

                break
            }

            await ProductNode.onInsert.call(this, map, node)
        },

        onPull(map, node) {
            ProductNode.onPull.call(this, map, node)
            node.isFolded = false
        },

        onPush(map, node, type, data) {
            if (! node.data.pkid) {
                return
            }

            const key = {
                FOPR: 'sfOprSfs',
                SF: 'rFunList',
            }[type]

            if (! key) {
                return
            }

            const pushData = this._getPushData(map, node)
            data[key].push(pushData)
        },
    }
}
