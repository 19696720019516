import {useMapContext} from '@/components/MindMap/index.mjs'
import SidePane from '../../SidePanel/SidePane.jsx'
import {PropertiesContext} from '../../SidePanel/NodePanel/Properties.mjs'
import Text from '../../SidePanel/NodePanel/PropertiesControllerText.jsx'
import ProjectDetail from './PropertiesControllerProjectDetail.jsx'

const PropertiesPaneCatRootPrj = () => {
    const map = useMapContext()

    return (
        <PropertiesContext.Provider value={[map.data.project]}>
            <SidePane>
                <Text
                    title="项目编号"
                    labelSuffix={<ProjectDetail />}
                    readOnly
                    prop="prjNo"
                />

                <Text
                    title="项目名称"
                    readOnly
                    prop="prjName"
                />

                <Text
                    title="项目类型"
                    readOnly
                    prop="typeName"
                />

                <Text
                    title="更新人"
                    readOnly
                    prop="uptName"
                />

                <Text
                    title="更新时间"
                    readOnly
                    prop="uptTime"
                />

                <Text
                    title="创建人"
                    readOnly
                    prop="crtName"
                />

                <Text
                    title="创建时间"
                    readOnly
                    prop="crtTime"
                />
            </SidePane>
        </PropertiesContext.Provider>
    )
}

export default PropertiesPaneCatRootPrj
