import {css} from '@emotion/react'
import InputNumber from '@/components/Form/InputNumber.jsx'
import Input from './PropertiesControllerInput.jsx'

const cssNumber = css({
    textAlign: 'left',
})

const PropertiesControllerNumber = ({
    className,
    style,
    disabled,
    hidden,
    labelSuffix,
    prop,
    required,
    title,
    value,
    ...props
}) => {
    return (
        <Input
            className={className}
            style={style}
            disabled={disabled}
            hidden={hidden}
            labelSuffix={labelSuffix}
            prop={prop}
            required={required}
            title={title}
            value={value}
        >
            <InputNumber
                css={cssNumber}
                changeDelay={null}
                {...props}
            />
        </Input>
    )
}

export default PropertiesControllerNumber
