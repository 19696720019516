import Color from '../Color.mjs'
import useAttributeNode from '../useAttributeNode.jsx'
import IconAvatar from '../icons/IconAvatar.jsx'

const prop = 'bjPos'
const type = 'ATTR_BJ_POS'

export default (BizNode) => {
    const AttributeNode = useAttributeNode(BizNode, {prop, type})

    return {
        ...AttributeNode,

        getIcons() {
            return [
                <IconAvatar
                    key="type"
                    color={Color.BLUE}
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...AttributeNode.getStyle.call(this, map, node),
                backgroundColor: '#fff',
                borderColor: 'var(--main-color)',
                custom: {'--main-color': Color.GOLD},
            }
        },
    }
}
