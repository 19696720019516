import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    dmUserCode: {
        name: () => '数据域代码',
    },

    dmName: {
        name: () => '数据域名称',
        required: () => true,
    },
})
